
import React, { useState } from 'react';

function ForgetPasswordForm() {
  const [email, setEmail] = useState("");

  const handleChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handleOnSubmit = (evt) => {
    evt.preventDefault();
    alert(`Password reset link sent to: ${email}`);
    setEmail("");
  };

  return (
    <div className="form-container sign-in-container">
      <form className="form" onSubmit={handleOnSubmit}>
        <h1 style={{margin:"1rem 0"}}>Forgot Password</h1>
        <span>Enter your email to reset your password</span>
        <input
        style={{margin:"2rem 0"}}
          type="email"
          placeholder="Email"
          name="email"
          value={email}
          onChange={handleChange}
          autocomplete="off" 
        />
        <button type="submit">Send Reset Link</button>
      </form>
    </div>
  );
}

export default ForgetPasswordForm;
