import React from 'react';
import {Loader} from '@xpertdox_js/xdcomponents';
const styles = {
  container: {
    position: 'relative',
    width: '100%',
    minHeight: '100vh',
  }
}

const LoadingLandingPage = props => {
  return(
    <div style={styles.container}>
      <Loader styles={{container: {height: window.innerHeight + 'px'}}} noBg />
    </div>
  )
}

export default LoadingLandingPage;
