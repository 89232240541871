import React, { useState, useLayoutEffect, useRef } from 'react';
import AnimationHandler from './controller';

const AnimationWrap = (props) => {
  const hasMounted = useRef(false);
  const [cbReady, setcbReady] = React.useState(false);
  const cbCalled = useRef(false);
  const [animationValue, setAnimationValue] = useState(null);
  const animationHandlers = useRef(props.animation.map((anObj) => AnimationHandler(anObj)));

  // -- compile initial styles
  const initialStyles = () => {
    const store = {};
    animationHandlers.current.forEach((animObj, i) => {
      store[animObj.property] = animObj.valueMap
        ? animObj.valueMap.replace('$v', animObj.schedule[0])
        : animObj.schedule[0];
    });
    if (props.fixedTransform && store.transform) store.transform += ` ${props.fixedTransform}`;
    return store;
  };


  // -- check if animations have finished
  const stillRunning = () => {
    if (animationHandlers.current) {
      let value = false;
      animationHandlers.current.forEach((handler) => {
        if (handler.running) value = true;
      });
      if (!value && props.callback && !cbCalled.current && !cbReady) setcbReady(true);
      return value;
    }
  };

  // -- wrap callback event into rendering cycle
  const {callback} = props;
  React.useEffect(() => {
    if (cbReady && callback) callback();
  },  [cbReady, callback])

  // -- animtion frame loop
  const started = useRef(null);
  const animationRef = useRef(false);
  const handleRender = (timeStamp) => {
    if (hasMounted.current && stillRunning()) {
      if (!started.current) started.current = timeStamp;
      const runtime = timeStamp - started.current;
      let update = {};
      let setFixed = false;
      for (let i = 0; i < animationHandlers.current.length; i++) {
        const handler = animationHandlers.current[i];
        // run logic
        if (handler.running) handler.logic(runtime);
        // compile transform properties
        if (update.transform && handler.value().transform) {
          update.transform += ` ${handler.value().transform}`;
        } else update = { ...update, ...handler.value() };
        // append fixed transform values if needed
        if (props.fixedTransform && update.transform && !setFixed) {
          update.transform += ` ${props.fixedTransform}`;
          setFixed = true;
        }
        handler.updated();
      }

      setAnimationValue(update);
    }
  };


  // -- check for animation value change
  const prevAnimationValue = useRef(null);
  const checkForChanges = () => {
    let result = false;
    if (prevAnimationValue.current === null && animationValue !== null) result = true;
    if (!result && animationValue !== null) {
      const prev = prevAnimationValue.current === null
        ? { ...prevAnimationValue.current }
        : {};
      const oldValues = Object.values(prev);
      const newValues = Object.values(animationValue);
      oldValues.forEach((property, i) => {
        if (property !== newValues[i]) result = true;
      });
    }
    return result;
  };

  if (animationRef.current === false) animationRef.current = requestAnimationFrame(handleRender);

  if (checkForChanges() && stillRunning() && hasMounted.current) animationRef.current = requestAnimationFrame((timeStamp) => handleRender(timeStamp));

  // -- mount/unmount && animation setter/cleanup
  useLayoutEffect(() => {
    hasMounted.current = true;
    return (() => {
      cancelAnimationFrame(animationRef.current);
      hasMounted.current = false;
    });
  }, []);

  const Wrapper = React.createElement(
    props.svg ? 'g' : 'div',
    {
      ...props.wrapperProps,
      style: { ...initialStyles(), ...props.wrapStyles, ...animationValue },
      id: props.id,
    },
    props.children,
  );

  // render
  return Wrapper;
};

export default AnimationWrap;
