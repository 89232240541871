import React, {useState} from 'react';
import defaultStyles from './styles';

const Loader = props => {

  const [animate, setAnimate] = useState('red');

  let styles = {...defaultStyles};
  if (props.styles) Object.keys(props.styles).forEach(styleTarget => {
    styles[styleTarget] = {...styles[styleTarget], ...props.styles[styleTarget]};
  })
  if (props.width) styles.svg = {...styles.svg, width: props.width};


  let animateStyles = {};
  const animation = 'soft-blink .32s ease-in-out';


  animateStyles[animate] = {animation: animation};
  animateStyles['alt'
    + (animate.slice(0, 1).toUpperCase()
    + animate.slice(1))
  ] = {animation:animation};

  const changeAnimation = () => {
    let color = animate === 'red'
      ? 'blue'
      : animate === 'blue'
      ? 'green'
      : 'red';
    setAnimate(color);
  }

  Object.keys(animateStyles).forEach(target => {
    styles[target] = {...styles[target], ...animateStyles[target]};
  })

  return(
    <div style={styles.container}>
      <div style={styles.content}>
        <svg style={styles.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 226 226">
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
            {props.noBg ? null : <circle style={styles.circle} cx="113" cy="113" r="113"/>}
            <g className='svg-logo-red'>
              <path style={styles.red} onAnimationEnd={changeAnimation} d="M124.43,35c5.91-.5,14.5,4.28,17.32,9.23l20.84,36.59c5.88,10.33,9.2,25.06,3.49,35.47l-12.21,22.24c-7.51,13.68-31.35,9.24-25.17-2l10.92-19.89c5.72-10.41,2.39-25.14-3.49-35.47l-18.2-32-2.63-4.63c-2.55-4.47-9.8-8.81-15.54-9.24Z"/>
              <path style={styles.altRed} d="M85.2,53l6.68-11.38c3.9-6.65,18.39-.19,22.2,6.51l3.12,5.47-5.88,8C101.3,77.33,76.82,67.27,85.2,53Z"/>
            </g>
            <g className='svg-logo-blue'>
              <path style={styles.blue} onAnimationEnd={changeAnimation} d="M180.17,167.86c-2.53,5.37-11,10.42-16.66,10.38L121.4,178c-11.88-.07-26.3-4.55-32.46-14.71L75.79,141.59c-8.1-13.35,7.67-31.77,14.32-20.79l11.76,19.4c6.16,10.16,20.59,14.64,32.47,14.71l36.78.22,5.32,0c5.15,0,12.53-4.08,15.77-8.83Z"/>
              <path style={styles.altBlue} d="M185.24,124.88l6.52,11.47c3.82,6.7-9,16-16.74,16l-6.29,0-4-9.11C156.1,126.64,177.05,110.47,185.24,124.88Z"/>
            </g>
            <g className='svg-logo-green'>
              <path style={styles.green} onAnimationEnd={changeAnimation} d="M36.48,149.06c-3.39-4.87-3.55-14.7-.66-19.62L57.09,93.1c6-10.25,17.09-20.5,29-20.76l25.36-.54c15.61-.33,23.68,22.53,10.85,22.8l-22.69.49c-11.88.25-23,10.5-29,20.76L52,147.59l-2.69,4.59c-2.6,4.45-2.73,12.89-.24,18.07Z"/>
              <path style={styles.altGreen} d="M71.34,174.22l-13.19-.08c-7.72-.06-9.37-15.84-5.47-22.49l3.18-5.43,9.9,1.06C84.38,148.1,87.92,174.33,71.34,174.22Z"/>
            </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default Loader;
