import React, { useState, useRef } from "react";
import Form from "../General/Form";
import styles from "./styles";
import Button from "../General/Button";
import fetchController from "../General/fetchController";
import Loader from "../General/Loader";
import { SsoContext } from "../hooks/ssoContext";
import FormWrapper from "./FormWrapper";

import MSAL from "./MSALintegration";

const LoginForm = (props) => {
  // console.log(props, "12212121212221");
  const toRegister =props.toRegister;
  const [formState, setFormState] = useState({});
  const [status, setStatus] = useState("waiting");
  const { state, handleSession } = React.useContext(SsoContext);
  const isStanford =
    state.urlQueries.instance === "STANFORD" || props.config.id === "STANFORD";
  const [popupError, setPopupError] = useState(false);
  const [showManual, setShowManual] = useState(false);
  const [intLoginVisible, setIntLoginVisible] = useState(true);
  const hasConfigColors = props.config && props.config.colors;

  const extLoginBtnStyles = {
    btn: hasConfigColors
      ? { ...styles.integratedLoginBtn, background: props.config.colors.main }
      : styles.integratedLoginBtn,
    hov: hasConfigColors
      ? { ...styles.integratedLoginBtnHov, color: props.config.colors.main }
      : styles.integratedLoginBtnHov,
  };
  const msalInteg = useRef(
    MSAL({
      popupError,
      setPopupError,
      state,
      handleSession,
      setStatus,
      setVisible: setIntLoginVisible,
    })
  );

  const fetcher = useRef(
    fetchController({
      url: "/backend/rt/user/login",
      method: "post",
      endpoint: "",
      body: {},
    })
  );

  const formSettings = [
    {
      type: "email",
      // label: "Email Address",
      placeholder: "Email Address",
      id: "login_email",
      styles: styles.textInputStyles,
      autocomplete: "new-email",
    },
    {
      type: "password",
      // label: "Password",
      placeholder: "Password",
      id: "login_password",
      noValidators: true,
      styles: styles.textInputStyles,
      autocomplete: "new-password",
    },
  ];

  if (props.disclaimerField)
    formSettings.push(props.disclaimerFieldSettings.field);

//   const validateForm = () => {
//     for (const field of formSettings) {
//         if (field.required && !formState[field.id]) {
//             return false;
//         }
//     }
//     return true;
// };

  const handleSubmit = () => {
    setStatus("fetching");
    fetcher.current.body = {
      email: formState.login_email,
      instance: state.urlQueries.instance || props.config.id,
      password: formState.login_password,
      type: props.from,
    };

    props.setTwoFactorData({
      email: formState.login_email,
      password: formState.login_password,
      type: props.from,
      instance: props.config.id,
    });
    if (props.disclaimerField)
      fetcher.current.body.verification = {
        studyRepDisclaimer: formState.disclaimer_radio === "disclaimer_agree",
      };
    fetcher.current.fetch()
    .then(res => {
      if (res.error) {
        console.log(res.data);
        if (res.field === 'disclaimer') {
          props.setDisclaimerField(true);
        } else if (res.field === 'twoFactor') {
          props.to2fa();
        }
        setStatus('error');
      } else {
        setStatus('complete');
        // console.log(res.data, 'data from response');
        if (res.data && res.data.cookies) handleSession(res.data.cookies.session);
        // handleToken(res.data.token);
        let redirectUri = state.urlQueries.redirect_uri ? state.urlQueries.redirect_uri : res.data.redirect;
        if(state.urlQueries.page_name){
          const xt_page = state.urlQueries.page_name
          if(state.urlQueries.filters){
            const filters = state.urlQueries.filters
            window.location = redirectUri + `/${xt_page}` + `?filters=${filters}` + '?session=' + res.data.session
          }else{
            window.location = redirectUri + `/${xt_page}` + '?session=' + res.data.session
          }
        }else{
          window.location = redirectUri + '?session=' + res.data.session;
        }
        
      }
    })
    .catch(err => {
      console.error(err);
      setStatus('error');
    })
  }
  const handleHeightFix = React.useCallback(node => {
    if (node && node.clientHeight % 2 !== 0) {
      node.style.marginTop = "1px";
    } else if (node) {
      node.style.marginTop = 0;
    }
  }, []);

  if (popupError) {
    setTimeout(() => {
      setPopupError(false);
    }, 5000);
  }
// console.log(props,"--------------------")
  const appName = props.config
    ? props.config.name : " XpertDox";
  const platformName = props.config
    ? (props.from === "xt_coding" ? "Business Intelligence Platform" : `Console`)
    : " XpertDox";

  return (
    <React.Fragment>
      {isStanford && intLoginVisible && (
        <div ref={handleHeightFix}>
          {popupError && (
            <p style={styles.integratedPopupMessage}>{popupError}</p>
          )}
          <Button
            onClick={msalInteg.current.handleIntegratedLogin}
            styles={extLoginBtnStyles.btn}
            hoverStyles={extLoginBtnStyles.hov}
          >
            SID Login
          </Button>
          <div style={styles.or}>
            <span style={styles.orText}>OR</span>
          </div>
        </div>
      )}
      {(!isStanford || showManual) && intLoginVisible && (
      
            <FormWrapper
              title={` ${appName}`}
              subheading={`${platformName}`}
              subTitle="Please log in using your credentials."
              titleStyles={props.titleStyles} 
              message={fetcher.current.message}
              status={status}     
              id="login_form"
              active={props.active}
             additionalButtons={props.from==="xc_console"||props.from==="xt_console"?{ text: 'Sign Up', onClick: toRegister }:null}

            >
      
            <Form
              settings={formSettings}
              state={{
                formState: formState,
                setFormState: setFormState,
              }}
              handleSubmit={handleSubmit}
              //onClick={props.onf}
              styles={{
                ...styles.formStyles,
                ...props.formStyles,
                submitButton: {
                  width: "100%", 
                  ...(props.formStyles?.submitButton || {}), 
                },
              }}
              validators={props.disclaimerFieldSettings.validator}
              memo={props.disclaimerField}
              // submitText="Log In"
              additionalButtons={[
                {
                  onClick: props.toLogin,
                  text: "Log In",
             
                 
               
              },]}
              additionalButtons1={[
                {
                  onClick: props.toForgot,
                  text: "Forgot Password?",
                  styles: {
                    textAlign: "right",
                    background: "none",
                    color: "black",
                    fontSize: "12px",
                    padding: "0px",
                  },
                  hoverStyles: {
                    color: "#3A3A3D",
                    background: "transparent",
                    cursor: "pointer",
                  },
                },
              ]}
              
        
            /></FormWrapper>
  
      )}
      {isStanford && !showManual && intLoginVisible && (
        <Button
          onClick={() => setShowManual(true)}
          styles={{
            ...extLoginBtnStyles.btn,
            background: "rgb(193, 193, 193) none repeat scroll 0% 0%",
          }}
          hoverStyles={extLoginBtnStyles.hov}
        >
          Admin Login
        </Button>
      )}
      {!intLoginVisible && (
        <Loader
          styles={{ container: { height: window.innerHeight + "px" } }}
          noBg
        />
      )}
    </React.Fragment>
  );
};

export default LoginForm;
