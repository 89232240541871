import React, {useState, useEffect} from 'react';
import axios from 'axios';
import parseUrlQueries from '../hooks/parseUrlQueries';
import axiosConfig from '../../config/axios.config';
import xdLogo from '../../assets/xd-logo.png';

const styles = {
  container: {
    backgroundColor: 'white',
    width: '50%',
    margin: 'auto',
    height: '100%',
    textAlign: 'center',
    padding: '20px',
    lineHeight: '50px',
    position: 'relative',
    minHeight: '100vh'
  }
}

const ListOfInstancesPage = props => {
  const [data, setData] = useState({})

  useEffect(() => {
    axios.get(axiosConfig.apiUrl + '/backend/rt/sso/allInstances')
    .then(res => {
      // console.log('allInstances res', res.data, Object.keys(res.data))
      if (res && res.data) setData(res.data)
    })
    .catch(err => {
      console.error('allInstances err',err);
    })
  }, [])
  const allInstances = Object.keys(data);
  const urlQueries = parseUrlQueries()
  // console.log(allInstances, urlQueries)
  
  return(
    <div style = {styles.container}>
      <img src={xdLogo} />
      <h1>Choose Your Organization</h1>
        {allInstances.map((instance, index) => { 
          const instanceData = data[instance];
          urlQueries['instance'] = instance
          const typeFlag = instanceData.images.logo.type === 'jpg' ? 'jpeg' : instanceData.images.logo.type === 'svg' ? 'svg+xml' :  instanceData.images.logo.type
          const imgSrc =instanceData.images.logo ? `data:image/${typeFlag};base64,${instanceData.images.logo.img}` : xdLogo;
          let queryString = ''
          Object.keys(urlQueries).forEach(key=> queryString += `?${key}=${urlQueries[key]}`)
          return <div key={index+1} style={{fontSize: '20px'}} >
            <a href={`/sso${queryString}`}>
              <img style={{width: '200px', width: '150px'}} src={imgSrc} alt={instance} />
              {/* {instance} */}
            </a>
          </div>
        })}
    </div>
  )
}

export default ListOfInstancesPage;
