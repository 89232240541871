import React, { useState, useRef } from 'react';
import Form from '../General/Form';
import styles from './styles';
import fetchController from '../General/fetchController';
import { SsoContext } from '../hooks/ssoContext';
import axios from 'axios';
import axiosConfig from '../../config/axios.config';
import FormWrapper from './FormWrapper';

const TwoFactorForm = (props) => {
  const [formState, setFormState] = useState({});
  const [status, setStatus] = useState('waiting');
  const [textState, setTextState] = useState(false);
  const [countDown, setCountDown] = useState(30);

  const { state } = React.useContext(SsoContext);

  const fetcher = useRef(
    fetchController({
      url: '/backend/rt/sso/validateTwoFactor',
      method: 'post',
      endpoint: '',
      body: {},
    })
  );

  const formSettings = [
    {
      type: 'text',
      // label: 'Verification Code',
      placeholder: 'Verification Code From Email',
      id: '2fa_token',
    },
    {
      type: 'checkbox',
      label: '',
      id: 'remember_computer',
      options: [['Remember this computer?', 'remember_this_computer']],
    },
    
  ];

  const handleResendCode = async () => {
    setTextState(true);
    setCountDown(30); // Reset countdown timer when Resend Code button is clicked
    try {
      const resendCodeData = {
        ...props.data,
      };

      const res = await axios.post(
        axiosConfig.apiUrl + '/backend/rt/user/resendTwoFactorCode',
        resendCodeData
      );
      console.log(res);
      setStatus('complete');
      fetcher.current.message =
        'We sent a new verification code to your email.';
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = () => {
    fetcher.current.body = {
      email: props.data.email,
      remember: formState.remember_computer.includes('remember_this_computer'),
      token: formState['2fa_token'],
      app: state.urlQueries.app,
      instance: state.urlQueries.instance || props.data.instance,
    };
    fetcher.current
      .fetch()
      .then((res) => {
        console.log(res);
        if (res.data.error) {
          setStatus('error');
          fetcher.current.message = res.data.message;
        } else {
          setStatus('complete');
          axios
            .post(axiosConfig.apiUrl + '/backend/rt/user/login', props.data)
            .then((res) => {
            //   console.log(res);
            //   debugger;
            //   window.location =
            //     res.data.redirect + '?session=' + res.data.session;
            // })
            if (res.data.error) {
              setStatus('error');
              fetcher.current.message = res.data.message;
            } else {
              setStatus('complete');
             
              // window.location.href = res.data.redirect + '?session=' + res.data.session;
            }
            let redirectUri = res.data.redirect;
          if(state.urlQueries.page_name){
            const xt_page = state.urlQueries.page_name
            if(state.urlQueries.filters){
              const filters = state.urlQueries.filters
              window.location = redirectUri + `/${xt_page}` + `?filters=${filters}` + '?session=' + res.data.session
            }else{
              window.location = redirectUri + `/${xt_page}` + '?session=' + res.data.session
            }
            // window.location = redirectUri + `/${xt_page}` + '?session=' + res.data.session
          }else{
            window.location = redirectUri + '?session=' + res.data.session;
          }
        })
            .catch((err) => {
              const needsDisclaimer =
                err.response.data &&
                err.response.data.field &&
                err.response.data.field === 'disclaimer';
              console.log(err.response, needsDisclaimer, 'needs disclaimer');
         //     debugger;
              if (needsDisclaimer) {
                setStatus(needsDisclaimer ? 'complete' : 'error');
                fetcher.current.message = err.response.data.message;
                if (needsDisclaimer) {
                  props.setDisclaimerField(true);
                  props.toLogin();
                }
              } else {
                setStatus('error');
                fetcher.current.message =
                  err.message || 'unknown error occurred.';
              }
            });
        }
      })
      .catch((err) => {
        console.error(err);
        setStatus('error');
      });
  };

  React.useEffect(() => {
    if (countDown > 0 && status === 'waiting') {
      const timer = setTimeout(() => {
        setCountDown((prevCountDown) => prevCountDown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else if (countDown === 0 && status === 'waiting') {
      setTextState(false); // Reset textState to false when countdown reaches 0
    }
  }, [countDown, status]);
  const appName = props.config
    ? props.config.name : " XpertDox";
  const platformName = props.config
    ? (props.from === "xt_coding" ? "Business Intelligence Platform" : `Console`)
    : " XpertDox";
  return (
    <FormWrapper
    title={` ${appName}`}
    subheading={`${platformName}`}
      status={status}
      message={fetcher.current.message}
      backRoute={props.toLogin}
      titleStyles={props.titleStyles}
      // title='2-Step Verification'
      subTitle='Please enter the verification code that you have received in your email'
      id='2fa_form'
      active={props.active}
      btns={[
      
        {
          text: textState
            ? `Code sent (${countDown})`
            :
            `Resend code`,
            
            onClick: textState ? undefined : handleResendCode,
          style:countDown >0 ?styles.disabled1: styles.resendCodeStyle,
          disabled: countDown > 0 && textState,

         
        },

      
       ]}

      
    >
      <Form
        settings={formSettings}
        state={{
          formState: formState,
          setFormState: setFormState,
        }}
        handleSubmit={handleSubmit}
        styles={{ ...styles.formStyles, ...props.formStyles }}
       // submitText='Log In'
        additionalButtons={[
          {
            text: 'Login',
            onClick: handleSubmit,
            fullWidth:true,
          },
        ]}
        // additionalButtons1={[
        //   {
        //     text: textState
        //       ? `Code sent (${countDown})`
        //       :
        //       `Resend code`,
              
        //       onClick: textState ? undefined : handleResendCode,
        //     style:countDown >0 ?styles.disabled1: styles.resendCodeStyle,
        //     disabled: countDown > 0 && textState,
           
        //   },
         
        // ]}
      />
    </FormWrapper>
  );
};

export default TwoFactorForm;

