import { panel, absCenter, clearfix, colors } from "@xpertdox_js/xdstyle";

const messageContainer = {
  ...clearfix,
  padding: "12px",
  border: "1px solid",
  borderRadius: "4px",
  marginBottom: "10px",
};

const messageLink = {
  float: "right",
  width: "auto",
  display: "inline-block",
  padding: "8px 10px",
  borderRadius: "4px",
  color: "#fff",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formText: {
    fontSize: "1.4rem",
  },
  loaderWrap: {
    position: "relative",
    width: "100%",
    height: "320px",
  },

  formWrap: {
    ...panel,
    padding: 0,
    alignItems: "center",
    boxShadow: "0 2px 8px -4px rgba(0,0,0,0.25)",
    // borderRadius: '4px',
    marginBottom: 0,
    width: "921px",
    height: "576px",
  },
  overlayLeft: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    // ...panel,
    // background: colors.xdDarkBlue,

    // padding: 0,
    // boxShadow: '0 2px 8px -4px rgba(0,0,0,0.25)',
    // borderRadius: '4px',
    // marginBottom: 0,
    // width:'927px',
    // height:'647px'
  },
  overlayRight: {
    // ...panel,
    background: "",
    padding: 50,
    // boxShadow: '0 2px 8px -4px rgba(0,0,0,0.25)',
    // borderRadius: '4px',
    // marginBottom: 0,
    // width:'927px',
    // height:'647px'
  },
  formContent: {
    // padding: '0'
  },
  formatLink: {
    // width:'900px !important',
    // float:'left'
  },
  formTitle: {
    height: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    // borderTopRightRadius: '4px',
    // borderTopLeftRadius: '4px',
    // // padding: '16px',
    fontSize: "4rem",
    color: "#fff",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    background: colors.xdDarkBlue,
    // marginBottom:40
  },

  formTitleContent: {
    // margin: 0,
    padding: "0 5rem ",
    color: "white",
    fontSize: "3.6rem",
    margin: 0,
  },
  resendCodeStyle: {
    backgroundColor: "transparent",
    position: "absolute",
    fontSize:"1.2rem",
    marginTop: "-144px",
    marginRight: "57px",
    color: "#2E2E2E",
 
    float: "right",
    right: 0,
    cursor: "pointer !important",
    "&:hover": {
      color: "red",
    },
  },
  disabled1: {
    backgroundColor: "transparent",
    fontSize:"1.2rem",
    // width: "40%",
    display: "flex",
    position: "absolute",
    float: "right",
    right: 0,
    // cursor:"not-allowed",
    marginTop: "-144px",
    marginRight: "57px",
    color: "#3f3c3c",
    //cursor:'not-allowed',
  },
  subTitle: {
    textAlign: "center",
    fontSize: "1.5rem",
    maxWidth: "80%",
    color: colors.black,
    margin: "10px auto",
  },
  linksContainer: {
    ...clearfix,
  },
  btn: {
    background: "none",
    padding: "8px 0",
    textAlign: "center",
    float: "left",
    color: colors.darkGray,
    fill: colors.darkGray,
    display: "block",
    fontSize: "1.42rem",
    // width: "50%",
  },

  wrapperBtn: {
    borderColor: "#ffffff",
    marginTop: "1rem",
    padding: "1.5rem 5rem",
    borderRadius: "30px",
    fontSize: "14px",
    // border: "1px solid #181289",
    color: colors.darkGray,
    backgroundColor: "#ffffff",
   
    transition: "filter 0.4s ease, ",
  
  },
  wrapperBtnHov: {
   
    backgroundColor: "#FFFFFF",
    // background: white;
    boxShadow: "0 0 5px 3px rgba(255, 255, 255, 0.3)",
  },
  btnHov: {
    fill: colors.xdHighlight,
    color: colors.xdHighlight,
  },
  backSvg: {
    display: "block",
    width: "20px",
    height: "20px",
    float: "left",
    marginRight: "5px",
  },
  // form styles
  formStyles: {
    display: 'flex',
    flexWrap: 'wrap',
    submit: {
      display: "block",
      margin: "0 auto 20px",
      fontSize: "1.6rem",
      transform: "translateY(12px)",
      borderRadius: "200px",
    },
    
    
    

    
    labelText: {
      // paddingLeft: '2px',
    },
  },
  textInputStyles: {
    textInput: {
      // borderRadius: '200px'
    },
  },
  // leftLabel: {
  //   // float: "left",
  //   width: "50%",
  //   // paddingRight: "4px",
  // },
  // rightLabel: {
    
  //   width: "50%",
  //   // paddingLeft: "4px",
  // },
  leftLabel: {
    float: 'left',
  },
  rightLabel: {
    float: 'right',
  },
  message: {
    fontSize: "1.4rem",
    margin: 0,
    fontWeight: "bold",
  },
  messageLeft: {
    float: "left",
    marginTop: "6px",
  },
  completeContainer: {
    ...messageContainer,
    background: colors.lightGreen,
    color: colors.success,
    borderColor: colors.success,
  },
  errorContainer: {
    ...messageContainer,
    background: colors.lightRed,
    color: colors.error,
    borderColor: colors.error,
  },
  completeMessageLink: {
    ...messageLink,
    background: colors.success,
  },
  errorMessageLink: {
    ...messageLink,
    background: colors.error,
  },
  formSubText: {
    textAlign: 'center',
    fontSize: '1.2rem',
    color: '#424242',
  },
  subTextLink: {
    color: colors.xdHighlight,
    fontSize: "1.2rem",
    background: "none",
    border: "none",
    padding: 0,
    textDecoration: "underline",
    cursor: "pointer",
  },

  integratedLoginBtn: {
    padding: "12px 0",
    textAlign: "center",
    display: "block",
    // background: 'none',
    // color: colors.xdHighlight,
    background: colors.xdHighlight,
    color: "#fff",
    // border: '1px solid ' + colors.xdHighlight,
    width: "100%",
    // marginTop: '1px',
    marginBottom: "5px",
    borderRadius: "4px",
    fontSize: "2.4rem",
    fontWeight: "bold",
  },
  integratedLoginBtnHov: {
    // background: colors.xdHighlight,
    // color: '#fff',
    background: "#fff",
    boxShadow: "0 2px 3px rgba(0,0,0,0.1)",
    color: colors.xdHighlight,
  },
  integratedPopupMessage: {
    color: colors.error,
    fontSize: "1.5rem",
    textAlign: "center",
    marginTop: 0,
    padding: "5px",
    background: colors.lightRed,
    border: "1px solid " + colors.error,
    borderRadius: "4px",
  },
  or: {
    position: "relative",
    overflow: "visible",
    width: "100%",
    height: "1px",
    borderRadius: "20px",
    background: "#25262c",
    margin: "42px 0",
  },
  orText: {
    ...absCenter,
    display: "block",
    color: "#fff",
    fontWeight: "bold",
    background: "#25262c",
    padding: "10px 10px",
    borderRadius: "40px",
  },
};
