import {panel, absCenter, absCenterY, colors} from '@xpertdox_js/xdstyle';

const messageContainer = {
  border: '1px solid',
  padding: '18px',
  position: 'relative',
  borderRadius: '4px',
}

export default {
  wrap: {
    maxWidth: '1065px',
    margin: '0 auto'
  },
  mobileWrap: {
    paddingTop: '80px',
    width: '90%',
  },
  desktopWrap: {
    ...absCenter,
    width: '1065px',
  },
  container: {
    ...panel,
  },
  loaderWrap: {
    position: 'relative',
    width: '100%',
    height: '320px'
  },
  pageTitle: {
    marginTop: 0,
    fontSize: '2.4rem'
  },
  errorContainer: {
    ...messageContainer,
    borderColor: colors.error,
    color: colors.error,
    background: colors.lightRed,
  },
  completeContainer: {
    ...messageContainer,
    borderColor: colors.success,
    color: colors.success,
    background: colors.lightGreen,
  },
  message: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    maxWidth: '85%',
  },
  toLoginBtn: {
    ...absCenterY,
    left: 'auto',
    right: '16px',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    background: colors.success,
    color: '#fff',
    padding: '12px 18px',
    borderRadius: '4px',
  }
}
