export default {
  red: {fill: '#d12727', opacity: 0.4},
  altRed: {fill: '#e5412a', opacity: 0.4},
  blue: {fill: '#296b9a', opacity: 0.4},
  altBlue: {fill: '#397db2', opacity: 0.4},
  green: {fill: '#009378', opacity: 0.4},
  altGreen: {fill: '#00b695', opacity: 0.4},
  circle: {fill: '#fff'},
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  content: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    marginRight: '-50%',
  },
  svg: {
    display: 'block',
    width: '125px',
  }
}
