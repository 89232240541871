import config from '../../config/axios.config';
import axios from 'axios';

class FetchController {
  constructor(input) {
    this.method = input.method || 'get';
    this.url = input.url;
    this.endpoint = input.endPoint || input.endpoint || '';
    this.status = 'init';
    this.data = null;
    this.body = input.body;
    this.error = false;
    this.message = null;
    this.successMessage = input.successMessage || 'Success'
    this.errorMessage = input.errorMessage || 'Unknown Error';
    this.base = input.base;
    this.headers = input.headers;
  }

  async fetch() {
    this.status = 'fetching';
    this.message = 'Fetching Trial Information';
    let returnValue;
    await axios({
      method: this.method,
      url: (this.base ? this.base : config.apiUrl) + this.url + this.endpoint,
      data: this.body,
      headers: {...this.headers, ...config.auth.headers}
    })
    .then(data => {
      this.status = 'complete';
      if (data.error) {
        this.error = true;
        this.message = data.message || this.errorMessage;
      } else {
        this.data = data.data;
        this.message = data.data ? (data.data.message || this.successMessage) : data.message || this.successMessage;
      }
      returnValue = data;
    })
    .catch(err => {
      console.log(err, err.response)
      this.status = 'complete';
      this.error = true;
      this.message = err.response && err.response.data && err.response.data.message
      ? err.response.data.message
      : this.errorMessage;
      returnValue = err.response ? err.response.data : err.response;
    })
    return returnValue;
  }

  reset(newInput={}) {
    this.status = 'init';
    this.message = null;
    this.error = false;
    this.data = null;
    Object.keys(newInput).forEach(key => {
      this[key] = newInput[key];
    })
  }

}



const fetchController = input => {
  return new FetchController(input);
}

export default fetchController;
