// import React from 'react';

// const XdLogoBg = () => {
//   return(
//     <svg style={{
//       fill: 'rgba(0,0,0,.05)',
//       position: 'absolute',
//       left: '-5%',
//       top: 'auto',
//       right: 'auto',
//       width: '60vw',
//       height: 'auto',
//       bottom: '-10%'
//     }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496.59 448.41"><g id="Layer_1-2" data-name="Layer 1-2"><path d="M285.1,24.11c18.5-1.56,45.38,13.4,54.21,28.89l65.22,114.51c18.4,32.33,28.8,78.43,10.92,111l-38.21,69.61c-23.5,42.81-98.11,28.92-78.77-6.26l34.17-62.25c17.91-32.58,7.48-78.68-10.92-111l-57-100.15L256.53,54c-8-14-30.67-27.57-48.64-28.92Z" transform="translate(-1.41 -24)" fillRule="evenodd"/><path d="M9.85,381.08c-10.61-15.24-11.11-46-2.07-61.4L74.35,206c18.78-32.08,53.49-64.16,90.76-65l79.37-1.7c48.85-1,74.11,70.52,34,71.36l-71,1.54c-37.19.78-72,32.86-90.77,65L58.42,376.48,50,390.85c-8.13,13.93-8.54,40.34-.75,56.55Z" transform="translate(-1.41 -24)" fillRule="evenodd"/><path d="M162.33,80.45l20.9-35.62c12.21-20.81,57.56-.59,69.48,20.38l9.77,17.11-18.41,25C212.71,156.59,136.1,125.11,162.33,80.45Z" transform="translate(-1.41 -24)" fillRule="evenodd"/><path d="M119,459.83l-41.28-.25c-24.16-.19-29.33-49.58-17.12-70.39l10-17,31,3.31C159.76,378.08,170.84,460.17,119,459.83Z" transform="translate(-1.41 -24)" fillRule="evenodd"/><path d="M475.42,305.41l20.41,35.9c11.95,21-28.17,50.07-52.4,50.07H423.75l-12.52-28.51C384.22,310.92,449.79,260.31,475.42,305.41Z" transform="translate(-1.41 -24)" fillRule="evenodd"/><path d="M459.55,439.92c-7.92,16.81-34.42,32.61-52.14,32.49l-131.79-.75c-37.18-.22-82.31-14.24-101.59-46l-41.15-67.91c-25.35-41.79,24-99.43,44.81-65.07l36.81,60.71c19.28,31.8,64.44,45.82,101.62,46l115.11.69h16.65c16.12,0,39.21-12.77,49.35-27.63Z" transform="translate(-1.41 -24)" fillRule="evenodd"/></g></svg>
//   )
// }

// export default XdLogoBg;
import React from 'react';

const XdLogoBg = () => {
  return (
    <div style={{
      position: 'fixed',
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      zIndex:0,
    }}>
      <div style={{
        position: 'absolute',
        left: '12%',
        top: '25%',
        right: 'auto',
        width: '30vw',
        height: 'auto',
        // bottom: '-10%',
        pointerEvents: 'none',  // Make sure clicks go through the SVG
        opacity: '0.03'  // Adjust opacity for watermark effect
      }}>
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496.59 448.41">
          <g id="Layer_1-2" data-name="Layer 1-2">
            <path d="M285.1,24.11c18.5-1.56,45.38,13.4,54.21,28.89l65.22,114.51c18.4,32.33,28.8,78.43,10.92,111l-38.21,69.61c-23.5,42.81-98.11,28.92-78.77-6.26l34.17-62.25c17.91-32.58,7.48-78.68-10.92-111l-57-100.15L256.53,54c-8-14-30.67-27.57-48.64-28.92Z" transform="translate(-1.41 -24)" fillRule="evenodd"/>
            <path d="M9.85,381.08c-10.61-15.24-11.11-46-2.07-61.4L74.35,206c18.78-32.08,53.49-64.16,90.76-65l79.37-1.7c48.85-1,74.11,70.52,34,71.36l-71,1.54c-37.19.78-72,32.86-90.77,65L58.42,376.48,50,390.85c-8.13,13.93-8.54,40.34-.75,56.55Z" transform="translate(-1.41 -24)" fillRule="evenodd"/>
            <path d="M162.33,80.45l20.9-35.62c12.21-20.81,57.56-.59,69.48,20.38l9.77,17.11-18.41,25C212.71,156.59,136.1,125.11,162.33,80.45Z" transform="translate(-1.41 -24)" fillRule="evenodd"/>
            <path d="M119,459.83l-41.28-.25c-24.16-.19-29.33-49.58-17.12-70.39l10-17,31,3.31C159.76,378.08,170.84,460.17,119,459.83Z" transform="translate(-1.41 -24)" fillRule="evenodd"/>
            <path d="M475.42,305.41l20.41,35.9c11.95,21-28.17,50.07-52.4,50.07H423.75l-12.52-28.51C384.22,310.92,449.79,260.31,475.42,305.41Z" transform="translate(-1.41 -24)" fillRule="evenodd"/>
            <path d="M459.55,439.92c-7.92,16.81-34.42,32.61-52.14,32.49l-131.79-.75c-37.18-.22-82.31-14.24-101.59-46l-41.15-67.91c-25.35-41.79,24-99.43,44.81-65.07l36.81,60.71c19.28,31.8,64.44,45.82,101.62,46l115.11.69h16.65c16.12,0,39.21-12.77,49.35-27.63Z" transform="translate(-1.41 -24)" fillRule="evenodd"/>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default XdLogoBg;
