// import xdlogowhite from "../../assets/white-logo.svg"
// LIFESPAN="https://www.xpertdox.com/static/LIFESPAN_white-c041402af6f6f21af7f9bbeb9b43b092.svg"
// UAB="https://www.xpertdox.com/static/UAB_white-2d8e824635fef3ec83e94046795714bf.svg"
// STANFORD="https://www.xpertdox.com/static/STANFORD_white-d73e43bdbcdbd986667217ef8e3a8b70.svg"
// XD="xdlogowhite"
// UC4C="https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/UC4C_white.svg"
// PMP="https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/PMP_white.svg"
// CVMG="https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/CVMG_white.svg"
// AFCP="https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/POSITIVE_white.svg"
// QVISIT="https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/Qvisit+White.svg"
// BFISH="https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/BFISH_white.svg"
// MTM="https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/MTM_white.svg"
// VYBE="https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/Vybe_white.svg"
// GTWAY="https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/GTWAY_white.svg"
// NSM="xdlogowhite"


import React from 'react';
import xdlogowhite from '../../assets/white-logo.svg';

// Define a mapping between props and images
const imageMap = {
    LIFESPANHEALTH: "https://www.xpertdox.com/static/LIFESPAN_white-c041402af6f6f21af7f9bbeb9b43b092.svg",
    UAB: "https://www.xpertdox.com/static/UAB_white-2d8e824635fef3ec83e94046795714bf.svg",
    STANFORD: "https://www.xpertdox.com/static/STANFORD_white-d73e43bdbcdbd986667217ef8e3a8b70.svg",
    XD: xdlogowhite,
    UC4C: "https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/UC4C_white.svg",
    PMP: "https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/PMP_white.svg",
    CVMG: "https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/CVMG_white.svg",
    AFCP: "https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/POSITIVE_white.svg",
    QVISIT: "https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/Qvisit+White.svg",
    BFISH: "https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/BFISH_white.svg",
    MTM: "https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/MTM_white.svg",
    VYBE: "https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/Vybe_white.svg",
    GTWAY: "https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/GTWAY_white.svg",
    NSM: xdlogowhite
};

const ImageDisplay = ({ app }) => {
    // Remove spaces from the prop value
    const cleanedApp = app.replace(/\s+/g, '').toUpperCase();
    
    
    // Get the image URL based on the cleaned prop value
    const imageUrl = imageMap[cleanedApp] || imageMap['XD']; // Default to XD logo if app is not found

    return <img src={imageUrl} alt={cleanedApp}  width={240} style={{margin:"-2rem 0 2rem 0"}} />;
};

export default ImageDisplay;
