export default {
  container: {
    display:"block"

  },
  textInput: {
    transition: 'box-shadow .2s',
    width: '100%',
    padding: '8px',
    height:'50px',
    border: '1px solid',
    background: '#E8F0FE',
    border: '2px solid #000000',
    outline: 'none',
    maxWidth: '100%',
    borderRadius: '8px',
    // marginBottom:20,
   
    marginTop:10,
    'nth-child(2)':{
      marginLeft: 30,
    }

  },
  textInputFoc: {
    boxShadow: 'inset 0 2px 3px rgba(0,0,0,0.12)',
    borderColor: 'rgba(0,22,66,0.5)',
    background: '#fff'
  },
  textarea: {
    height: '145px'
  },
  eyeIcons:{
    height:20,
    position:'absolute',
    display:'ruby',
    margin:'25px -30px',
    cursor:'pointer',
    width:20,
    float:'right'

  }
}
