const lightBorder = '1px solid rgba(255,255,255,0.2)';
const darkBorder = '1px solid rgba(0,0,0,0.18)'
const depthBorder = {
  borderTop: darkBorder,
  borderLeft: darkBorder,
  borderBottom: lightBorder,
  borderRight: lightBorder
}
const depthBorderAlt = {
  borderTop: lightBorder,
  borderLeft: lightBorder,
  borderBottom: darkBorder,
  borderRight: darkBorder
}
export default {
  container: {
    position: 'relative',
    width: '60px',
    height: '30px',
    borderRadius: '250px',
    background: 'rgba(0,0,0,0.45)',
    display: 'table',
    clear: 'both',
    content: '',
    cursor: 'pointer',
    boxShadow: 'inset 0 1px 5px rgba(0,0,0,0.1)',
    transition: '.25s',
    ...depthBorder,
  },
  containerOn: {
    background: '#2C81D2',
  },
  switch: {
    background: 'linear-gradient(to bottom right, #fff, #efefef)',
    borderRadius: '250px',
    width: '24px',
    height: '24px',
    transition: '.1s ease-in',
    position: 'absolute',
    top: '50%', left: 0, right: 'auto',
    transform: 'translateY(-50%)',
    transition: 'left .28s ease-out',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    ...depthBorderAlt,
  },
  text: {
    color: '#fff',
    fontSize: '1.25rem',
    maxWidth: '50%',
  }
}
