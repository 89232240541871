import {configPanel, clearfix, absCenter} from '../globalStyles';

export default {
  container: {
    ...clearfix,
    position: 'relative',
    boxShadow: '0 2px 8px rgba(0,0,0,0.075)',
    borderRadius: '5px',
    width: '100%',
    maxWidth: '320px',
    border: '1px solid rgba(0,0,0,0.15)'
  },
  menuItem: {
    background: '#25262c',
    padding: '8px',
    border: 'none',
    width: '100%',
    textAlign: 'left',
    borderBottom: '1px solid rgba(0,0,0,0.42)'
  },
  menuItemHov: {
    background: '#2C81D2',
  },
  selectMenu: {
    ...configPanel,
    padding: 0,
    marginTop: '8px',
    position: 'absolute',
    top: '100%',
    animation: 'slide-in-from-bottom-w-fade .32s ease-out forwards',
    width: '100%',
    zIndex: 1001,
    maxHeight: '280px',
    overflowY: 'auto'
  },
  display: {
    background: '#fff',
    float:'left',
    borderRadius:'5px',
    width: '65%',
    minWidth: '120px',
    position: 'relative',
    height: '40px',
  },
  displayText: {
      position: 'absolute',
      left: '10px',
      maxWidth: '100%',
      overflowX: 'hidden',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#7b7b7b'
  },
  button: {
    padding: '12px 8px 13px',
    float: 'left',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderRight: '1px solid rgba(0,0,0,0.1)',
    borderColor: 'rgba(0,0,0,0.1)',
    width: '35%',
    minWidth: '88px',
    position:'relative',
    height: '40px',
    background: 'rgba(0,0,0,0.05)',
    color: '#25262c'
  },
  btnText: {
    ...absCenter,
  },
  buttonActive: {
    background: '#2C81D2',
    color: '#fff',
    boxShadow: 'inset 0 2px 5px rgba(0,0,0,0.25)',
    borderColor: 'rgba(0,0,0,0.25)'
  },
  buttonHov: {
    background: 'rgba(0,0,0,0.08)',
  },
  placeholder: {
    color: '#6f6f6f'
  },
  clickOverlay: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    background: 'none',
    border: 'none',
    outline: 'none',
    display: 'block',
    width: '100%',
    height: '100%',
    zIndex: 1000,
  }
}
