export default {
    display: "inline-block",
    border: "none",
    padding: "12px 16px",
    textAlign: "center",
    color: "#fff",
    background: "#cd4d4e",
    cursor: "pointer",
    outline: 'none'
}
