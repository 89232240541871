import React, {useState} from 'react';
import styles from './styles';
import {NavLink} from 'react-router-dom';

const StyledNavLink = props => {
  const [hover, setHover] = useState(false);

  const hoverOn = () => setHover(true);
  const hoverOff = () => setHover(false);
  const compiledStyles = {
    ...styles.navLink,
    ...props.styles,
  }
  const compiledHoverStyles = {
    ...styles.navHover,
    ...props.hoverStyles
  }

  const linkStyles = hover
  ? {
    ...compiledStyles,
    ...compiledHoverStyles
    }
  : compiledStyles;

  return(
    <NavLink
      isActive={props.callback || null}
      style={linkStyles}
      onMouseEnter={hoverOn}
      onMouseLeave={hoverOff}
      to={props.to}
      activeStyle={{...styles.navLink, ...styles.navActive, ...props.activeStyles}}
    >
      {props.children || props.text}
    </NavLink>
  )
}

export default StyledNavLink;
