import colors from '../../colors';
import {btn_c_link, btn_li_lg, font_s_sm, font_s_lg, svg_inline, svg_sm} from '../../styleGuide';
import {absCenterY, clearfix} from '../../globalStyles';


export default {
  tabContainer: {
  ...clearfix,
  },
  tab: {
    float: 'left',
    position: 'relative',
    borderRight: '1px solid rgba(0,0,0,0.2)',
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    background: colors.offWhite,
    cursor: 'pointer',
  },
  tabIcon: {
    ...absCenterY,
    ...svg_inline,
    ...svg_sm,
    fill: colors.xdDarkBlue,
  },
  tabBtn: {
    position: 'relative',
    width: '220px',
    maxWidth: '100%',
    minHeight: '48px',
    padding: '12px',
    paddingRight: 0,
    color: colors.xdDarkBlue,
    ...font_s_lg,
    fontWeight: 'bold',
    overflow: 'hidden',
    background: 'none',
    transition: 'background .2s',
    maxHeight: '47px',
    textAlign: 'left'
  },
  tabBtnHov: {
    background: '#f2f4f8'
  },
  tabText: {
    display: 'block',
    paddingLeft: '40px',
  },
  tabTextWithSubTitle: {
    lineHeight: '28px',
    transform: 'translateY(-9px)',
  },
  tabSubTitle: {
    ...font_s_sm,
    display: 'block',
    lineHeight: '4px',
    color: 'rgba(0,0,0,0.42)'
  },
  tabLine: {
    ...absCenterY,
    display: 'block',
    left: '42px',
    height: '50%',
    width: '2px',
    borderRadius: '5px',
    background: 'rgba(14,0,20,0.6)',
    opacity: .42,
    borderRight: '1px solid #fff',
  },
  tabMenu: {
    position: 'absolute',
    width: '220px',
    maxWidth: '80%',
    background: colors.offWhite,
    borderRight: '1px solid rgba(0,0,0,0.2)',
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    zIndex: 20,
    backfaceVisibility: 'hidden',
    boxShadow: '2px 4px 4px rgba(0,0,0,0.16)'
  },
  menuListItem: {
    ...btn_li_lg,
    ...btn_c_link.base,
  },
  menuListItemHov: {
    ...btn_c_link.hover,
    background: '#fff',
  },
}
