export default () => {
  let urlQueries = {};
  if (window.location.href.includes('?')) {
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        urlQueries[key] = value;
    });
    let urlParts = window.location.href.split('?');
    urlParts.forEach((val, i) => {
      if (i !== 0) {
        let subParts = val.split('=');
        urlQueries[subParts[0]] = subParts[1];
      }
    })
  }
  return urlQueries;
}
