export default {
  main: '#b1040e',
  secondary: null,
  black: '#25262c',
  offWhite: '#fafdfe',
  shade: '#f9f9f9',
  lightGray: 'rgba(232, 232, 232, 0.6)',
  gray: '#bababc',
  darkGray: '#5d5e60',
  success: '#16b867',
  error: '#E62712',
  green: '#0f8048',
  lightGreen: '#eef9f4',
  lightRed: '#fce9e7',
  background: '#e5e8ec',
  highlight: '#eef7fe',
  xdDarkBlue: '#445461',
  xdFadedBlue: '#BCC7D0',
  xdHighlight: '#2c81d2',
  xdHighlightAlt: '#569adb',
  lightBlue: '#e9f2fa',
}
