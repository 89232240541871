export default (searchQuery) => {
  let search = searchQuery || window.location.search;
  let result = {};
  if (search) {
    search.split('?').forEach(split => {
      let query = split.split('=');
      if (query[1]) result[query[0]] = query[1];
    })
  }
  return result;
}
