// import React, {useState} from 'react';
// import styles from './styles';
// import Icons from '../../../Icons';

// const TextField = props => {

//   const [focus, setFocus] = useState(false);
//   const [type, setType] = useState(props.type);

//   const focusOn = () => setFocus(true);
//   const focusOff = (e) => {
//     setFocus(false);
//     if (props.onBlur){
//       setTimeout(() => {
//         props.onBlur(e);
//       }, 150)
//     }
//   }
//   const txtInputStyles = props.styles
//   ? {...styles.textInput, ...props.styles}
//   : styles.textInput;
//   const txtareaInputStyles = props.styles
//   ? {...txtInputStyles, ...styles.textarea, ...props.styles}
//   : {...txtInputStyles, ...styles.textarea};

//   const inputStyles = type === 'textarea' ? txtareaInputStyles : txtInputStyles;
//   const focusStyles = props.styles
//   ? {...styles.textInputFoc, ...props.styles.textInputFoc}
//   : styles.textInputFoc;

//   let elementStyles = focus ? {...inputStyles, ...focusStyles} : inputStyles;

//   const handleUpdate = e => {
//     // props.setter({
//     //    value: e.target.value,
//     //    id: e.target.id,
//     //    name: e.target.name,
//     // })

//     let synthValue = null, validPhone;

//     if (type === 'name')
//     synthValue = e.target.value.replace(/[^a-zA-Z '-]/g, "");

//     if (type === 'phone') {
//       let value = e.target.value.replace(/[^0-9]/g, "");
//       const length = value.length;
//       // strip characters as needed
//       if (length > 10 && value.charAt(0) === '1') value = value.slice(1, value.length);
//       // only numbers
//       validPhone = length <= 10
//       && (value.match(/^[-+]?[0-9() -]+$/) || value === '');
//       // synthetic value formatting
//       if (length === 4) synthValue = `(${value.slice(0, 3)}) ${value.charAt(3)}`
//       else if (length > 4 && length <= 6)
//         synthValue = `(${value.slice(0, 3)}) ${value.slice(3, value.length)}`
//       else if (length > 6)
//         synthValue = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, value.length)}`
//       else synthValue = value;
//     }
//     synthValue = synthValue === null ? e.target.value : synthValue;

//     const validUpdate = type === 'phone' ? validPhone
//     // : props.type === 'name' ? validName
//     : true;

//     if (validUpdate) {
//       // console.log(e.keyCode, e);
//       // if (e.keyCode === 13 && props.type === 'textarea' && props.options.submitOnEnter) {
//       //   e.preventDefault();
//       //   props.handleSubmit();
//       // } else {
//         props.setter({
//           value: (synthValue || ''),
//           id: e.target.id,
//           name: e.target.name
//         })
//       // }
//     }
//   }

//   const handleTypeChange = () => {
//     if (type === 'text') setType('password');
//     else setType('text')
//   }

//   const element = React.createElement(
//     type === 'textarea' ? 'textarea' : 'input',
//     {
//       type: type,
//       value: props.value,
//       placeholder: props.placeholder,
//       onFocus: focusOn,
//       onBlur: focusOff,
//       onChange: handleUpdate,
//       onKeyPress: props.options.submitOnEnter && type === 'textarea' ? e => {
//         if (e.nativeEvent && e.nativeEvent.keyCode === 13) {
//           e.preventDefault();
//           props.handleSubmit({value: e.target.value, id: e.target.id, name: e.target.name});
//         }
//       } : null,
//       id: props.id,
//       name: props.id,
//       style: elementStyles,
//       autoFocus: props.autoFocus,
//       autoComplete: props.autoComplete,
//     }
//   );

//   const showPwdBtn = (type === 'password' || type === 'text') && props.id && props.id.includes('password')

//   return(
//     <div style={styles.container}>
//       {element}
//       <span className="label-text" style={{position: "absolute", top:" 50%", left: "10px", transform: "translateY(-50%)", background:" rgb(232, 240, 254)", padding:" 0 5px", transition: "all 0.2s ease"}}>{props.placeholder}</span>
//       {showPwdBtn && <Icons icon={type === 'password' ? 'eyeopen' : 'eye'} style={styles.eyeIcons}  onClick={handleTypeChange}/>}
//     </div>
//   )
// }


// export default TextField;

import React, { useState, } from 'react';
import styles from './styles';
import Icons from '../../../Icons';

const TextField = props => {
  const [focus, setFocus] = useState(false);
  const [type, setType] = useState(props.type);

  const focusOn = () => setFocus(true);
  const focusOff = (e) => {
    setFocus(false);
    if (props.onBlur) {
      setTimeout(() => {
        props.onBlur(e);
      }, 150);
    }
  };

  const txtInputStyles = props.styles
    ? { ...styles.textInput, ...props.styles }
    : styles.textInput;
  const txtareaInputStyles = props.styles
    ? { ...txtInputStyles, ...styles.textarea, ...props.styles }
    : { ...txtInputStyles, ...styles.textarea };

  const inputStyles = type === 'textarea' ? txtareaInputStyles : txtInputStyles;
  const focusStyles = props.styles
    ? { ...styles.textInputFoc, ...props.styles.textInputFoc }
    : styles.textInputFoc;

  let elementStyles = focus ? { ...inputStyles, ...focusStyles } : inputStyles;

  const handleUpdate = e => {
    let synthValue = null, validPhone;

    if (type === 'name')
      synthValue = e.target.value.replace(/[^a-zA-Z '-]/g, "");

    if (type === 'phone') {
      let value = e.target.value.replace(/[^0-9]/g, "");
      const length = value.length;
      if (length > 10 && value.charAt(0) === '1') value = value.slice(1, value.length);
      validPhone = length <= 10 && (value.match(/^[-+]?[0-9() -]+$/) || value === '');
      if (length === 4) synthValue = `(${value.slice(0, 3)}) ${value.charAt(3)}`;
      else if (length > 4 && length <= 6)
        synthValue = `(${value.slice(0, 3)}) ${value.slice(3, value.length)}`;
      else if (length > 6)
        synthValue = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, value.length)}`;
      else synthValue = value;
    }
    synthValue = synthValue === null ? e.target.value : synthValue;

    const validUpdate = type === 'phone' ? validPhone : true;

    if (validUpdate) {
      props.setter({
        value: (synthValue || ''),
        id: e.target.id,
        name: e.target.name
      });
    }
  };

  const handleTypeChange = () => {
    if (type === 'text') setType('password');
    else setType('text');
  };

  const element = React.createElement(
    type === 'textarea' ? 'textarea' : 'input',
    {
      type: type,
      value: props.value,
      // placeholder: props.placeholder,
      onFocus: focusOn,
      onBlur: focusOff,
      onChange: handleUpdate,
      
      onKeyPress: props.options.submitOnEnter && type === 'textarea' ? e => {
        if (e.nativeEvent && e.nativeEvent.keyCode === 13) {
          e.preventDefault();
          props.handleSubmit({ value: e.target.value, id: e.target.id, name: e.target.name });
        }
      } : null,
      id: props.id,
      name: props.id,
      style: elementStyles,
      autoFocus: props.autoFocus,
      autoComplete: "off",
    }
  );

  const showPwdBtn = (type === 'password' || type === 'text') && props.id && props.id.includes('password');

  return (
    <div style={{ ...styles.container, position: 'relative' }}>
      {element}
      <span className="label-text" style={{
        position: "absolute",
        top: focus || props.value ? "2px" : "50%",
        left: focus || props.value? "14px" : "5px",
        transform: focus || props.value ? "translateY(0)" : "translateY(-50%)",
        background:  focus || props.value ? "white" : "#e8f0fe",
        borderRadius:"10px",
        border:"1px solid #E8F0FE97",
        padding: !focus && ! props.value  ? "5px 103px 0px 7px" : "0 5px",
        color:props.value||focus ?"black":'#3c3c3c',
        transition: "all 0.2s ease",
        fontSize: focus || props.value ? "13px" : "14px",
        pointerEvents: "none"
      }}>{props.placeholder}</span>
      {showPwdBtn && <Icons icon={type === 'password' ? 'eyeopen' : 'eye'} style={styles.eyeIcons} onClick={handleTypeChange} />}
    </div>
  );
};

export default TextField;
