const termsOfUse = {
    introduction_part1:`The Business Intelligence Platform (this “Site”) is provided by XpertDox, LLC (“XpertDox,” “we,” or “us”), allowing clients or client’s representatives (“You,” “Client,” “Your,” and “Yourself”) to monitor medical coding data generated by the Site. XpertCoding is an AI-powered medical coding solution that uses advanced algorithms, natural language processing (NLP), and machine learning to automate the coding of medical claims, aiming for higher accuracy and faster claims submissions.
    This user agreement (“User Agreement”) governs your access to and use of this Site and the Site Materials and constitutes a binding agreement between you and XpertDox. We reserve the right to change this User Agreement at our sole discretion. Your continued use of this Site or any Site Materials after any change takes effect constitutes your acceptance of the new User Agreement. In the event of any conflict between this User Agreement and any Service Agreement you enter into with XpertDox, the terms of the Service Agreement will take precedence.
    PLEASE READ THIS USER AGREEMENT CAREFULLY BEFORE ACCESSING, USING, OR BROWSING THIS SITE OR ANY SITE MATERIALS. BY CLICKING ON “I AGREE,” OR BY ACCESSING, USING, OR BROWSING THIS SITE OR ANY SITE MATERIALS, YOU, ON BEHALF OF YOURSELF OR YOUR ENTITY, AS APPLICABLE, ACKNOWLEDGE AND CONFIRM THAT:
    `,
    introduction_part2:`IF YOU OR YOUR ENTITY, AS APPLICABLE, ARE NOT WILLING TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, INCLUDING THE PRECEDING ACKNOWLEDGEMENT AND AGREEMENT, DO NOT ACCESS, USE, OR BROWSE OUR SITE; XPERTDOX DOES NOT AND WILL NOT GRANT YOU ANY RIGHT OR LICENSE TO ACCESS, USE, OR BROWSE THIS SITE OR THE SITE MATERIALS.`,
    disclaimer:`XpertDox provides no warranties for goods and services on the  Site unless expressly stated in a Service Agreement. XpertDox disclaims all express or implied warranties of title, merchantability, fitness for a particular purpose, or non-infringement. XpertDox is not liable for any damages arising from inaccuracies, errors, or interruptions on the   Site. In no event shall XpertDox's liability exceed the fees you paid to XpertDox in connection with your use of or access to the Site.`,
    license:`If you agree with this User Agreement, XpertDox grants you a revocable, non-exclusive, non-transferable, limited license to access and view this  Site and the  Site’s Materials. XpertDox reserves all rights not expressly granted. This license is conditional on compliance with this User Agreement, which XpertDox may modify at its discretion.`,
    ownership:`All rights, title, and interest in the  Site and the Site’s Materials, including data, systems, and methodologies, are owned by XpertDox or its affiliates and are protected by IP laws. XpertDox does not claim rights in unaltered government data. Users must not engage in any activity that constitutes copyright infringement or misuse of the  Site’s Materials.`,
    prohibitions:`You may not reproduce, create derivative works, distribute, publicly perform, or display any  Site’s materials without prior written consent from XpertDox. Unauthorized use includes transmitting, downloading, uploading, posting, selling, renting, licensing, transferring, disclosing, mirroring, framing, reverse engineering, decompiling, or disassembling any part of the  Site or  Site’s Materials. You must comply with HIPAA guidelines and Compiling Data for commercial purposes or using it in violation of laws is prohibited. `,
    trademark:`You must obtain prior written permission from XpertDox before using any of its marks. Users may not use any XpertDox trademarks, including “XpertDox” and “XpertCoding,” without express prior written consent. Misuse of these trademarks, including any action likely to deceive or cause confusion, is prohibited. All other names, logos, product and service names, designs, and slogans appearing on this Site are trademarks of their respective owners.`,
    confidentiality:`You agree to protect the confidentiality of the data on the  Site with the same degree of care as your own confidential information, but no less than a reasonable degree of care. Disclosure pursuant to a judicial or administrative order is not a breach if you provide timely notice to XpertDox and cooperate to limit the scope of the order. Breach of confidentiality obligations will cause irreparable harm, and XpertDox is entitled to injunctive and other relief without needing to prove actual damage, inadequacy of legal remedies, or post a bond.`,
    property_rights:`The Site and all its contents, features, and functionality including but not limited to all information, software, User Interface, placement of functions, text, displays, images, video, audio, and the design, selection, and arrangement thereof are owned by XpertDox, its licensors, or other providers of such material, and are protected by copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. These Terms of Use permit you to use the Site for personal use only. You may not access or use the Site, any part of the Site, or any materials available through the Site for commercial purposes, except with written permission from XpertDox. You must not:`,
    property_rights_part2:`If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Site in violation of these Terms of Use, your right to use the Site will cease immediately. You must, at our option, return or destroy any copies of the materials you have made and confirm the destruction in writing. A breach of these terms constitutes a breach of trust, and appropriate actions will be taken as per applicable law. No right, title, or interest in the Site or any content on the Site is transferred to you, and all rights not expressly granted are reserved by XpertDox. Any use of the Site not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.
    XpertDox actively enforces its intellectual property rights. For inquiries about alternative arrangements regarding the data, contact support@xpertdox.com.`,
    terms_termination:`This User Agreement takes effect when you click “I Agree” or access, use, or browse this  Site or any  Site’s Materials. We reserve the right to deny access, change, remove, or discontinue the  Site or any  Site’s Materials without notice. Upon termination of this User Agreement, all licenses granted will immediately and automatically terminate, and you may no longer use the  Site or  Site’s materials.`,
    privacy_policy: `We prioritize the protection of personal data and are committed to ensuring compliance with applicable privacy regulations, including the Health Insurance Portability and Accountability Act (HIPAA). Our Privacy Policy outlines our data collection, use practices, and measures we take to safeguard personal health information. By agreeing to this User Agreement, you also agree to our Privacy Policy, which is available on our Site.`,
    links:`This Site may contain links to third-party sites. We are not responsible for the content, policies, or practices of these sites, nor are we liable for anything posted on any site. These links do not imply endorsement or affiliation. Please review the terms and policies of each linked site.`,
    third_party:`Some Site Materials are provided by independent information providers (“IIPs”) and EHRs or EMRs or any other sources or applications. We do not guarantee or endorse the availability, accuracy, reliability, or completeness of information from IIPs. Private information delivered to third parties via links on this  Site is subject to the privacy policies of those third parties, not XpertDox.`,
    security:`We take the utmost precautions to secure the Site. To ensure the security of PHI Data and client data, we implement comprehensive measures, including regular audits and monitoring of access logs to detect unauthorized activities. Physical security measures, such as secure workstations and restricted access to data centers, are also in place to safeguard data. XpertDox maintains regular data backups, disaster recovery plans, and secure communication channels, provides training on HIPAA regulations, and adheres to other data protection best practices.
    If users violate any of these good practices, including but not limited to using weak passwords, sharing login credentials, accessing data over unsecured networks, or failing to follow proper disposal methods. In that case, XpertDox is not liable for any resulting data loss or breaches. For detailed information on our security measures, please visit the Data Security Center on our website.`,
    account_security:`XpertDox is not liable if any part or all of the Services on the Site are unavailable at any time or for any duration. We may occasionally restrict access to certain parts or the entirety of the Site, including for registered users. Additionally, XpertDox is not liable for any breaches of your account, whether due to actions from our end or yours. This includes, but is not limited to, unauthorized access, security breaches, loss of data, or any related issues arising from account access management.`,
    account_security_part2:`To access the Site’s Services, you may need to provide certain registration details or other information. To access, use, or download the Site, you may be required to provide XpertDox with certain information about yourself (or the person or entity on whose behalf you are accessing, using, or downloading the Site). This information may include your name, email address, and telephone number. It is a condition of your use of the Services that all the information you provide is accurate, current, and complete. You agree that all information you provide for registration or otherwise, including through any interactive features on XpertDox.com, is governed by our Privacy Policy. All information collected through the Services is subject to our Privacy Policy. By using the Services, you consent to all actions taken by us with respect to your information in accordance with the Privacy Policy.`,
    health_info_compliance:`Certain services that XpertDox provides to our healthcare provider customers involve access to and the processing of Protected Health Information (PHI) as defined under HIPAA and other applicable privacy laws. XpertDox will, subject to agreements with our healthcare customers or clients, comply with these privacy laws applicable to business associates and maintain the confidentiality of PHI transmitted or made available through our Site.
    You acknowledge and agree that these Terms may be amended as necessary to comply with privacy laws. Providers using the Site to collect, store, and use, data must obtain the required consent and comply with all applicable laws regarding such information.
    `,
    health_info_compliance_part2:`When you use the Site to upload, transmit, or receive PHI, you agree to comply with all applicable local, state, federal, or international laws, including privacy laws. You represent and warrant that you will comply with all applicable laws governing the handling of PHI. To the extent permitted by applicable laws, you grant XpertDox a perpetual, unlimited license to use de-identified data related to you for data benchmarking, sharing, warehousing, resource utilization, and similar data analysis services, in compliance with applicable laws. XpertDox disclaims any liability for your use or misuse of PHI or other information transmitted, monitored, stored, or received while using the Site. XpertDox reserves the right to amend or delete any uploaded content, and to revoke membership or restrict access to the Site, at its sole discretion, if any content violates these Terms or applicable laws.`,
    loss_of_data:`You acknowledge that data conversion and transmission within the Site are subject to potential human and machine errors, omissions, delays, and losses, including inadvertent loss of data or damage to media, which may result in loss or damage. XpertDox disclaims any liability for errors, omissions, delays, or losses. You also agree that accessing, using, or downloading the Site via the Internet or mobile devices is inherently insecure. That information transmitted and received through such use may be subject to unauthorized interception, diversion, corruption, loss, access, and disclosure. XpertDox disclaims any liability for any adverse consequences resulting from your access, use, or download of the Site via the Internet or mobile devices, and for any violations of applicable laws or intellectual property rights resulting from your Internet connection.`,
    comm_with_XpertDox:`If you send or receive email communications to or from XpertDox, you represent that your email service has adequate security systems to prevent unauthorized access. Content in any electronic communication from XpertDox is subject to this User Agreement.
    `,
    site_monitoring:`We reserve the right to monitor and record activity on this Site without notice. We may disclose records, communications, information, materials, or other content as required by law or to protect our rights or property. We are not responsible for screening or monitoring the Site. Users must comply with all applicable laws and regulations.`,
    force_majeure:`We will not be deemed to be in breach of these Terms or liable for any breach of these Terms or our Privacy Policy due to any event or occurrence beyond our reasonable control, including without limitation, acts of God, terrorism, war, invasion, failures of any public networks, electrical shortages, Internet outage, labor disturbance, earthquakes or floods, civil disorder, strikes, fire, pandemics, epidemics, or other disasters (including without limitation, natural, technological, or social). XpertDox endeavors to provide access to the Site 24/7 and to conduct necessary repairs or maintenance for smooth operation. However, XpertDox disclaims any liability for any failures or deficiencies in the Site's performance, whether due to maintenance, breakdowns, or any events beyond XpertDox's reasonable control. You release us and our affiliates from claims resulting from causes beyond our control and unnatural causes, including equipment failure, communication issues, unauthorized access, natural disasters, labor problems, wars, or governmental restrictions.`,
    disclaimer_warranties:`This Site and the Site Materials are provided “as is” for informational purposes. We disclaim all warranties, express or implied, regarding the Site, Site Materials, and any products or services obtained through the Site. Use of the Site and Site Materials is at your own risk.
    XpertDox disclaims all warranties and conditions, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.`,
    disclaimer_warranties_part2:`Some states and jurisdictions do not allow limitations on implied warranties, so the above limitation may not apply to you. These provisions shall be enforceable to the maximum extent permitted by applicable laws.`,
    medical_disclaimers:`Your use of the site and any information or content included on or transmitted through the Site does not create any healthcare provider relationship between you and XpertDox or its affiliates. 
    Any content available on or through the Site is for informational and educational purposes only and is not a substitute for professional medical judgment in diagnosing and treating patients. The content and services provided are not intended to replace medical diagnosis or treatment. XpertDox does not recommend or endorse any specific drugs, tests, physicians, products, procedures, opinions, or other information mentioned on or transmitted through the Site. Your reliance on any information obtained or used by you is solely at your own risk.`,
    medical_disclaimers_part2:`XpertDox disclaims any liability for the accuracy of any information or content provided or sent by users, including physicians, healthcare providers, insurance providers, or their representatives, or transmitted through applications like EHRs or EMRs or any other sources applicable sources. You are responsible for verifying the accuracy of any information you send or receive through the Site.`,
    medical_disclaimers_part3:`XpertDox makes the Site available as an informative and educational resource. While XpertDox may review content posted on the Site, it does not guarantee the timeliness or accuracy of any content. Neither the authors (whether users or not), XpertDox, nor any other party involved in the preparation or publication of any content can assure you that such content is accurate or complete. XpertDox disclaims liability for any errors or omissions or for results obtained from the use of such content.`,
    medical_disclaimers_part4:`XpertDox disclaims any liability in connection with your use of the site for medical decision-making.`,
    liability_limitation:`We are not liable for any damages arising from your use of, or reliance on, the Site or Site Materials. Your sole remedy for any problem is to cease using the Site or Site Materials. We are not liable for indirect, incidental, special, punitive, exemplary, or consequential damages. Under no circumstances will we or our affiliates, or any of our or their respective directors, officers, shareholders, proprietors, partners, employees, agents, representatives,  attorneys, predecessors, successors or assigns, be liable for any indirect, incidental, special, punitive, exemplary or consequential damages (including, but not limited to, lost profits and damages that result from inconvenience, delay, or loss of use) arising out of use of this Site, any linked websites, any content within the Services, or any items obtained through the Services. This includes, but is not limited to, direct, indirect, special, incidental, consequential, exemplary, or punitive damages, such as personal injury, pain, and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, or loss of data, whether caused by negligence and gross negligence, breach of contract, strict liability, or otherwise, even if foreseeable. This applies regardless of any fault by XpertDox or the third parties mentioned on the Services or any affiliated services, websites, or applications), even if they have been advised or not advised of the possibility of such damages. These provisions shall be enforceable to the maximum extent permitted by applicable law and do not affect any liability that cannot be excluded or limited under applicable law.`,
    indemnity:`You agree to indemnify and protect XpertDox and our Affiliates, and our and their respective directors, officers, shareholders, proprietors, partners, employees, agents, representatives,  attorneys, predecessors, successors, from any claims, liabilities, damages, judgments, awards, losses, costs, or expenses (including without limitation, attorney's fees and costs) incurred in connection with any third-party claim, demand or action (including, without limitation, any regulatory or government authority, or third party in a judicial action or administrative proceeding) from your use of the Site, any Site Materials, or any products or services obtained through the Site, or any breach of this User Agreement. This indemnification covers any issues arising from your breach of these Terms or your use of the Services, including, but not limited to, any unauthorized use of the content, services, or products provided by the Services, or any information obtained through the Services.`,
    governing_law:`This User Agreement is governed by Delaware law. Any action related to this Site or User Agreement must be brought in the federal or state courts in Delaware. Use of the Site is limited to those who agree to resolve disputes in these courts.`,
    changes_site:`We may update the content on this Site periodically, but it may not always be complete or current. Any material on the Site could be out of date at any given time, and we are not obligated to update such material.`,
    notices:`Notices to you may be posted on the Site or sent to the email address you provide. Notices are deemed given when posted on the Site or when sent to your email address. For any inquiries or to contact us, please reach out at support@xpertdox.com or via mail at XpertDox, LLC, 8687 East Via de Ventura, Suite 302, Scottsdale, AZ 85258.`,
    procedure_for_claims:`If you believe that content on the Site infringes your copyright, you may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing:`,
    procedure_for_claims_part2:`Submit your claim to support@xpertdox.com or mail it to: Attention: Copyright Agent, XpertDox, LLC, 8687 East Via de Ventura, Suite 302, Scottsdale, AZ 85258.`,
    procedure_for_intellectual_property:`These Terms of Use prohibit users from violating another party’s intellectual property rights. For intellectual property violations other than copyright claims, rights holders may submit a valid notice of intellectual property infringement to XpertDox via email at support@xpertdox.com.
    A valid notice of infringement must identify the following:`,
    procedure_for_intellectual_property_part2:`You may also contact us by mail at Attention: Legal Department, XpertDox, LLC, 8687 East Via de Ventura, Suite 302, Scottsdale, AZ 85258. By submitting a valid notice of infringement, you agree that we may provide your request to the user who posted the material addressed in your notice. Upon receipt of a valid notice of intellectual property infringement, XpertDox may remove or disable access to the allegedly infringing content at its sole discretion. XpertDox may terminate the accounts of repeat infringers under appropriate circumstances.`,
    severability:`If any provision of these Terms is deemed void or unenforceable by a court, arbitral tribunal, administrative officer, or other competent authority, such provision(s) will be limited or eliminated to the minimum extent necessary and replaced with a valid provision that best reflects the intent of these Terms. All other provisions will remain in full force and effect.`,
    agreement:`These Terms constitute the entire agreement between you and XpertDox regarding your access to and use of the Site and Site Materials, superseding all prior understandings or agreements. XpertDox will not accept counter-offers to these Terms and will reject all such counter-offers. You acknowledge that we have relied on your acceptance of this User Agreement to provide you with access to the Site and Site Materials. No joint venture, partnership, employment, or agency relationship exists between you and XpertDox as a result of this User Agreement or your use of the Site.`,
    assignment:`XpertDox may assign its rights, in whole or in part, in the Site at its sole discretion.`,
    no_agency:`Nothing in these Terms creates any agency, partnership, or other form of joint enterprise between you and XpertDox.`,
    no_waiver:`XpertDox's failure or delay to insist on or enforce strict performance of these Terms will not be construed as a waiver of any provision or any right XpertDox has to enforce these Terms. If any portion of these Terms is adjudged invalid or unenforceable by a court of competent jurisdiction, the remaining portions will remain valid and enforceable.`,
    third_party_beneficiary:`These Terms do not confer any rights or remedies on any third parties.`,
    last_update:`June 2024`,
}
export default termsOfUse