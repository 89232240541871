import React from 'react';
import styles from './styles';

const DismissPage = props => {
  return(
    <div style={styles.container}>
      <div style={styles.content}>
        <h2 style={styles.message}>Oops! Something went wrong.</h2>
      </div>
    </div>
  )
}

export default DismissPage;
