import React from 'react';
import termsOfUse from '../../content/termsOfUse';
import "./style.css";
import xdLogo from '../../assets/xd-logo.png'
import WaterMarkBg from '../WaterMarkBg';
const TermOfUse = () => {
  return (<>
        <WaterMarkBg/>
    <div style={{padding:"2rem 10rem", maxWidth:"1440px" ,margin :"auto" , background:"white"}}>
    <img src={xdLogo} alt="" srcset="" style={{margin:"4rem 0rem 0rem 0rem" }} />
    <h4  style={{textAlign:"center", marginBottom:"10px" ,fontSize:"32px"}}>Terms of Use</h4>
               
               <p id="terms_title">INTRODUCTION</p>
               <p id="terms_text">{termsOfUse.introduction_part1}</p>
               <ul id="terms_list">
                   <li id="terms_list_item"><p id="terms_text">A.  YOU HAVE READ AND UNDERSTOOD ALL THE TERMS AND CONDITIONS OF THIS USER AGREEMENT.</p></li>
                   <li id="terms_list_item"><p id="terms_text">B.  YOU OR YOUR ENTITY, AS APPLICABLE, AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS USER AGREEMENT AND ACKNOWLEDGE THAT THIS USER AGREEMENT IS THE LEGAL EQUIVALENT OF A SIGNED, WRITTEN CONTRACT BETWEEN XPERTDOX AND YOU OR YOUR ENTITY, AS APPLICABLE.</p></li>
                   <li id="terms_list_item"><p id="terms_text">C.  IF ACCESSING, USING, OR BROWSING THIS SITE ON BEHALF OF AN ENTITY, YOU HAVE FULL AUTHORITY TO BIND YOUR ENTITY TO ALL OF THE TERMS AND CONDITIONS OF THIS USER AGREEMENT.</p></li>
               </ul>
               <p id="terms_text">{termsOfUse.introduction_part2}</p>
               <p id="terms_title">Disclaimer of Warranties and Limitation of Liability</p>
               <p id="terms_text">{termsOfUse.disclaimer}</p>
               <p id="terms_title">License</p>
               <p id="terms_text">{termsOfUse.license}</p>
               <p id="terms_title">Ownership and Confidentiality Notices</p>
               <p id="terms_text">{termsOfUse.ownership}</p>
               <p id="terms_title">Prohibited Activities</p>
               <p id="terms_text">{termsOfUse.prohibitions}</p>
               <p id="terms_title">Trademark Usage</p>
               <p id="terms_text">{termsOfUse.trademark}</p>
               <p id="terms_title">Confidentiality Obligations</p>
               <p id="terms_text">{termsOfUse.confidentiality}</p>
               <p id="terms_title">Enforcement of Intellectual Property Rights</p>
               <p id="terms_text">{termsOfUse.property_rights}</p>
               <ul id="terms_list">
               <li id="terms_list_item"><p id="terms_text">1.  Reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Site.</p></li>
               <li id="terms_list_item"><p id="terms_text">2.  Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Site.</p></li>
               </ul>
               <p id="terms_text">{termsOfUse.property_rights_part2}</p>
               <p id="terms_title">Terms and Termination</p>
               <p id="terms_text">{termsOfUse.terms_termination}</p>
               <p id="terms_title">Privacy Policy</p>
               <p id="terms_text">{termsOfUse.privacy_policy}</p>
               <p id="terms_title">Links</p>
               <p id="terms_text">{termsOfUse.links}</p>
               <p id="terms_title">Third-Party Content and Privacy Information Delivered to Third Parties</p>
               <p id="terms_text">{termsOfUse.third_party}</p>
               <p id="terms_title">Security</p>
               <p id="terms_text">{termsOfUse.security}</p>
               <p id="terms_title">Accessing the Services and Account Security</p>
               <p id="terms_text">{termsOfUse.account_security}</p>
               <p id="terms_text">{termsOfUse.account_security_part2}</p>
               <p id="terms_title">Protected Health Information Compliance</p>
               <p id="terms_text">{termsOfUse.health_info_compliance}</p>
               <p id="terms_text">{termsOfUse.health_info_compliance_part2}</p>
               <p id="terms_title">Indemnification from Loss of Data </p>
               <p id="terms_text">{termsOfUse.loss_of_data}</p>
               <p id="terms_title">Electronic Communications with XpertDox</p>
               <p id="terms_text">{termsOfUse.comm_with_XpertDox}</p>
               <p id="terms_title">Site Monitoring</p>
               <p id="terms_text">{termsOfUse.site_monitoring}</p>
               <p id="terms_title">Force Majeure or Events Beyond Our Control</p>
               <p id="terms_text">{termsOfUse.force_majeure}</p>
               <p id="terms_title">Disclaimer of Warranties</p>
               <p id="terms_text">{termsOfUse.disclaimer_warranties}</p>
               <ul id="terms_list">
               <li id="terms_list_item"><p id="terms_text">1.  You assume sole responsibility for selecting the Site and its content to achieve your intended results and for using and obtaining results from the Site and its content.</p></li>
               <li id="terms_list_item"><p id="terms_text">2.  XpertDox makes no representation or warranty that the Site, any related applications, or their content will be error-free, secure, virus-free, or free from interruptions, defects, or harmful components, or that they will meet your specific requirements or be compatible with your equipment or software. XpertDox does not guarantee that any inaccuracies or errors will be corrected.</p></li>
               <li id="terms_list_item"><p id="terms_text">3.  XpertDox makes no representation or warranty regarding the accuracy, timeliness, or error-free nature of information obtained from third-party sources or users of the Site.</p></li>
               <li id="terms_list_item"><p id="terms_text">4.  XpertDox makes no representation or warranty that the information provided by users to gain access and permission to use the Site or any related applications is accurate or error-free.</p></li>
               <li id="terms_list_item"><p id="terms_text">5. XpertDox makes no representation or warranty regarding the suitability, character, or integrity of any persons participating in the Site or related applications.</p></li>
               </ul>
               <p id="terms_text">{termsOfUse.disclaimer_warranties_part2}</p>
               <p id="terms_title">Medical Disclaimers</p>
               <p id="terms_text">{termsOfUse.medical_disclaimers}</p>
               <p id="terms_text">{termsOfUse.medical_disclaimers_part2}</p>
               <p id="terms_text">{termsOfUse.medical_disclaimers_part3}</p>
               <p id="terms_text">{termsOfUse.medical_disclaimers_part4}</p>
               <p id="terms_title">Limitations of Liability</p>
               <p id="terms_text">{termsOfUse.liability_limitation}</p>
               <p id="terms_title">Indemnity</p>
               <p id="terms_text">{termsOfUse.indemnity}</p>
               <p id="terms_title">Governing Law and Selection of Venue</p>
               <p id="terms_text">{termsOfUse.governing_law}</p>
               <p id="terms_title">Changes to the Site</p>
               <p id="terms_text">{termsOfUse.changes_site}</p>
               <p id="terms_title">Notices - Contacting XpertDox</p>
               <p id="terms_text">{termsOfUse.notices}</p>
               <p id="terms_title">Notice and Procedure for Claims of Copyright Infringement</p>
               <p id="terms_text">{termsOfUse.procedure_for_claims}</p>
               <ul id="terms_list">
               <li id="terms_list_item"><p id="terms_text">1.  An electronic or physical signature of the person authorized to act on behalf of the copyright owner.</p></li>
               <li id="terms_list_item"><p id="terms_text">2.  A description of the copyrighted work that you claim has been infringed.</p></li>
               <li id="terms_list_item"><p id="terms_text">3.  A description of where the material that you claim is infringing is located on the Site, reasonably sufficient to enable us to identify and locate the material.</p></li>
               <li id="terms_list_item"><p id="terms_text">4.  Your contact information, including address, telephone number, and email address.</p></li>
               <li id="terms_list_item"><p id="terms_text">5.  A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</p></li>
               <li id="terms_list_item"><p id="terms_text">6.  A statement that the information in your notice is accurate and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</p></li>
               </ul>
               <p id="terms_text">{termsOfUse.procedure_for_claims_part2}</p>
               <p id="terms_title">Notice and Procedure for Claims of Intellectual Property</p>
               <p id="terms_text">{termsOfUse.procedure_for_intellectual_property}</p>
               <ul id="terms_list">
               <li id="terms_list_item"><p id="terms_text">1.  The work claimed to have been infringed (e.g., trademark registration).</p></li>
               <li id="terms_list_item"><p id="terms_text">2.  The material that is claimed to be infringing with information sufficient to locate the allegedly infringing material on the Site.</p></li>
               <li id="terms_list_item"><p id="terms_text">3.  Your contact information, including address, telephone number, and a valid email address.</p></li>
               <li id="terms_list_item"><p id="terms_text">4.  A statement by you that you have a good faith belief that use of the material is not authorized.</p></li>
               </ul>
               <p id="terms_text">{termsOfUse.procedure_for_intellectual_property_part2}</p>
               <p id="terms_title">Miscellaneous</p>
               <p id="terms_title">Severability</p>
               <p id="terms_text">{termsOfUse.severability}</p>
               <p id="terms_title">Entire Agreement</p>
               <p id="terms_text">{termsOfUse.agreement}</p>
               <p id="terms_title">Assignment</p>
               <p id="terms_text">{termsOfUse.assignment}</p>
               <p id="terms_title">No Agency</p>
               <p id="terms_text">{termsOfUse.no_agency}</p>
               <p id="terms_title">No Waiver</p>
               <p id="terms_text">{termsOfUse.no_waiver}</p>
               <p id="terms_title">No Third Party Beneficiary</p>
               <p id="terms_text">{termsOfUse.third_party_beneficiary}</p>
               <p id="terms_text_margin"><strong >Last Update : {termsOfUse.last_update}</strong> </p>
              
    </div>
  </>
  )
}
export default TermOfUse;
