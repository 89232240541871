export default (inDelay) => ({
  in: [
    ['opacity', {0: 0, 100: 1}, 320, {
      // easing: 'easeOut',
      delay: inDelay.current,
    }],
    ['transform', {0: 200, 70: -51, 100: -50}, 420, {
      valueMap: 'translateY($v%)',
      easing: 'easeOut',
      delay: inDelay.current,
    }],
  ],
  out: [
    ['opacity', {0: 1, 100: 0}, 320, {
      // easing: 'easeIn'
    }],
    ['transform', {0: -50, 30: -51, 100: 200}, 420, {
      valueMap: 'translateY($v%)',
      easing: 'easeIn'
    }],
  ]
})
