import React, {useState, useRef} from 'react';
import Form from '../General/Form';
import styles from './styles';
// import Button from '../General/Button';
import fetchController from '../General/fetchController';
import Loader from '../General/Loader';
import {SsoContext} from '../hooks/ssoContext';
import FormWrapper from './FormWrapper';

const RegisterForm = props => {
  const {state, toggleModal} = React.useContext(SsoContext)
  const [formState, setFormState] = useState({});
  const [formStatus, setFormStatus] = useState('idle');
  const [messageLink, setMessageLink] = useState(null);

  const fetcher = useRef(fetchController({
    method: 'post',
    url: '/backend/rt/user/',
    endpoint: 'register/',
  }))

  const formSettings = [
    {
      type: 'text',
      placeholder: 'First Name',
      id: 'reg_first_name',
    
      labelStyles: {width: true},
      validators: ['fieldRequired', 'name'],
    },
    {
      type: 'text',
      placeholder: 'Last Name',
      id: 'reg_last_name',
     
      labelStyles: { width: true},
      validators: ['fieldRequired', 'name'],
    },
    {
      type: 'email',
    
      placeholder: 'Email Address',
      id: 'reg_email',
    },
  ]

  const consoleFormFields = [
    {
      type: 'phone',
      placeholder: 'Phone Number',
      id: 'reg_phone',
      // labelStyles: styles.leftLabel,
      labelStyles: {width: true},
      notRequired: true,
    },
    {
      type: 'text',
      placeholder: 'Division / Department',
      id: 'reg_division',
      // labelStyles: styles.rightLabel,
      labelStyles: {width: true},
      notRequired: true,
    },
    {
      type: 'text',
      placeholder: 'Affiliation',
      id: 'reg_affiliation',
      // labelStyles: styles.leftLabel,
      labelStyles: {width: true},
      notRequired: true,
    },
    {
      type: 'text',
      placeholder: 'Title',
      id: 'reg_title',
      // labelStyles: styles.rightLabel,
      labelStyles: {width: true},
      notRequired: true,
    },
  ]

  if (props.from === 'xt_console' || props.from === 'xt_screen') {
    formSettings.push(...consoleFormFields);
  }

  formSettings.push(...[
    {
      type: 'password',
      placeholder: 'Password',
      id: 'reg_password',
      labelStyles: {width: true},
    },
    {
      type: 'password',
      placeholder: 'Confirm Password',
      id: 'reg_confirm_password',
      confirmPassword: 'reg_password',
      labelStyles: {width: true},
    }
  ])

  if (props.from === 'xt_console') {
    formSettings.push(...[
      
      {...props.disclaimerFieldSettings.field, value: formState[props.disclaimerFieldSettings.field.id]}
    ])
  }

  const handleSubmit = () => {
    console.log(formState)
    setFormStatus('fetching');
    if (messageLink !== null) setMessageLink(null);
    fetcher.current.reset();
    fetcher.current.body = {
      name: formState.reg_first_name + ' ' + formState.reg_last_name,
      email: formState.reg_email,
      phoneNumber: formState.reg_phone,
      affiliation: formState.reg_affiliation,
      division: formState.reg_division,
      userTitle: formState.reg_title,
      password: formState.reg_password,
      instance: state.urlQueries.instance || (props.config ? props.config.id : null),
      type: props.from,
      verification: {
        studyRepDisclaimer: formState.disclaimer_radio === 'disclaimer_agree',
      }
    }
    fetcher.current.fetch()
    .then(res => {
      if (fetcher.current.error) {
        setFormStatus('error');
        console.log(res, 'error')
        if (fetcher.current.message.includes('proceed to login')) setMessageLink({
          to: props.toLogin,
          text: 'Login',
        })
      } else {
        setFormStatus('complete');
        console.log(res, 'registered')
      }
    })
  }

  const additionalButtonsData = [
    {
      onClick: props.toRegister,
      text: 'Sign Up',
    }
  ]

  const appName = props.config ? props.config.name : "XpertDox";
  const platformName = props.config
    ? (props.from === "xt_coding" ? "Business Intelligence Platform" : `Console`)
    : "XpertDox";

  return (
    <FormWrapper
      title={` ${appName}`}
      subheading={`${platformName}`}
      id='register_form'
      from={props.from}
      titleStyles={props.titleStyles}
      backRoute={props.toLogin}
      message={fetcher.current.message}
      subTitle={state.urlQueries.app !== 'xt_console' && state.urlQueries.app !== 'xt_dashboard'
        ? `Join the community of patients, family members, providers and researchers at ${state.instanceConfig ? state.instanceConfig.name : 'XpertDox'} to further research!`
        : null
      }
      status={formStatus}
      active={props.active}
      formMessageLink={messageLink}
      additionalButtons={{ text: 'Login', onClick: props.toLogin }}
    >
      <Form
        settings={formSettings}
        handleSubmit={handleSubmit}
        styles={{ ...styles.formStyles, ...props.formStyles }}
        additionalButtons={additionalButtonsData}
        state={{
          formState: formState,
          setFormState: setFormState
        }}
        validators={props.disclaimerFieldSettings.validator}
      />
      <p style={{...styles.formSubText, marginTop:"10px" }}>
        <button
          style={styles.subTextLink}
          onClick={() => toggleModal('moreInfo', true)}
        >
          Tell Me More
        </button> about the information I provide.
      </p>
    </FormWrapper>
  )
}

export default RegisterForm;
