import React, { useState } from "react";
import "./styles.css";
import SignInForm from "./SignIn";
import SignUpForm from "./SignUp";
import VerificationForm from "./Verification";
import ForgetPasswordForm from "./ForgetPassword";
import xdlogo from "../../assets/xd-logo.png"
import xdlogowhite from "../../assets/white-logo.svg"
import BackgroundAnimation from "./BackgroundAnimation";
import TermOfUse from "../TermOfUse";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
export default function Layout() {
  const [type, setType] = useState("signIn");

  const handleOnClick = (text) => {
    if (text !== type) {
      setType(text);
      return;
    }
  };

  const containerClass = "container " + (type === "signUp" ? "right-panel-active" : "");

  return (<>
  
      <BackgroundAnimation/>
    <div className="layout">
      <div className={containerClass} id="container">
        {type === "signIn" && (
          <SignInForm onForgotPassword={() => handleOnClick("forgetPassword")} />
        )}
        {type === "signUp" && <SignUpForm />}
        {/* {type === "verification" && <VerificationForm />} */}
        {type === "forgetPassword" && <ForgetPasswordForm />}
        <div className="overlay-container">
          <div className="overlay">
          
          <div className="overlay-panel overlay-left">
              {/* <img src="https://www.xpertdox.com/static/LIFESPAN_white-c041402af6f6f21af7f9bbeb9b43b092.svg" alt="" srcset="" width={200} style={{margin:"-2rem 0 3rem 0"}}/> */}
              {/* <img src="https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/Qvisit+White.svg" alt="" srcset="" width={200} style={{margin:"-2rem 0 3rem 0"}}/> */}
              <img src={xdlogowhite} alt="" srcset="" width={200} style={{margin:"-2rem 0 3rem 0"}}/>
              
              <h1>Welcome Back!</h1>
              <p>To keep connected with us please login with your personal info</p>
              <button className="ghost" id="signIn" onClick={() => handleOnClick("signIn")}>
                Sign In
              </button>
              <div style={{display:"flex", justifyContent:"center", width:"100%", margin:"2rem 0 0 0"}}>
                <span style={{fontSize:"10px"}}>

              {/* By clicking submit button, you agree to our  <br/> */}
              <Link to="/term-of-use"  target="_blank" style={{ color: "white", marginY: "4rem!important", cursor:"pointer", borderBottom:"1px solid white",fontSize:"10px"}}> Terms of Use</Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/privacy-policy"  target="_blank" style={{ color: "white", marginY: "1rem!important", cursor:"pointer", borderBottom:"1px solid white",fontSize:"10px"}}> Privacy Policy</Link>
                </span>
              </div>
            </div><div className="overlay-panel overlay-right">
              {/* <img src="https://www.xpertdox.com/static/LIFESPAN_white-c041402af6f6f21af7f9bbeb9b43b092.svg" alt="" srcset="" width={200} style={{margin:"-2rem 0 3rem 0"}}/> */}
              {/* <img src="https://xpertdox.s3.us-west-2.amazonaws.com/cdn/Sponsor+Logos/Qvisit+White.svg" alt="" srcset="" width={200} style={{margin:"-2rem 0 3rem 0"}}/> */}
              <img src={xdlogowhite} alt="" srcset="" width={200} style={{margin:"-2rem 0 3rem 0"}}/>
              <h1 style={{fontSize:"30px"}}><strong>Business Intelligence Platform</strong></h1>
              
              {/* <p>Enter your personal details and start journey with us</p> */}
                 {type==="forgetPassword"&&<button  className="ghost " id="signInBtnFP" onClick={() => handleOnClick("signIn")}>
                Sign In
              </button>}
              <div style={{display:"flex", justifyContent:"center", width:"100%", margin:"2rem 0 0 0"}}>
                <span style={{fontSize:"10px"}}>

              {/* By clicking submit button, you agree to our  <br/> */}
              <Link to="/term-of-use"  target="_blank" style={{ color: "white", marginY: "4rem!important", cursor:"pointer", borderBottom:"1px solid white",fontSize:"10px"}}> Terms of Use</Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/privacy-policy"  target="_blank" style={{ color: "white", marginY: "1rem!important", cursor:"pointer", borderBottom:"1px solid white",fontSize:"10px"}}> Privacy Policy</Link>
                </span>
              </div>
               {/* <div style={{background:"white", borderRadius:"15px", padding:" 3px 10px" }}>
              
                </div> */}

            </div>
           
          
          </div>
        </div>
      </div>
    {/* <div id="xd_footer_logo" style={{background:"",display:"flex", justifyContent:"start", margin:"1rem 0px"}}>

      <img src={xdlogo} alt="" srcset="" width={100} />
    </div> */}
    </div>
      </>
  );
}