import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// adjust font sizes for high pixel density screens
let scale, testVal = window.innerHeight < window.innerWidth ? window.innerHeight : window.innerWidth;
if (testVal > 4000) scale = 2
else if (testVal > 3000) scale = 1
else scale = 0;
document.getElementsByTagName('html')[0].style.fontSize =
scale === 2 ? '82.5%'
: scale === 1 ? '72.5%'
: '62.5%';


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
