import {absCenter, clearfix, } from '@xpertdox_js/xdstyle';

export default {
  animationWrap: {
    ...absCenter,
    
  
   position: 'absolute',
    left: '50%',
   
   maxWidth: '921px',
   maxHeight:'auto',
borderRadius:"12px",
    
    overflow: 'hidden',
    top: '50%',
  },
  disclaimer: {
    margin: '8px 0 0px 0',
  },
  header: {
    ...clearfix,
    width: '100%',
    padding: '12px 18px',
  },
  logo: {
    float: 'left',
    width: 'auto',
    height: '36px'
  },
  logoRight: {
    float: 'right',
    width: 'auto',
    height: '36px',
  },
  modalContainer: {
    padding: '16px 12px',
    fontSize: '1.6rem',
    overflowY: 'hidden',
  },
  modalText: {
    margin: 0,
  }
}
