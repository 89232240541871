import React from 'react';
import logo from './logo.svg';
import './App.css';
import styles from './styles';
import {BrowserRouter, Route} from 'react-router-dom';
import VerifyEmailLandingPage from './components/VerifyEmailLandingPage';
import LoginLandingPage from './components/LoginLandingPage';
import ListOfInstancesPage from './components/ListOfInstancesPage';
import {ResponsiveContextProvider} from './components/General/Context/ResponsiveContext';
import {SsoContextProvider} from './components/hooks/ssoContext';
import SessionTest from './components/SessionTest';
import {UserContextProvider} from './components/SessionTest/SsoUserContext';
import axios from 'axios';
import axiosConfig from './config/axios.config';
import instanceSetup from './components/hooks/instanceSetup';
import parseUrlQueries from './components/hooks/parseUrlQueries';
import DismissPage from './components/DismissPage';
import Layout from './components/layout';
import TermOfUse from './components/TermOfUse';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {

  const isUserAgentIE = () => {
    //Check the userAgent property of the window.navigator
    // UA here is used for User Agent
    const UA = window.navigator.userAgent;
    // check if IE 10 or older
    const msie = UA.indexOf('MSIE ');
     // check if IE 11
    const trident = UA.indexOf('Trident/');
    return (msie > 0 || trident > 0);
  }

  axios.get(axiosConfig.apiUrl + '/backend/rt/sso/app_version/sso')
  .then(res => {
    if (res.data && res.data.version && localStorage.appVersion) {
      if (localStorage.appVersion !== res.data.version) {
        localStorage.setItem('appVersion', res.data.version);
        if(!isUserAgentIE() && caches) {
          caches.keys().then(names => {
            for(let name of names) caches.delete(name)
          })
        }
        window.location.reload(true);
      }
    } else if (res.data && res.data.version) localStorage.setItem('appVersion', res.data.version)
  })
  .catch(err => console.error(err));

  return (
    <div style={styles.container} id='application_container'>
    <SsoContextProvider>
    <ResponsiveContextProvider>
    <BrowserRouter>
      <Route exact path='/' component={DismissPage} />
        <Route exact path='/sso/' component={LoginLandingPage} />
        <Route exact path='/sso/instances' component={ListOfInstancesPage} />
        <Route exact path='/sso/resetPassword/' component={LoginLandingPage} />
        <Route  path='/sso/layout' component={Layout} />
        <Route path='/sso/verify-email' component={VerifyEmailLandingPage} />
        <Route exact path='/term-of-use' component={TermOfUse} />
        <Route exact path='/privacy-policy' component={PrivacyPolicy} />
        <UserContextProvider>
          <Route path='/sso/sessionTest/' component={SessionTest} />
        </UserContextProvider> 
    </BrowserRouter>
    </ResponsiveContextProvider>
    </SsoContextProvider>
  </div>

  );
}

export default App;
