// import React, { useRef, useState } from 'react';
// import Form from '../General/Form';
// import styles from './styles';
// import fetchController from '../General/fetchController';
// import urlQueryParser from '../Parsers/urlQueryParser';
// import { SsoContext } from '../hooks/ssoContext';
// import FormWrapper from './FormWrapper';

// const ForgotPasswordForm = (props) => {
//   const queries = urlQueryParser();
//   const { state } = React.useContext(SsoContext);
//   const [form1State, setForm1State] = useState({});
//   const [form2State, setForm2State] = useState({});
//   const [step, setStep] = useState(() => (window.location.pathname.includes('resetPassword') ? 2 : 1));
//   const [status, setStatus] = useState('idle');
//   const emailRef = useRef('');
//   const updateStatus = (status) => {
//     setStatus(status);
//   };

//   const fetcher = useRef(fetchController({
//     url: '/backend/rt/user/',
//     method: 'post',
//   }));

//   const form1Settings = [
//     {
//       type: 'email',
//       label: 'Email Address',
//       placeholder: 'Email Address',
//       id: 'fetch_rec_code_email',
//     },
//   ];
//   const form2Settings = [
//     {
//       type: 'text',
//       label: 'Verification Code From Email',
//       placeholder: 'Verification Code',
//       value: queries.vc,
//       id: 'verification_code',
//       attributes: {
//         autoComplete: 'new-password',
//       },
//     },
//     {
//       type: 'password',
//       label: 'New Password',
//       placeholder: 'Password',
//       id: 'reset_password',
//       attributes: {
//         autoComplete: 'new-password',
//       },
//     },
//     {
//       type: 'password',
//       label: 'Confirm New Password',
//       placeholder: 'Confirm Password',
//       confirmPassword: 'reset_password',
//       id: 'confirm_reset_password',
//     },
//   ];

//   const handleSubmit = () => {
//     updateStatus('fetching');
//     fetcher.current.reset(step === 1 ? {
//       endpoint: 'requestPasswordReset',
//       body: {
//         email: form1State.fetch_rec_code_email,
//         appId: props.from,
//         instance: state.urlQueries.instance || state.instanceConfig.id,
//       },
//     } : {
//       endpoint: 'updatePassword',
//       body: {
//         token: form2State.verification_code,
//         email: emailRef.current,
//         id: window.location.pathname.includes('resetPassword') ? queries.id : null,
//         password: form2State.reset_password,
//         instance: state.urlQueries.instance || state.instanceConfig.id,
//         app: state.urlQueries.app,
//       },
//     });
//     fetcher.current.fetch()
//       .then((res) => {
//         if (res.data.error) {
//           if (res.status === 428) {
//             setTimeout(() => {
//               updateStatus(step === 1 ? 'idle' : 'complete');
//               if (step === 1) setStep(2);
//             }, 1500);
//           } else {
//             updateStatus('error');
//           }
//         } else {
//           if (step === 1) emailRef.current = form1State.fetch_rec_code_email;
//           updateStatus(step === 1 ? 'idle' : 'complete');
//           if (step === 1) setStep(2);
//           if (step === 2) {
//             setTimeout(() => {
//               props.toLogin();
//             }, 3000);
//           }
//         }
//       })
//       .catch(() => {
//         updateStatus('error');
//       });
//   };

//   const buttonContainerStyles = {
//     display: 'flex', // Flex layout for horizontal arrangement
//     justifyContent: 'space-between', // Space between buttons
//     marginTop: '20px', // Margin at the top of the container,
    
//   };

  
//   const showForm = status !== 'complete' && status !== 'fetching';
 
//   return (
//     <FormWrapper
//       status={status}
//       message={fetcher.current.message}
//       subTitle={step === 1 ? 'Provide your email address, and we will email you a link to change your password.'
//         : step === 2 ? 'If you have previously registered with this email, you should receive an email with the verification code.' : ''}
//       backRoute={step === 1 ? props.toLogin : () => setStep(1)}
//       title="Reset Password"
//       titleStyles={props.titleStyles}
//       id="forgot_password_form"
//       memo={step}
//       active={props.active}
      
//     >
//       {step === 1 && showForm && (
//         <Form
//           handleSubmit={handleSubmit}
//           settings={form1Settings}
//           state={{
//             formState: form1State,
//             setFormState: setForm1State,
//           }}
          
//           styles={{
//             ...styles.formStyles,
//             ...props.formStyles,
//           }}
        

//           additionalButtons1={[
           
//             {
//               text: 'Send Password Reset Link',
//               handleSubmit: handleSubmit,
//               fullWidth:true
            
            
//             },
           
//           ]}
          
//           additionalButtons={[
           
//             {
//               text: 'Login',
//               onClick: props.toLogin,
            
              
//             },
//             {
//               text: 'Sign Up',
//               onClick: props.toRegister,
//             },
//           ]}
          
//         />
//       )}

//       {step === 2 && showForm && (
//         <Form
//           id="reset_password_form_element"
//           autoComplete="off"
//           handleSubmit={handleSubmit}
//           settings={form2Settings}
//           state={{
//             formState: form2State,
//             setFormState: setForm2State,
//           }}
//           styles={{
//             ...styles.formStyles,
//             ...props.formStyles,
//             submitButton: {
//               width: '100%', // This line sets the width to full width
//               ...props.formStyles?.submitButton, // Preserve any existing submit button styles
//             },
//           }}
//          // submitText="Reset Password"

//           additionalButtons1={[
           
//             {
//               text: 'Reset Password',
//               handleSubmit: handleSubmit,
//               fullWidth:true
            
            
//             },
           
//           ]}
          
//           additionalButtons={[
//             {
//               text: 'Login',
//               onClick: props.toLogin,

//             },
//             {
//               text: 'Sign Up',
//               onClick: props.toRegister,
//             },
//           ]}
//           additionalButtonContainerStyle={buttonContainerStyles}
//         />
//       )}
//     </FormWrapper>
//   );
// };

// export default ForgotPasswordForm;

import React, { useRef, useState, useContext } from 'react';
import Form from '../General/Form';
import styles from './styles';
import fetchController from '../General/fetchController';
import urlQueryParser from '../Parsers/urlQueryParser';
import { SsoContext } from '../hooks/ssoContext';
import FormWrapper from './FormWrapper';

const formSettings = {
  1: [
    {
      type: 'email',
      // label: 'Email Address',
      placeholder: 'Email Address',
      id: 'fetch_rec_code_email',
    },
  ],
  2: [
    {
      type: 'text',
      // label: 'Verification Code From Email',
      placeholder: 'Verification Code',
      value: urlQueryParser().vc,
      id: 'verification_code',
      attributes: { autoComplete: 'new-password' },
    },
    {
      type: 'password',
      // label: 'New Password',
      placeholder: 'New Password',
      id: 'reset_password',
      attributes: { autoComplete: 'new-password' },
    },
    {
      type: 'password',
      // label: 'Confirm New Password',
      placeholder: 'Confirm Password',
      confirmPassword: 'reset_password',
      id: 'confirm_reset_password',
    },
  ],
};

const ForgotPasswordForm = (props) => {
  const { from, toLogin, toRegister, titleStyles, active } = props;
  const queries = urlQueryParser();
  const { state } = useContext(SsoContext);

  const initialState = {
    formState: {},
    step: window.location.pathname.includes('resetPassword') ? 2 : 1,
    status: 'idle',
  };

  const [formData, setFormData] = useState(initialState);
  const { formState, step, status } = formData;
  const emailRef = useRef('');

  const fetcher = useRef(fetchController({ url: '/backend/rt/user/', method: 'post' }));

  const updateState = (key, value) => setFormData((prev) => ({ ...prev, [key]: value }));

  const getRequestBody = () => {
    const body = step === 1
      ? {
          email: formState.fetch_rec_code_email,
          appId: from,
          instance: state.urlQueries.instance || state.instanceConfig.id,
        }
      : {
          token: formState.verification_code,
          email: emailRef.current,
          id: window.location.pathname.includes('resetPassword') ? queries.id : null,
          password: formState.reset_password,
          instance: state.urlQueries.instance || state.instanceConfig.id,
          app: state.urlQueries.app,
        };
    return { endpoint: step === 1 ? 'requestPasswordReset' : 'updatePassword', body };
  };

  const handleSuccess = () => {
    if (step === 1) {
      emailRef.current = formState.fetch_rec_code_email;
      updateState('step', 2);
    } else {
      setTimeout(toLogin, 3000);
    }
    updateState('status', step === 1 ? 'idle' : 'complete');
  };

  const handleError = (res) => {
    if (res.status === 428) {
      setTimeout(() => {
        updateState('status', step === 1 ? 'idle' : 'complete');
        if (step === 1) updateState('step', 2);
      }, 1500);
    } else {
      updateState('status', 'error');
    }
  };

  const handleSubmit = () => {
    updateState('status', 'fetching');
    fetcher.current.reset(getRequestBody());
    fetcher.current.fetch()
      .then((res) => (res.data.error ? handleError(res) : handleSuccess()))
      .catch(() => updateState('status', 'error'));
  };
  const appName = props.config
    ? props.config.name : " XpertDox";
  const platformName = props.config
    ? (props.from === "xt_coding" ? "Business Intelligence Platform" : `Console`)
    : " XpertDox";
  const renderForm = () => (
    <Form
      handleSubmit={handleSubmit}
      settings={formSettings[step]}
      state={{ formState, setFormState: (newState) => updateState('formState', newState) }}
      styles={{ ...styles.formStyles, ...props.formStyles }}
      additionalButtons={[{ text: step === 1 ? 'Send OTP' : 'Reset Password', handleSubmit, fullWidth: true }]}
      // additionalButtons={[{ text: 'Login', onClick: toLogin }, { text: 'Sign Up', onClick: toRegister }]}
      additionalButtonContainerStyle={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}
    />
  );

  return (
    <FormWrapper
    title={` ${appName}`}
    subheading={`${platformName}`}
      status={status}
      message={fetcher.current.message}
      subTitle={step === 1 
        ? 'Provide your email address, and we will email you OTP to change your password.'
        : 'If you have previously registered with this email, you should receive an email with the verification code.'
      }
      backRoute={step === 1 ? toLogin : () => updateState('step', 1)}
      // title="Reset Password"
      titleStyles={titleStyles}
      id="forgot_password_form"
      memo={step}
      active={active}
      additionalButtons={{ text: 'Login', onClick: toLogin }}
      
    >
      {status !== 'complete' && status !== 'fetching' && renderForm()}
    </FormWrapper>
  );
};

export default ForgotPasswordForm;
