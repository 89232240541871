const msalConfig = {
  auth: {
    clientId: "ccbe012b-7434-4f1e-8678-fe5e530ee839",
    // authority: "https://login.microsoftonline.com",
    authority: "supportxpertdox.onmicrosoft.com",
    redirectUri: "Enter_the_Redirect_Uri_Here",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

const msalConfig2 = {
  appId: '27e4071f-6b27-4475-8639-bd1a0110429c',
  // appId: '1a7ef835-4e48-4c7c-b1b2-3d5ab452f010',
  // appId: '6f010c4e-3ba8-4cea-8c8a-691b80c8efe9',
  // appId: '85149b04-3c56-4df2-b482-2754985b8c95',
  // redirectUri: 'http://localhost:3001/sso',
  // redirectUri: 'https://accounts.xpertdox.com/sso',
  redirectUri: 'https://accounts.xpertdox.com/sso',
  authority: "https://login.microsoftonline.com/organizations",
  scopes: [
    'user.read',
    'calendars.read'
  ]
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
const loginRequest = {
  scopes: ["openid", "profile", "User.Read"]
};

// Add here scopes for access token to be used at MS Graph API endpoints.
const tokenRequest = {
  scopes: ["Mail.Read"]
};

export default {
  msalConfig,
  msalConfig2,
  loginRequest,
  tokenRequest,
}
