import React from 'react';
import styles from './styles';

const ToggleSwitch = props => {

  const text = (props.text || ['Yes', 'No']);
  const toggle = () => props.setter(props.setterParam || !props.condition);

  let condition = props.type !== 'approved' ? props.condition : !props.condition;

  let mergeStyles = {...styles};
  if (props.styles) {
    Object.keys(props.styles).forEach(key => {
      mergeStyles[key] = {...mergeStyles[key], ...props.styles[key]};
    })
  }

  const switchStyles = {
    ...mergeStyles.switch,
    ...{
      left: condition ? '32px' : '2px',
    }
  }

  const containerStyles = condition
   ? {...mergeStyles.container, ...mergeStyles.containerOn}
   : mergeStyles.container;

  return(
    <button onClick={toggle} style={containerStyles} type='button'>
      <div style={switchStyles}></div>
      <span style={{...mergeStyles.text, ...{float: 'left'}}}>{text[0]}</span>
      <span style={{...mergeStyles.text, ...{float: 'right'}}}>{text[1]}</span>
    </button>
  );
}


export default ToggleSwitch;
