import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import styles from "./styles";
import Button from "../General/Button";
import Loader from "../General/Loader";
import { SsoContext } from "../hooks/ssoContext";
import ImageDisplay from "../ImageDisplay";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const FormWrapper = (props) => {
  // form wrap dimensions state(prevents blurring from animation)
  // console.log(props, "222222");
  const { state, toggleModal } = React.useContext(SsoContext);
  const [dims, setDims] = useState({ width: "auto", height: "auto" });
  const formStatus = props.status;
  const titleStyles = { ...props.titleStyles };
  const [formMessage, setFormMessage] = useState(null);
  const formMessageLink = props.formMessageLink;
  const isMounted = React.useRef(true);
  React.useEffect(() => () => (isMounted.current = false), []);

  useEffect(() => {
    if (props.message) {
      setFormMessage(props.message);
      setTimeout(() => {
        setFormMessage(null);
      }, 5000);
    }
  }, [props.message]);
  


  // keep login modal even width and height
  useLayoutEffect(() => {
    const element = document.getElementById(props.id);
    let update = { width: dims.width, height: dims.height };
    const width = Math.ceil(element.clientWidth);
    const height = Math.ceil(element.clientHeight);
    if (width % 2 !== 0) update.width = width + 1 + "px";
    else update.width = width;
    if (height % 2 !== 0) update.height = height + 1 + "px";
    else update.height = height;
    if (update.width !== dims.width || update.height !== dims.height)
      setDims(update);
  }, [props.id, dims.width, dims.height]);
  const memo = useRef((props.memo ? props.memo : "") + props.status);
  const memoTest = (props.memo ? props.memo : "") + props.status;
  if (memoTest !== memo.current && props.active && isMounted.current) {
    setDims({ width: "auto", height: "auto" });
    memo.current = memoTest;
  }
  useLayoutEffect(() => setDims({ width: "auto", height: "auto" }), []);
  const appContainer = document.getElementById("application_container");
  if (props.active && appContainer && dims.width !== "auto")
    appContainer.style.height = dims.height + 250 + "px";

  return (
    <>
      {props.backRoute && formStatus !== "fetching" && (
        <Button
          onClick={props.backRoute}
          styles={{ ...styles.btn, float: "none", width: "auto" }}
          hoverStyles={
            state.instanceConfig
              ? {
                  color: state.instanceConfig.colors.main,
                  fill: state.instanceConfig.colors.main,
                }
              : styles.btnHov
          }
        >
          <svg
            style={styles.backSvg}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M216.4 163.7c5.1 5 5.1 13.3.1 18.4L155.8 243h231.3c7.1 0 12.9 5.8 12.9 13s-5.8 13-12.9 13H155.8l60.8 60.9c5 5.1 4.9 13.3-.1 18.4-5.1 5-13.2 5-18.3-.1l-82.4-83c-1.1-1.2-2-2.5-2.7-4.1-.7-1.6-1-3.3-1-5 0-3.4 1.3-6.6 3.7-9.1l82.4-83c4.9-5.2 13.1-5.3 18.2-.3z" />
          </svg>
          <span style={styles.backText}>Back</span>
        </Button>
      )}
     
      <div
        id={props.id}
        style={{
          width: dims.width,
          height: dims.height,
          display: "flex",
          ...styles.formWrap,height:props.from=="xt_console"?"700px":"576px"
        }}
      >
        <div
          style={{
            ...styles.titleWrap,
            ...styles.overlayLeft,
            ...titleStyles,
            width: "50%",
          }}
        >
          <ImageDisplay style={styles.formTitleContent} app={props.title} />
          {/* <h1 style={styles.formTitleContent}>{props.title}</h1> */}
          {props.subheading === "Console" ? (
            <h1 style={{ ...styles.formTitleContent, padding: "0px 140px" }}>
              Welcome
              <br />
              To
              <br />
              {props.subheading}
            </h1>
          ) : (
            <h1 style={styles.formTitleContent}>
              <strong>{props.subheading}</strong>
            </h1>
          )}
          {props.additionalButtons ? (
            <Button
              styles={{ ...styles.wrapperBtn }}
              hoverStyles={{ ...styles.wrapperBtnHov }}
              onClick={props.additionalButtons.onClick}
            >
              {props.additionalButtons.text}
            </Button>
          ) : (
            ""
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              margin: "2rem 0 0 0",
            }}
          >
            <span style={{ fontSize: "12px", marginBottom: "-20px" }}>
              By clicking this button, you agree to our <br />
              <Link
                to="/term-of-use"
                target="_blank"
                style={{
                  color: "white",
                  marginY: "4rem!important",
                  cursor: "pointer",
                  borderBottom: "1px solid white",
                  fontSize: "10px",
                }}
              >
                {" "}
                Terms of Use
              </Link>
              &nbsp;&nbsp;and&nbsp;&nbsp;
              <Link
                to="/privacy-policy"
                target="_blank"
                style={{
                  color: "white",
                  marginY: "1rem!important",
                  cursor: "pointer",
                  borderBottom: "1px solid white",
                  fontSize: "10px",
                }}
              >
                {" "}
                Privacy Policy
              </Link>
            </span>
          </div>
           
        </div>
        <div style={{ ...styles.overlayRight, width: "50%" }}>
          <div style={styles.formContent}>
            {props.subTitle &&
              formStatus !== "fetching" &&
              formStatus !== "complete" && (
                <p style={styles.subTitle}>{props.subTitle}</p>
              )}
              
            {(formStatus === "error" || formStatus === "complete") &&
           
              formMessage && (
                <div style={styles[formStatus + "Container"]}>
                  <p
                    style={
                      formMessageLink
                        ? { ...styles.message, ...styles.messageLeft }
                        : styles.message
                    }
                  >
                    {formMessage}
                  </p>
                  {formMessageLink && (
                    <Button
                      onClick={formMessageLink.to}
                      styles={styles[formStatus + "MessageLink"]}
                    >
                      {formMessageLink.text}
                    </Button>
                  )}
                </div>
              )}
            {formStatus !== "fetching" &&
              formStatus !== "complete" &&
              props.children}
            {formStatus === "fetching" && (
              <div style={styles.loaderWrap}>
                <Loader width="145px" height="145px" noBg />
              </div>
            )}
          </div>
          <div style={styles.linksContainer}>
            {props.btns &&
              formStatus !== "fetching" &&
              props.btns.map((settings, i) => (
                <Button
                  key={i}
                  onClick={settings.onClick}
                  styles={{
                    ...styles.btn,
                    ...settings.styles,
                    ...settings.style,
                    cursor: settings.disabled ? "not-allowed" : "pointer",
                  }}
                  hoverStyles={
                    state.instanceConfig
                      ? {
                          color: state.instanceConfig.colors.main,
                        }
                      : styles.btnHov
                  }
                >
                  {settings.text}
                </Button>
              ))}
          </div>
        </div>
      
      </div>
    </>
  );
};

export default FormWrapper;
