// import React, {useState, useRef} from 'react';
// import fetchController from '../General/fetchController';
// import VerificationForm from "./Verification";

// function SignInForm({ onForgotPassword }) {
//   const [state, setState] = React.useState({
//     email: "",
//     password: ""
//   });
//   const [type, setType] = React.useState("signIn");

//   const handleChange = evt => {
//     const value = evt.target.value;
//     setState({
//       ...state,
//       [evt.target.name]: value
//     });
//   };
//   const fetcher = useRef(fetchController({
//     url: '/backend/rt/user/login',
//     method: 'post',
//     endpoint: '',
//     body: {},
//   }))

//   const handleOnSubmit = evt => {
//     evt.preventDefault();
//     const { email, password } = state;
//     // alert(`You are login with email: ${email} and password: ${password}`);
//     fetcher.current.body = {
//       email: email,
//       // instance: state.urlQueries.instance || props.config.id,
//       instance: "XD",
//       password: password,
//       type: "xc_console",
//       // type: props.from,
//     }
//     // setType("verification");
//   };

//   if (type === "verification") {
//     return <VerificationForm />;
//   }

//   return (
//     <div className="form-container sign-in-container">
//       <form className="form" onSubmit={handleOnSubmit}>
//         {/* <h1>Sign in</h1> */}
//         <h1>Please share your credentials to log in</h1>
//         {/* <span style={{marginTop:"1rem"}}>use your account</span> */}
//         <input
//           type="email"
//           placeholder="Email"
//           name="email"
//           value={state.email}
//           onChange={handleChange}
//           autocomplete="off" 
//         />
//         <input
//           type="password"
//           name="password"
//           placeholder="Password"
//           value={state.password}
//           onChange={handleChange}
//           autocomplete="off" 
//         />
//         <a href="#" onClick={onForgotPassword}>Forgot your password?</a>
//         <button type="submit">Sign In</button>
//       </form>
//     </div>
//   );
// }

// export default SignInForm;

import React, { useState } from 'react';
import VerificationForm from "./Verification";
import axios from 'axios';

function SignInForm({ onForgotPassword }) {
  const [state, setState] = useState({
    email: "",
    password: ""
  });
  const [pageType, setPageType] = useState("signIn");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = evt => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  };

  const handleOnSubmit = async evt => {
    evt.preventDefault();
    setLoading(true);
    const { email, password } = state;

    const payload = {
      email: email,
      instance: "XD",
      password: password,
      type: "xc_console",
    };

    console.log('Request Payload:', payload);  

    try {
      const axiosResponse = await axios.post('http://localhost:4002/backend/rt/user/login', payload, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      console.log(axiosResponse, 'Full Response');  // Log the full response object

      if (axiosResponse.status === 200) {
        console.log(axiosResponse.data, 'Response Data');
        if (axiosResponse.data.message === "Please re-confirm email address. Check your email for confirmation link.") {
          setPageType("verification");
        } else {
          setError("An unexpected error occurred. Please try again.");
        }
      } else {
        setError("An error occurred. Please try again.");
      }
    } catch (err) {
      if (err.response) {
        console.error('Error Response:', err.response.data);  // Log the response data from the error
        setError(err.response.data.message || "An error occurred. Please try again.");
setPageType(err.response.data.field)
       
      } else {
        console.error('Error:', err.message);  // Log the error message
        setError("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };
  if (pageType === "twoFactor") {
    return <VerificationForm state={state} />;
  }
  

  return (
    <div className="form-container sign-in-container">
      <form className="form" onSubmit={handleOnSubmit}>
        <h1>Please share your credentials to log in</h1>
        <input
          type="email"
          placeholder="Email"
          name="email"
          value={state.email}
          onChange={handleChange}
          autoComplete="off" 
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={state.password}
          onChange={handleChange}
          autoComplete="off" 
        />
        {error && <p className="error">{error}</p>}
        <a href="#" onClick={onForgotPassword}>Forgot your password?</a>
        <button type="submit" disabled={loading}>
          {loading ? "Signing In..." : "Sign In"}
        </button>
      </form>
    </div>
  );
}

export default SignInForm;
