import {absCenter} from '@xpertdox_js/xdstyle';

export default {
  container: {
    position: 'relative',
    minHeight: '100vh',
    width: '100%'
  },
  content: {...absCenter},
  message: {
    fontSize: '32px'
  }
}
