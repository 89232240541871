export default {
  container: {
    // width: 'auto',
    //height:'447px',
    
    // paddingLeft:'88px',
    // paddingRight:'88px',
    // paddingBottom:88,
    // width:"768px",
    // minHeight:'480px'
  },
  form: {
  },
  title: {
    margin: '12px 0'
  },
  errorWrap: {
    // padding: '4px 4px',
    // background: '#e62712',
    // border: '1px solid #e62712',
    // borderRadius: '2px',
    position: 'absolute',
    top: '100%',
    left: '0',
    marginTop: '-5px',
    width: '100%',
    fontSize: '8px',
  },
  error: {
    color: '#e62712',
    margin: '2px',
    fontSize: '1.2rem',
    fontWeight: 'normal'
  },
  warning: {
    color: '#666',
  },
  label: {
    width: '100%',
    display: 'inline-block',
    padding: '2px',
    marginTop:0,
    background: 'none',

  },
  
  errorLabel: {
    // background: '#fce9e7',
    // borderTop: '1px solid #e62712',
    // borderRight: '2px solid #e62712',
  },
  inlineLabel: {
    width: 'auto',
    display: 'flex',
    // marginLeft: '5px',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 2
  },
  labelText: {
    // display: 'block',
    //marginBottom: '20px',
    fontSize: '12px',
  //  marginTop:'10px',
  },
  titleLabel: {
    paddingBottom: '5px'
  },
  lineBreakLabel: {
  marginTop:30,
  marginBottom:30,
   
  },
  
  inlineLabelText: {
    // display: 'inline-block',
    verticalAlign: 'top',
    marginTop: '9px',
    fontSize: '1.2rem',
    fontWeight: 'normal'

  },
  submit: {
  //  width: '347px',
 padding:"1.5rem 5rem",
 fontSize:"14px",
  //  marginTop:'10px ',
//  height:'50px',
 //maxWidth:'100%',

  borderRadius:'30px',
   
    
  },

  

  register: {
    // width: '100% !imporant',
   // margin:'25px auto',
  // height:'58px',
 // width:'100%',
    // width: 'auto',
   borderRadius:'10px',
     background: '#7D8891',
   },
  submitValid: {
    background: '#445461',
    color: '#fff',
  },
  submitHov: {
    background: '#2C81D2'
  },
  submitFoc: {
    background: '#7D8891'
  },
  dynamicSelect: {
    container: {
      boxShadow: 'none',
      borderRadius: '2px',
      border: '1px solid #d2d4d8',
    },
    display: {
      height: '34px',
      borderTopRightRadius: '2px',
      borderBottomRightRadius: '2px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      minWidth: '75%',
      width: '75%',
    },
    displayText: {
      color: '#3c3e48',
    },
    button: {
      // borderRight: '1px solid rgba(0,0,0,0.1)',
      // background: '#fafdfe',
      // height: '34px',
      // borderTopLeftRadius: '2px',
      // borderBottomLeftRadius: '2px',
      // minWidth: '25%',
      // width: '25%'
    },
    buttonHov: {
      background: '#f0f6f9',
    },
    menuItem: {
      background: '#fafdfe',
      boxShadow: '0 1px 8px rgba(0,0,0,0.1)',
      borderBottom: '1px solid #d2d4d8',
      color: '#3c3e48'
    },
    menuItemHov: {
      color: '#fff'
    },
    selectMenu: {
      background: '#fafdfe',
      border: '1px solid rgba(0,0,0,0.18)',
    }
  },
  checkCircle: {
    container: {
      margin: '-9px 0',
      display: 'inline-block'
    },
  },
  optionLabel: {
    marginBottom: 0,
    fontWeight: 'normal',
  }
}
