import React, { useState } from "react";

function SignUpForm() {
  const [stage, setStage] = useState(1); // State to manage the stage of the form

  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone_number: "",
    department: "",
    affiliation: "",
    title: "",
  });

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleNext = () => {
    setStage(stage + 1);
  };

  const handlePrevious = () => {
    setStage(stage - 1);
  };

  const handleOnSubmit = (evt) => {
    evt.preventDefault();

    const {
      firstname,
      lastname,
      email,
      password,
      confirm_password,
      phone_number,
      department,
      affiliation,
      title,
    } = state;

    // Perform your form submission logic here
    alert(`
      Name: ${firstname} ${lastname}
      Email: ${email}
      Password: ${password}
      Phone ${phone_number}
      depart: ${department}
    affiliation: ${affiliation}
     title: ${title}
    `);

    // Reset the form state after submission
    setState({
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      confirm_password: "",
      phone_number: "",
      department: "",
      affiliation: "",
      title: "",
    });

    // Optionally, you can also reset the stage to the initial stage
    setStage(1);
  };

  return (
    <div className="form-container sign-up-container">
      <form className="form" onSubmit={handleOnSubmit}>
        <h1>Create Account</h1>
        <span>Use your email for registration</span>
        {stage === 1 && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <input
                style={{ width: "48%" }}
                type="text"
                name="firstname"
                value={state.firstname}
                onChange={handleChange}
                autocomplete="off" 
                placeholder="First Name"
              />
              <input
                style={{ width: "48%" }}
                type="text"
                name="lastname"
                value={state.lastname}
                onChange={handleChange}
                autocomplete="off" 
                placeholder="Last Name"
              />
            </div>
            <input
              type="email"
              name="email"
              value={state.email}
              onChange={handleChange}
              autocomplete="off" 
              placeholder="Email"
            />
            <input
              type="text"
              name="phone_number"
              value={state.phone_number}
              onChange={handleChange}
              autocomplete="off" 
              placeholder="Phone Number"
            />
            <input
              type="text"
              name="department"
              value={state.department}
              onChange={handleChange}
              autocomplete="off" 
              placeholder="Division / Department"
            />
          </>
        )}
        {stage === 2 && (
          <div>
            <input
              type="text"
              name="affiliation"
              value={state.affiliation}
              onChange={handleChange}
              autocomplete="off" 
              placeholder="Affiliation"
            />
            <input
              type="text"
              name="title"
              value={state.title}
              onChange={handleChange}
              autocomplete="off" 
              placeholder="Title"
            />
            <input
              type="password"
              name="password"
              value={state.password}
              onChange={handleChange}
              autocomplete="off" 
              placeholder="Password"
            />
            <input
              type="password"
              name="confirm_password"
              value={state.confirm_password}
              onChange={handleChange}
              autocomplete="off" 
              placeholder="Confirm password"
            />
          </div>
        )}

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: stage == 1 ? "flex-end" : "space-between",
            margin: "1rem 0rem",
          }}
        >
          {stage == 2 && (
            <button
              className="progressbtn"
              type="button"
              onClick={handlePrevious}
            >
              Previous
            </button>
          )}
          {stage == 1 && (
            // <div id="nextbtn" style={{display:"flex", width:"100%", }}>
            <button className="" type="button" onClick={handleNext}>
              Next
            </button>
            // </div>
          )}
          {stage == 2 && <button type="submit">Submit</button>}
        </div>
      </form>
    </div>
  );
}

export default SignUpForm;
