import React from 'react'
import privacyPolicyContent from '../../content/privacyPolicyContent'
import "../TermOfUse/style.css";
import xdLogo from '../../assets/xd-logo.png';
import WaterMarkBg from '../WaterMarkBg';
const PrivacyPolicy = () => {
  return (
    <div>
        <WaterMarkBg/>
    <div style={{padding:"2rem 10rem", maxWidth:"1440px" ,margin :"auto" , background:"white"}}>
    <img src={xdLogo} alt="" srcset="" style={{margin:"4rem 0rem 0rem 0rem" }} />
    <h4  style={{textAlign:"center", marginBottom:"30px" ,fontSize:"32px"}}>Privacy Policy</h4>
                     <p id="terms_text">{privacyPolicyContent.intro}</p>
                     <p id="terms_text_margin">{privacyPolicyContent.intro_part1}</p>
                     <ul id="terms_list_bullet">
                         <li id="terms_list_item"><p id="terms_text">You represent, warrant, and covenant that You are at least 18 years old.</p></li>
                         <li id="terms_list_item"><p id="terms_text">You represent, warrant, and covenant that You are authorized to engage in the activities that You conduct within the Site on behalf of that person or entity, including the submission and receipt of all Information on their behalf.</p></li>
                         <li id="terms_list_item"><p id="terms_text">You agree that We can rely on Your representation that You are authorized to engage in the activities that You conduct within the Site on behalf of that person or entity.</p></li>
                         <li id="terms_list_item"><p id="terms_text">You understand that “You,” “Your,” and “Yourself” in this Privacy Policy refer to both the person or entity and You acting on their behalf.</p></li>
                     </ul>
                     <p id="terms_text_margin">{privacyPolicyContent.intro_part2}</p>
                     <p id="terms_text_margin">{privacyPolicyContent.intro_part3}</p>
                     <p id="terms_text_margin">{privacyPolicyContent.intro_part4}</p>
                     <p id="terms_title">SUMMARY OF KEY POINTS</p>
                     <p id="terms_text_margin">{privacyPolicyContent.key_points}</p>
                     <ul id="terms_list_bullet">
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>What personal information do we process?</span>When You visit, use, or navigate our Site, we may process personal information depending on how You interact with us and the Site, the choices You make, and the products and features You use.</p></li>
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>Do we process any sensitive personal information?</span>We do not process sensitive personal information.</p></li>
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>How do we process Your information?</span>We process Your information to provide, improve, and administer our Site, communicate with You, for security and fraud prevention, and to comply with the law. </p></li>
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>In what situations and with which parties do we share personal information?</span>We may share information in specific situations and with specific third parties.</p></li>
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>How do we keep Your information safe?</span>We have organizational and technical processes and procedures in place to protect Your personal information.</p></li>
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>What are Your rights?</span> Depending on where You are located geographically, the applicable privacy law may mean You have certain rights regarding Your personal information.</p></li>
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>How do You exercise Your rights?</span>The easiest way to exercise Your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p></li>
                     </ul>
                     <p id="terms_title">TABLE OF CONTENTS</p>
                     <ul id="terms_list">
                       <li id="terms_list_item"><p id="terms_text">1.  WHAT INFORMATION DO WE COLLECT?</p></li>
                       <li id="terms_list_item"><p id="terms_text">2.  HOW DO WE PROCESS YOUR INFORMATION?</p></li>
                       <li id="terms_list_item"><p id="terms_text">3.  WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p></li>
                       <li id="terms_list_item"><p id="terms_text">4.  DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p></li>
                       <li id="terms_list_item"><p id="terms_text">5.  HOW LONG DO WE KEEP YOUR INFORMATION?</p></li>
                       <li id="terms_list_item"><p id="terms_text">6.  HOW DO WE KEEP YOUR INFORMATION SAFE?</p></li>
                       <li id="terms_list_item"><p id="terms_text">7.  DO WE COLLECT INFORMATION FROM MINORS?</p></li>
                       <li id="terms_list_item"><p id="terms_text">8.  WHAT ARE YOUR PRIVACY RIGHTS?</p></li>
                       <li id="terms_list_item"><p id="terms_text">9.  CONTROLS FOR DO-NOT-TRACK FEATURES</p></li>
                       <li id="terms_list_item"><p id="terms_text">10.  LINKS TO OTHER WEBSITES</p></li>
                       <li id="terms_list_item"><p id="terms_text">11.  PROTECTED HEALTH INFORMATION</p></li>
                       <li id="terms_list_item"><p id="terms_text">12.  DO WE MAKE UPDATES TO THIS POLICY?</p></li>
                       <li id="terms_list_item"><p id="terms_text">13.  HOW CAN YOU CONTACT US ABOUT THIS POLICY?</p></li>
                       <li id="terms_list_item"><p id="terms_text">14.  HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</p></li>
                    </ul>
                    <p id="terms_title">1. WHAT INFORMATION DO WE COLLECT?</p>
                    <p id="terms_text">{privacyPolicyContent.information_we_collect}</p>
                    <p id="terms_text_margin">The information we collect includes:</p>
                    <ul id="terms_list_bullet">
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>Log and Usage Data:</span>{privacyPolicyContent.log_usage_data}</p></li>
                    </ul>
                    <p id="terms_title">2. HOW DO WE PROCESS YOUR INFORMATION?</p>
                    <p id="terms_text">{privacyPolicyContent.process_info}</p>
                    <ul id="terms_list_bullet">
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>To evaluate and improve our Site, products, security and Your experience:</span>We may process Your information when we believe it is necessary to identify usage trends, determine the effectiveness of our security measures, and to evaluate and improve our Site, product, and Your experience.</p></li>
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>To identify usage trends:</span>We may process information about how You use our Site to better understand how they are being used so we can improve them.</p></li>
                    </ul>
                    <p id="terms_title">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p>
                    <p id="terms_text">We do not share Your information with third parties except as listed below:</p>
                    <ul id="terms_list_bullet">
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>Content Optimization:</span>Google Fonts</p></li>
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>Data Backup and Security</span>Dropbox and Amazon</p></li>
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>IP Tracking</span>IPinfo</p></li>
                    </ul>
                    <p id="terms_text_margin">We may also need to share Your personal information in the following situations:</p>
                    <ul id="terms_list_bullet">
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>Business Transfers:</span>We may share or transfer Your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p></li>
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>Law Enforcement</span>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</p></li>
                         <li id="terms_list_item"><p id="terms_text"><span className='terms_text_bold'>Other Legal Requirements:</span>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p></li>
                    </ul>
                    <ul id="terms_list_empty_bullets">
                         <li id="terms_list_item"><p id="terms_text">Comply with a legal obligation.</p></li>
                         <li id="terms_list_item"><p id="terms_text">Protect and defend the rights or property of the Company.</p></li>
                         <li id="terms_list_item"><p id="terms_text">Prevent or investigate possible wrongdoing in connection with the Site.</p></li>
                         <li id="terms_list_item"><p id="terms_text">Protect the personal safety of Users of the Site or the public.</p></li>
                         <li id="terms_list_item"><p id="terms_text">Protect against legal liability.</p></li>
                    </ul>
                    <p id="terms_title">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
                    <p id="terms_text">{privacyPolicyContent.cookies}</p>
                    <p id="terms_title">5. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
                    <p id="terms_text">{privacyPolicyContent.how_long_info}</p>
                    <p id="terms_title">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
                    <p id="terms_text">{privacyPolicyContent.info_safety}</p>
                    <p id="terms_title">7. DO WE COLLECT INFORMATION FROM MINORS?</p>
                    <p id="terms_text">{privacyPolicyContent.info_from_minors}</p>
                    <p id="terms_title">8. WHAT ARE YOUR PRIVACY RIGHTS?</p>
                    <p id="terms_text_margin" className="terms_text_bold">Your rights with respect to Your personal data:</p>
                    <ul id="terms_list_bullet">
                         <li id="terms_list_item"><p id="terms_text">Right to be informed whether or not we are processing Your personal data.</p></li>
                         <li id="terms_list_item"><p id="terms_text">Right to access Your personal data.</p></li>
                         <li id="terms_list_item"><p id="terms_text">Right to correct inaccuracies in Your personal data.</p></li>
                         <li id="terms_list_item"><p id="terms_text">Right to request deletion of Your personal data.</p></li>
                         <li id="terms_list_item"><p id="terms_text">Right to obtain a copy of the personal data You previously shared with us.</p></li>
                    </ul>
                    <p id="terms_text_margin"><span className='terms_text_bold'>Withdrawing Your consent:</span>{privacyPolicyContent.privacy_right_consent}</p>
                    <p id="terms_text_margin"><span className='terms_text_bold'>Cookies and similar technologies:</span>{privacyPolicyContent.privacy_right_cookies}</p>
                    <p id="terms_text_margin"><span className='terms_text_bold'>Verification Process:</span>{privacyPolicyContent.verification_process}</p>
                    <p id="terms_text_margin"><span className='terms_text_bold'>Right to appeal:</span>{privacyPolicyContent.right_to_appeal}</p>
                    <p id="terms_title">9. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
                    <p id="terms_text">{privacyPolicyContent.control_features}</p>
                    <p id="terms_title">10. LINKS TO OTHER WEBSITES</p>
                    <p id="terms_text">{privacyPolicyContent.links_other_websites}</p>
                    <p id="terms_title">11. PROTECTED HEALTH INFORMATION</p>
                    <p id="terms_text">{privacyPolicyContent.protected_health_info}</p>
                    <p id="terms_text_margin">{privacyPolicyContent.protected_health_info_part2}</p>
                    <p id="terms_title">12. DO WE MAKE UPDATES TO THIS POLICY?</p>
                    <p id="terms_text">{privacyPolicyContent.updates_to_policy}</p>
                    <p id="terms_title">13. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</p>
                    <p id="terms_text">If You have any questions about this Privacy Policy, You can contact us:</p>
                    <ul id="terms_list_bullet">
                         <li id="terms_list_item"><p id="terms_text">By email: support@xpertdox.com</p></li>
                         <li id="terms_list_item"><p id="terms_text">By visiting this page on our website: Contact</p></li>
                         <li id="terms_list_item"><p id="terms_text">By phone number: 205.259.6045</p></li>
                         <li id="terms_list_item"><p id="terms_text">By mail: 8687 East Via de Ventura, Suite 302, Scottsdale, AZ 85258</p></li>
                    </ul>
                    <p id="terms_title">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</p>
                    <p id="terms_text">{privacyPolicyContent.review_update_delete}</p>
          </div>
    </div>
  )
}
export default PrivacyPolicy