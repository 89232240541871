import React, {useEffect} from 'react';

const ResponsiveContext = React.createContext();

const calcScreenSize = width => {

  const size = width < 768 ? 'mobile'
  : window.innerWidth < 1024 ? 'tablet'
  : window.innerWidth < 3000 ? 'desktop'
  : 'xlScreen';
  const columns = [
    ['oneHalf', 100, 50],
    ['oneThird', 100, 33.3333],
    ['oneThirdFlex', 50, 33.3333],
    ['twoThirds', 100, 66.6666],
    ['oneFourth', 100, 25],
    ['oneFourthFlex', 50, 25],
    ['threeFourths', 100, 75],
    ['oneFifth', 100, 20],
    ['oneFifthFlex', 33.3333, 20],
    ['twoFifths', 100, 40],
    ['threeFifths', 100, 60],
    ['fourFifths', 100, 80]
  ]
  let retCols = {};
  columns.forEach(col => retCols[col[0]] = {
    width: (size === 'mobile' ? col[1] : col[2]) + '%',
    float: 'left',
    padding: '0 10px',
  })

  return {
    size: size,
    columns: retCols
  };
}


const reducer = (state, action) => calcScreenSize(action.width);

const ResponsiveContextProvider = props => {

  const [state, dispatch] = React.useReducer(reducer, {
    size: calcScreenSize(window.innerWidth).size,
    columns: calcScreenSize(window.innerWidth).columns
  });
  const resizeFunc = () => dispatch({width: window.innerWidth});

  // mount/unmount resize listener
  useEffect(() => {
    window.addEventListener('resize', resizeFunc)
    return(() => window.removeEventListener('resize', resizeFunc))
  }, [])

  return(
    <ResponsiveContext.Provider value={{responsiveSize: state.size, columns: state.columns}}>
      {props.children}
    </ResponsiveContext.Provider>
  )
}

const ResponsiveContextConsumer = ResponsiveContext.Consumer;
export {ResponsiveContext, ResponsiveContextProvider, ResponsiveContextConsumer};
