import colors from '../../colors';
import {absCenter, absFill} from '../../globalStyles';

export default {
  container: {
    position: 'relative',
    display: 'inline-block',
    width: '225px',
    height: '260px',
    background: '#fff',
    // border: '1px solid rgba(0,0,0,0.05)',
    boxShadow: '0 3px 8px -3px rgba(0,0,0,0.72)',
    borderRadius: '3px',
    padding: '4px',
    marginRight: '10px',
    verticalAlign: 'top',
  },
  button: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    left: 'auto',
    borderRadius: '50px',
    width: '28px',
    height: '28px',
    background: colors.xdDarkBlue,
    fill: '#fff',
    padding: 0,
    opacity: .75,
  },
  buttonHov: {
    opacity: 1,
    background: colors.xdHighlight,
  },
  svg: {
    ...absCenter,
    display: 'block',
    width: '22px',
    height: '22px'
  },
  overlay: {
    ...absFill,
    background: 'rgba(255,255,255,0.6)',
  },
  errorContainer: {
    padding: '10px',
    color: colors.error,
    fontSize: '1.25rem',
    background: '#f2f2f2',
    border: '1px solid ' + colors.error,
  },
  deletedContainer: {
    padding: '10px',
    fontSize: '1.25rem',
    color: colors.success,
    background: colors.lightGreen,
    border: '1px solid ' + colors.success,
  },
  imageNameContainer: {
    position: 'absolute',
    top: 'auto',
    paddingBottom: '3px',
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    fontSize: '1.2rem',
    textAlign: 'center',
    color: '#fff',
    background: colors.xdDarkBlue,
    opacity: .75,
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    whiteSpace: 'nowrap',
  },
  imageDisplay: {
    container: {
      ...absCenter,
      maxWidth: '90%'
    }
  }
}
