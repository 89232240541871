import React from 'react';
// import fetchController from '../General/fetchController';
import urlQueryParser from '../Parsers/urlQueryParser';
// import {UserContext} from './SsoUserContext';

const SessionTest = props => {
  // const {user} = React.useContext(UserContext);
  const queries = urlQueryParser();
  console.log(queries)
  // const fetcher = useRef(fetchController({
  //   url: '/backend/rt/user/activateSession/',
  //   endpoint: queries.session,
  //   method: 'get',
  // }))

  // if (fetcher.current.status !== 'complete' && fetcher.current.status !== 'error')  {
  //   fetcher.current.fetch()
  //   .then(res => console.log(res))
  //   .catch(err => console.error(err))
  // }

  return(
    <div>Session Test</div>
  )

}

export default SessionTest;
