import colors from '../colors';

export default {
  container: {
    marginBottom: '20px',
    position: 'relative',
  },
  svg: {
    width: '16px',
    marginRight: '10px'
  },
  terms: {
    maxHeight: '500px',
    overflowY: 'scroll',
    width: '100%',
    marginTop: '1px',
    padding: '5px 10px',
    backgroundColor: '#fff',
    position: 'absolute',
    top: '100%',
    zIndex: 100,
    padding: '8px 5px',
    boxShadow: '0 1px 14px rgba(0,0,0,0.2)'
  },
  termsContent: {
    boxShadow: 'inset .5px .5px 4px .5px rgba(0,0,0,0.17)',
    background: '#fcfcfc',
  },
  term: {
    margin: '5px 2px',
    cursor: 'pointer',
    fontSize: '1.4rem',
    padding: '4px',
    textAlign: 'left',
    alignItems: 'center'
  }
}
