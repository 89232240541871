const privacyPolicyContent = {
    intro:`The Business Intelligence Platform (this “Site”) is provided by XpertDox, LLC (“XpertDox,” “we,” or “us”), allowing clients or their representatives (“You,” “Client,” “Your,” and “Yourself”) to monitor medical coding data generated by the XpertCoding platform. XpertCoding is an AI-powered medical coding solution that uses advanced algorithms, natural language processing (NLP), and machine learning to automate the coding of medical claims, aiming for higher accuracy and faster claims submissions.`,
    intro_part1:`This privacy policy for XpertDox describes how and why we might collect, store, use, and/or share ("process") Your information when You access, use, or browse this Site. When using the Site on behalf of an individual other than Yourself, which may be either a natural person or an entity:`,
    intro_part2:`This Site is controlled and operated by XpertDox from its offices within the United States. Accordingly, this Privacy Policy, and how XpertDox handles the information You voluntarily provide when You use the Site and information that We may automatically collect when You visit the Site, is governed by United States federal and state laws, and not by the laws of any other country, territory, or jurisdiction. We make no representations that the Site is appropriate or available for use outside the United States. Access to the Site from territories where its contents or purpose are illegal is prohibited. Those who choose to access the Site are responsible for compliance with applicable local laws and regulations. We are currently focused on the US and Canadian markets and have not yet implemented the EU’s General Data Protection Regulations. Your use of this Site will be deemed to constitute Your acceptance of and agreement to this Privacy Policy. If You do not agree to these Terms, You should not use any portion of the Site.`,
    intro_part3:`XpertDox reserves the right, at its sole discretion, to change this Privacy Policy at any time. If We change the Privacy Policy, We will notify You when You visit the Site so that You are aware of the changes. Your continued use of the Site after the effective date of any changes to the Privacy Policy will be deemed to constitute Your acceptance of and agreement to the changed Privacy Policy. Consequently, You should frequently review this Privacy Policy.`,
    intro_part4:`Questions or concerns? Reading this privacy policy will help You understand Your privacy rights and choices. If You do not agree with our policies and practices, please do not use our Site. If You still have any questions or concerns, please contact us at support@xpertdox.com.`,
    key_points:`This summary provides key points from our privacy policy.`,
    information_we_collect:`We automatically collect certain information when You visit, use, or navigate the Site. This information is primarily needed to maintain the security and operation of our Site and for our internal analytics and reporting purposes. Like many businesses, we also collect information through cookies and similar technologies. You can find out more about this in our Cookie Policy.`,
    log_usage_data:`Log and usage data is Site-related, diagnostic, usage, and performance information our servers automatically collect when You access or use our Site and which we record in log files. Depending on how You interact with us, this log data may include Your IP address, device information, browser type and settings, your location-city, country, zip code, timezone, approximate longitude and latitude coordinates, and information about Your activity in the Site (such as the date/time stamps associated with Your usage, pages and files viewed, searches, and other actions You take such as which features You use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).`,
    process_info:`We process Your information to provide, improve, and administer our Site, communicate with You, for security and fraud prevention, and to comply with the law. We may also process Your information for other purposes with Your consent.`,
    cookies:`We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Our Cookie Policy sets out specific information about how we use such technologies and how you can refuse certain cookies.`,
    how_long_info:`We will only keep Your personal information for the Term. We will also retain Deidentified Usage Data for internal analysis purposes. Usage Data may be used to strengthen the security or improve the functionality of Our Site, or We are legally obligated to retain this data for longer periods.
    When we have no ongoing legitimate business need to process Your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because Your personal information has been stored in backup archives), then we will securely store Your personal information and isolate it from any further processing until deletion is possible. No purpose in this policy will require us to keep Your personal information for longer than 180 days.`,
    info_safety:`We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. Please refer to the Security section of our Terms of Use for more details.`,
    info_from_minors:`We prohibit the use of the Site by children under 18 years of age.`,
    privacy_right_consent:`If we rely on Your consent to process Your personal information, which may be express and/or implied consent depending on the applicable law, You have the right to withdraw Your consent at any time. You can withdraw Your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS POLICY?" below. However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of Your personal information conducted in reliance on lawful processing grounds other than consent.`,
    privacy_right_cookies:`Most Web browsers are set to accept cookies by default. If You prefer, You can usually choose to set Your browser to remove cookies and to reject cookies. If You choose to remove cookies or reject cookies, this could affect certain features or Site of our Site. For further information, please see our Cookie Policy.
    If You are using an authorized agent to exercise Your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on Your behalf.`,
    verification_process:`We may request that you provide additional information reasonably necessary to verify your request. If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request. Upon receiving Your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform You of any such extension within the initial 45-day response period, together with the reason for the extension.`,
    right_to_appeal:`If we decline to act regarding Your request, we will inform You of our decision and reasoning behind it. If You wish to appeal our decision, please email us at support@xpertdox.com. Within sixty (60) days of receipt of an appeal, we will inform You in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If You have questions or comments about Your privacy rights, You may email us at support@xpertdox.com.`,
    control_features:`Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting You can activate to signal Your privacy preference not to have data about Your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized at this stage. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates Your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform You about that practice in a revised version of this privacy policy.`,
    links_other_websites:`Our Site may contain links to other websites not operated by us. If You click on a third-party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.`,
    protected_health_info:`Certain site services involve access to and processing of Protected Health Information (PHI). This PHI is provided to us pursuant to terms of use agreement or service agreement, and any other agreements that we have entered with our customers (health care providers or their firms, "Providers"), which also governs our use of PHI of their patients provided by our Provider customers or clients through their EHRs/EMRs or any other relevant sources.
    XpertDox only uses such PHI in accordance with any instructions or restrictions provided to XpertDox by the Provider and Provider’s sources, and in full compliance with the applicable provisions of HIPAA.`,
    protected_health_info_part2:`This Site has been designed to provide exclusive access to “Providers”, You, as a Provider or client, are responsible for safeguarding PHI and carefully protecting patients' health information while accessing the site and later, as per HIPAA provisions and guidelines.`,
    updates_to_policy:`We may update this privacy policy from time to time. The updated version will be indicated by an updated "Revised" date and will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to stay informed of how we are protecting your information.`,
    review_update_delete:`You have the right to request access to the personal information we collect from You, change it, or delete it. To request to review, update, or delete Your personal information, please email us at support@xpertdox.com.`,
}
export default privacyPolicyContent;