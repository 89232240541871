import {absCenter} from './globalStyles';
import colors from './colors';

// ====================================================== Global Settings
// ------ content spacing
export const p_items = {xs:'.2rem', sm:'.4rem', md:'1rem', lg:'1.6rem', xl: '2rem'}; // btn, li, etc. padding.
export const p_content = {xs:'.4rem', sm:'.8rem', md:'1.2rem', lg:'1.8rem', xl: '2.4rem'}; // containers padding
// ====================================================== Fonts
// ------- sizes
export const font_s_xs = {fontSize: '1rem'};
export const font_s_sm = {fontSize: '1.16rem'};
export const font_s_md = {fontSize: '1.36rem'};
export const font_s_lg = {fontSize: '1.68rem'};
export const font_s_xl = {fontSize: '2.36rem'};
export const font_s_xxl = {fontSize: '3.2rem'};
// ------- families
export const font_f_title = {fontFamily: '"Nunito Sans", sans-serif'};
export const font_f_content = {fontFamily: '"Roboto", sans-serif'};
// ====================================================== Borders
export const border_light = {border: '1px solid rgba(0,0,0,0.1)'}
export const border_medium = {border: '1px solid rgba(0,0,0,0.2)'}
export const border_dark = {border: '1px solid rgba(0,0,0,0.36)'}
// ====================================================== Shadows
export const shadow_box_xs = {boxShadow: '0 .2rem .2rem rgba(0,0,0,0.1)'};
export const shadow_box_sm = {boxShadow: '0 .4rem .3rem rgba(0,0,0,0.12)'};
export const shadow_box_md = {boxShadow: '0 .8rem .6rem rgba(0,0,0,0.14)'};
export const shadow_box_lg = {boxShadow: '0 .12rem .14rem rgba(0,0,0,0.16)'};
export const shadow_box_xl = {boxShadow: '0 .16rem .24rem rgba(0,0,0,0.18)'};

// ====================================================== SVGs
export const svg_base = {display: 'block'}
export const svg_circ = {...svg_base, ...absCenter}
export const svg_inline = {...svg_base, float: 'left', marginRight: '5px'}
// ------- sizes
export const svg_s_xs = {width: '1.4rem', height: '1.4rem'};
export const svg_s_sm = {width: '2rem', height: '2rem'};
export const svg_s_md = {width: '2.6rem', height: '2.6rem'};
export const svg_s_lg = {width: '3.6rem', height: '3.6rem'};
export const svg_s_xl = {width: '4.8rem', height: '4.8rem'};

export const svg_xs = {...svg_base, ...svg_s_xs};
export const svg_sm = {...svg_base, ...svg_s_sm};
export const svg_md = {...svg_base, ...svg_s_md};
export const svg_lg = {...svg_base, ...svg_s_lg};
export const svg_xl = {...svg_base, ...svg_s_xl};

// ====================================================== Buttons
// ------- base
export const btn_icon_base = {position: 'relative'};
export const btn_block = {display: 'block', width: '100%'};
export const btn_li_base = {textAlign: 'left'};
export const btn_base = {border: 0}
// ------- padding
export const btn_p_xs = {padding: '.2rem .4rem'}
export const btn_p_sm = {padding: '.4rem .9rem'}
export const btn_p_md = {padding: '.8rem 1.4rem'}
export const btn_p_lg = {padding: '1rem 1.6rem'}
export const btn_p_xl = {padding: '1.2rem 2rem'}
// ------- dimensions(absolute circle buttons - for icons)
export const btn_circ_xs = {width: '2.0rem', height: '2.0rem'}
export const btn_circ_sm = {width: '3.2rem', height: '3.2rem'}
export const btn_circ_md = {width: '4.8rem', height: '4.8rem'}
export const btn_circ_lg = {width: '6.2rem', height: '6.2rem'}
export const btn_circ_xl = {width: '7.5rem', height: '7.5rem'}
// ------- colors and state
/* -- properties:
  base: base styles
  hover: hover styles
  active: active styles
  focus: focus styles
*/
export const btn_c_xdHighlight = {
  base: {background: colors.xdHighlight,color: '#fff',fill: '#fff'},
  hover: {background: colors.xdHighlightAlt},
  active: {background: colors.xdHighlightAlt},
  focus: {background: colors.xdHighlightAlt}
}
export const btn_c_whiteToHighlight = {
  base: {color: colors.xdHighlight,fill: colors.xdHighlight,background: '#fff',},
  hover: {color: '#fff',fill: '#fff',background: colors.xdHighlight,},
  active: {background: '#fff'},
  focus: {background: '#fff'}

}
export const btn_c_clearToHighlight = {
  base: {color: colors.xdDarkBlue,fill: colors.xdDarkBlue,transition: 'background .18s, color .18s, fill .18s, box-shadow .32s',boxShadow: 'none',},
  hover: {background: '#fff',boxShadow: shadow_box_sm},
  active: {background: '#fff', boxShadow: shadow_box_sm},
  focus: {background: '#fff'}
}
export const btn_c_fadeToHighlight = {
  base: {background: colors.lightBlue,color: colors.xdHighlight,fill: colors.xdHighlight,borderRadius: '200px'},
  hover: {background: colors.xdHighlight, color: '#fff', fill: '#fff'},
  active: {background: colors.xdHighlight, color: '#fff', fill: '#fff'},
  focus: {background: colors.xdHighlight, color: '#fff', fill: '#fff'}
}
export const btn_c_link = {
  base: {
    background: 'none',
    fill: colors.xdDarkBlue,
    color: colors.xdDarkBlue,
  },
  hover: {color: colors.xdHighlight, fill: colors.xdHighlight},
  active: {color: colors.xdHighlight, fill: colors.xdHighlight, background: 'rgba(0,0,0,0.08)'},
  focus: {color: colors.xdHighlight, fill: colors.xdHighlight}
}
export const btn_c_lightGreen = {
  base: {background: colors.lightGreen,color: colors.success,fill: colors.success, border: 'none', borderColor: colors.success},
  hover: {color: '#fff', fill: '#fff', background: colors.success},
  active: {color: '#fff', fill: '#fff', background: colors.success},
  focus: {color: '#fff', fill: '#fff', background: colors.success}
}
export const btn_c_lightRed = {
  base: {background: colors.lightRed, color: colors.error,fill: colors.error,},
  hover: {background: colors.error, color: '#fff', fill: '#fff'},
  active: {background: colors.error, color: '#fff', fill: '#fff'},
  focus: {backgorund: colors.error, color: '#fff', fill: '#fff'}
}
export const btn_c_darkToHighlight = {
  base: {background: colors.xdDarkBlue, color: '#fff',  fill: '#fff'},
  hover: {background: colors.xdHighlight},
  active: {background: colors.xdHighlight},
  focus: {background: colors.xdHighlight},
}
// ------- btn exports
export const btn_xs = {...btn_p_xs, ...font_s_xs};
export const btn_sm = {...btn_p_sm, ...font_s_sm};
export const btn_md = {...btn_p_md, ...font_s_md};
export const btn_lg = {...btn_p_lg, ...font_s_md};
export const btn_xl = {...btn_p_xl, ...font_s_lg};
export const btn_icon_xs = {...btn_icon_base, ...btn_circ_xs};
export const btn_icon_sm = {...btn_icon_base, ...btn_circ_sm};
export const btn_icon_md = {...btn_icon_base, ...btn_circ_md};
export const btn_icon_lg = {...btn_icon_base, ...btn_circ_lg};
export const btn_icon_xl = {...btn_icon_base, ...btn_circ_xl};
export const btn_li = {...btn_li_base, ...btn_block};
export const btn_li_sm = {...btn_li, ...btn_sm, paddingLeft: '.6rem', paddingRight: '.6rem'};
export const btn_li_md = {...btn_li, ...btn_md, paddingLeft: '.8rem', paddingRight: '.8rem'};
export const btn_li_lg = {...btn_li, ...btn_lg, paddingLeft: '1rem', paddingRight: '1rem'};



// ====================================================== Forms
/* -- input properties:
  base: base styles
  active: active styles
  focus: focus styles
*/
// ====================================================== Containers
export const content_base = {padding: '12px 8px'}
export const container_content = {...content_base, ...font_s_md}

// ====================================================== Panels
export const panel_light = {...shadow_box_sm, ...border_medium, background: '#fff'}

// ====================================================== Fonts
