import colors from './components/General/colors';

export default {
  container: {
    background: colors.background,
    minHeight: '600px',
    overflow: 'hidden',
    width: '100%',
    minHeight: '100vh',
    position: 'relative',
    transition: 'height .32s ease-out'
  },
}
