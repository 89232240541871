import axios from "axios";
import React, { useState } from "react";

function VerificationForm({ state }) {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [rememberComputer, setRememberComputer] = useState(false);

  const handleVerification = async (event) => {
    event.preventDefault();

    const payload = {
      email: state.email,
      instance: "XD",
      password: state.password,
      type: "xc_console",
      remember: rememberComputer,
      token: otp,
    };

    try {
      const response = await axios.post('http://localhost:4002/backend/rt/sso/validateTwoFactor', payload);
      console.log('Verification Response:', response.data);
debugger;
      if (!response.data.error) {
        console.log('getting')

        // Email verified successfully, redirect to specified URL
        window.location.href = response.data.url 
      } else {
        setError("Verification failed. Please try again.");  // Handle error scenario if necessary
      }
    } catch (error) {
      console.error('Verification Error:', error);
      setError("Verification failed. Please try again.");  // Update error state based on actual error handling
    }
  };

  const handleCheckboxChange = () => {
    setRememberComputer(!rememberComputer);
  };

  const handleResendCode = async () => {
    try {
      const resendPayload = {
        email: state.email,
        instance: "XD",
        password: state.password,
        type: "xc_console",
      };

      const response = await axios.post('http://localhost:4002/backend/rt/user/resendTwoFactorCode', resendPayload);
      console.log('Resend Code Response:', response.data);
      // Optionally handle success response
    } catch (error) {
      console.error('Resend Code Error:', error);
      setError("Failed to resend verification code. Please try again.");
    }
  };

  return (
    <div className="form-container sign-in-container">
      <form className="form" onSubmit={handleVerification}>
        <a href="#">Back</a>
        <h1>Verification Code</h1>
        <input
          type="text"
          placeholder="Verification Code From Email"
          name="otp"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          required
        />
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
             {/* <input
              type="checkbox"
              checked={rememberComputer}
              onChange={handleCheckboxChange}
            /> */}       
          <a href="#" style={{display:"flex",flexDirection:"row"}}>
           
            Remember this computer?
          </a>
          <a href="#" onClick={handleResendCode}>Resend Code</a>
        </div>
        {error && <p className="error">{error}</p>}
        <button type="submit">Verify</button>
      </form>
    </div>
  );
}

export default VerificationForm;
