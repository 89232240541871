import colors from '../colors';

export default {
  navLink: {
    display: 'block',
    color: colors.black,
    fill: colors.black,
    background: 'none',
    width: '100%',
    padding: '10px 8px',
    textAlign: 'left',
    textDecoration: 'none',
    fontSize: '1.4rem'
  },
  navHover: {
    color: colors.xdHighlight,
    fill: colors.xdHighlight
  },
}
