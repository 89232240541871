export default (styles, config) => {
  const titleStyles = config && config.colors
  ? {
    ...styles.formTitle,
    background: config.colors.main_dark
      ? `linear-gradient(to bottom right, ${config.colors.main}, ${config.colors.main_dark})`
      : config.colors.main,
  }
  : styles.formTitle;
  const btnStyles = config && config.colors ?
  {
    ...styles.btn,
    background: config.colors.main,
  } : styles.btn;
  const btnHovStyles = config && config.colors ? {
    ...styles.btnHov,
    background: config.colors.secondary,
  } : styles.btnHov;
  const formOverrideStyles = config ? {
    submit: {
      background: config.colors.main_dark,
      margin: '',
      display: '',
     
    },
    
    submitHov: {
      opacity: 1,
      background: config.colors.main,
    },
    submitValid: {
      background: config.colors.main_dark,
    },
    submitFoc: {
      background: config.colors.main_dark,
    },
    submitInvalid: {
      opacity: .8,
    }
  } :  null;

  return {
    titleStyles,
    btnStyles,
    btnHovStyles,
    formOverrideStyles,
  }
}
