import React, {useRef} from 'react';
import jwtDecode from 'jwt-decode';
import urlQueryParser from '../Parsers/urlQueryParser';
import fetchController from '../General/fetchController';


const UserContext = React.createContext();



// reducer
const reducer = (state, action) => {
  const clearedUser = {name: null, email: null, loggedIn: false, entries: null};
  switch(action.type) {

    // login
    case 'login':
    localStorage.setItem('jwtToken', action.token);
    
    
    let result = jwtDecode(action.token);

    
    return {
      ...state,
      id: result.id,
      email: result.email,
      instance: result.instance,
      division: result.division,
      userTitle: result.userTitle,
      affiliation: result.affiliation,
      phoneNumber: result.phoneNumber,
      name: result.name,
      accountType: result.accountType,
      isXDAdmin: result.isXDAdmin,
      
      loggedIn: true,
    };

    // logout
    case 'logout':
    delete localStorage.jwtToken;
    // if (window.location.pathname === '/profile') {
      window.location.pathname = '/'
    // }
    return clearedUser;
    default: return clearedUser;
  }
};


// provider
const UserContextProvider = props => {

  const [state, dispatch] = React.useReducer(reducer, {
    name: null,
    email: null,
    loggedIn: false,
    entries: null,
  });
 
  // login if token in local storage && is still valid
  if (localStorage.jwtToken && !state.loggedIn) {
    if (jwtDecode(localStorage.jwtToken).exp < (Date.now() / 1000)) {
      // dispatch({type: 'logout'});
    } else {
      
      console.log('UserContext -> login using cookie')
      dispatch({
        type: 'login',
        token: localStorage.jwtToken,
      })
    }
  }
 
  // if not loggedIn && session in url then login using session
  const queries = urlQueryParser();
  const fetcher = useRef(fetchController({
    url: '/backend/rt/user/activateSession/',
    endpoint: queries.session,
    method: 'get',
  }))
  
  if (!localStorage.jwtToken && queries.session && fetcher.current.status !== 'complete' && fetcher.current.status !== 'fetching') {
    console.log('UserContext --> login using session');
    fetcher.current.fetch()
    .then(res => {
      if (res.data && res.data.token) dispatch({type: 'login', token: res.data.token});
      console.log(res)
     

    })
    .catch(err => console.error(err))
  }

 
  return(
    <UserContext.Provider
      value={{
        user: state,
        userAction: dispatch,
      
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

const UserContextConsumer = UserContext.Consumer;

export {UserContext, UserContextProvider, UserContextConsumer};
