import colors from '../colors';
import {btn_c_link, btn_li_lg, svg_xs, svg_sm} from '../styleGuide';
import {absCenterY, clearfix} from '../globalStyles';

export default {
  container: {
    width: '100%',
    position: 'relative',
    background: 'rgba(0,0,0,0.16)',
    height: '100%',
    maxHeight: '85vh'
  },
  contentContainer: {
    overflow: 'hidden',
  },
  innerContentContainer: {
    ...clearfix,
  },
  content: {
    float: 'left',
    overflowY: 'scroll',
    overflowX: 'hidden',
    position: 'relative',
  },
  grabber: {
    background: 'rgba(0,0,25,0.2)',
    border: 0,
    top: '48px',
    bottom: 0,
    padding: 0,
    position: 'absolute',
    transition: 'width .12s ease-out, transform .25s ease-out',
    zIndex: 10,
    display: 'block',
    transform: 'translateX(-1px)'
  },
  grabberHover: {
    background: 'rgba(100,100,125,0.75)',
    transform: 'translateX(-6px)'
  },
  grabberArrow: {
    ...absCenterY,
    ...svg_xs,
    fill: 'rgba(0,0,25,0.4)',
    transition: 'transform .32s'
  },
  grabberArrowHov: {
    ...svg_sm,
    fill: colors.xdHighlight,
  },
  grabberLeftArrow: {
    left: '-15px',
    right: 'auto',
  },
  grabberRightArrow: {
    right: '-14px',
    left: 'auto',
  },

  widget_tab: {
    float: 'right',
    width: '20%',
    minHeight: '100%',
    maxHeight: '100%',
    overflow: 'hidden'
  },
  widget_bottomToolbar: {
    position: 'fixed',
    top: 'auto',
    left: 'auto',
    bottom: 0,
    width: '100%',
    padding: '6px 16px',
    borderTop: '1px solid rgba(0,0,0,0.16)',
    borderRight: '1px solid rgba(0,0,0,0.16)',
    background: colors.offWhite,
    zIndex: 100
  }
}
