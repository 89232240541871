import React, {useRef, useState} from 'react';
import styles from './styles';
import fetchController from '../General/fetchController';
import Button from '../General/Button';
import {ResponsiveContext} from '../General/Context/ResponsiveContext';
import Loader from '../General/Loader';
import {SsoContext} from '../hooks/ssoContext';

const VerifyEmailLandingPage = props => {

  const {state} = React.useContext(SsoContext);
  const {responsiveSize} = React.useContext(ResponsiveContext);
  const [status, setStatus] = useState('fetching');
  const fetcher = useRef(fetchController({
    url: '/backend/rt/user/',
    method: 'post',
    endpoint: 'update-verification/',
    body: {
      userId: state.urlQueries.id,
      verificationId: 'email',
      value: true,
    }
  }))

  if (fetcher.current.status !== 'fetching' && fetcher.current.status !== 'complete') {
    fetcher.current.fetch()
    .then(res => {
      if (res.error) {
        setStatus('error');
      } else {
        setStatus('complete')
      }
    })
    .catch(err => {
      console.error(err);
      setStatus('error');
    })
  }

  const containerPosition = responsiveSize === 'mobile'
    ? styles.mobileWrap : styles.desktopWrap;

  return(
    <div style={{...styles.wrap, ...containerPosition}}>
      <div style={styles.container}>
        <h1 style={styles.pageTitle}>Verify Email Address</h1>
        {status === 'fetching' &&
          <div style={styles.loaderWrap}>
            <Loader
              width='150px'
              height='150px'
              noBg
            />
          </div>
        }
        {(status === 'error' || status === 'complete') &&
          <div style={styles[status + 'Container']}>
            <p style={styles.message}>{fetcher.current.message}</p>
            {status === 'complete' &&
              <Button
                onClick={() => props.history.push('/sso/?app=' + state.urlQueries.app + '?instance=' + state.urlQueries.instance )}
                styles={styles.toLoginBtn}
                hoverStyles={styles.toLoginBtnHov}
              >
                Login
              </Button>
            }
          </div>
        }
      </div>
    </div>
  )
}

export default VerifyEmailLandingPage;
