import React, { useEffect, useRef } from 'react';
import { TweenLite, Circ } from 'gsap';
import './BackgroundAnimation.css';

const BackgroundAnimation = () => {
  const canvasRef = useRef(null);
  const largeHeaderRef = useRef(null);
  const points = useRef([]);
  const target = useRef({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
  let animateHeader = true;

  useEffect(() => {
    initHeader();
    initAnimation();
    addListeners();

    return () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('scroll', scrollCheck);
      window.removeEventListener('resize', resize);
    };
  }, []);

  const initHeader = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    const largeHeader = largeHeaderRef.current;
    largeHeader.style.height = `${height}px`;

    const canvas = canvasRef.current;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    points.current = [];
    for (let x = 0; x < width; x += width / 15) {
      for (let y = 0; y < height; y += height / 15) {
        const px = x + Math.random() * width / 15;
        const py = y + Math.random() * height / 15;
        const p = { x: px, originX: px, y: py, originY: py };
        points.current.push(p);
      }
    }

    for (let i = 0; i < points.current.length; i++) {
      const closest = [];
      const p1 = points.current[i];
      for (let j = 0; j < points.current.length; j++) {
        const p2 = points.current[j];
        if (!(p1 === p2)) {
          let placed = false;
          for (let k = 0; k < 5; k++) {
            if (!placed) {
              if (!closest[k]) {
                closest[k] = p2;
                placed = true;
              }
            }
          }

          for (let k = 0; k < 5; k++) {
            if (!placed) {
              if (getDistance(p1, p2) < getDistance(p1, closest[k])) {
                closest[k] = p2;
                placed = true;
              }
            }
          }
        }
      }
      p1.closest = closest;
    }

    for (const point of points.current) {
      const c = new Circle(point, 4 + Math.random() * 5, 'rgba(255,255,255,0.3)', ctx);
      point.circle = c;
    }
  };

  const addListeners = () => {
    if (!('ontouchstart' in window)) {
      window.addEventListener('mousemove', mouseMove);
    }
    window.addEventListener('scroll', scrollCheck);
    window.addEventListener('resize', resize);
  };

  const mouseMove = (e) => {
    let posx = 0;
    let posy = 0;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
      posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }
    target.current.x = posx;
    target.current.y = posy;
  };

  const scrollCheck = () => {
    if (document.body.scrollTop > window.innerHeight) animateHeader = false;
    else animateHeader = true;
  };

  const resize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const largeHeader = largeHeaderRef.current;
    largeHeader.style.height = `${height}px`;

    const canvas = canvasRef.current;
    canvas.width = width;
    canvas.height = height;
  };

  const initAnimation = () => {
    animate();
    for (const point of points.current) {
      shiftPoint(point);
    }
  };

  const animate = () => {
    const ctx = canvasRef.current.getContext('2d');
    if (animateHeader) {
      ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
      for (const point of points.current) {
        if (Math.abs(getDistance(target.current, point)) < 4000) {
          point.active = 0.4;
          point.circle.active = 0.7;
        } else if (Math.abs(getDistance(target.current, point)) < 20000) {
          point.active = 0.2;
          point.circle.active = 0.4;
        } else if (Math.abs(getDistance(target.current, point)) < 40000) {
          point.active = 0.12;
          point.circle.active = 0.2;
        } else {
          point.active = 0;
          point.circle.active = 0;
        }

        drawLines(point, ctx);
        point.circle.draw();
      }
    }
    requestAnimationFrame(animate);
  };

  const shiftPoint = (p) => {
    TweenLite.to(p, 1 + 1 * Math.random(), {
      x: p.originX - 50 + Math.random() * 100,
      y: p.originY - 50 + Math.random() * 100,
      ease: Circ.easeInOut,
      onComplete: () => {
        shiftPoint(p);
      }
    });
  };

  const drawLines = (p, ctx) => {
    if (!p.active) return;
    for (const closest of p.closest) {
      ctx.beginPath();
      ctx.moveTo(p.x, p.y);
      ctx.lineTo(closest.x, closest.y);
      ctx.strokeStyle = `rgb(24,18,137,${p.active})`;
      ctx.stroke();
    }
  };

  const getDistance = (p1, p2) => {
    return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
  };

  class Circle {
    constructor(pos, rad, color, ctx) {
      this.pos = pos;
      this.radius = rad;
      this.color = color;
      this.ctx = ctx;
    }

    draw() {
      if (!this.active) return;
      this.ctx.beginPath();
      this.ctx.arc(this.pos.x, this.pos.y, this.radius, 0, 40 * Math.PI, false);
      this.ctx.fillStyle = `rgb(24,18,137,${this.active})`;
      this.ctx.fill();
    }
  }

  return (
    <div id="large-header" className="large-header" ref={largeHeaderRef}>
      <canvas id="demo-canvas" ref={canvasRef}></canvas>
    </div>
  );
};

export default BackgroundAnimation;
