import axios from 'axios';
import axiosConfig from '../../config/axios.config';
import crypto from 'crypto-js'

export default {

  // -- fetch cookie information
  getCookie: (appId, instance, setCheckingCookie) =>
    axios.post(axiosConfig.apiUrl + '/backend/rt/sso/cookie', {
      sessionID: crypto.AES.decrypt(localStorage.sso_session, axiosConfig.ssoKey).toString(crypto.enc.Utf8),
      type: appId,
      instance: instance,
    })
    .then(res => {
      if (res.data.redirect) window.location = res.data.redirect + '?session=' + res.data.session;
    })
    .catch(err => {
      console.error(err, 'sso session check error');
      setCheckingCookie(false);
      localStorage.removeItem('sso_session');
    }),

  // -- fetch config settings for instance
  getInstanceConfig: (instance, dispatch) =>
    axios.get(axiosConfig.apiUrl + '/backend/rt/sso/config/' + instance)
    .then(res => {
       console.log('res', res)
      dispatch({instanceConfig: res.data ? res.data : ''})
    })
    .catch(err => {
      console.error(err);
      dispatch({instanceConfig: false});
    })
}
