import React, {useState, useRef} from 'react';
import styles from './styles';
import {ModalContainer, InOutAnimation as Animation} from '@xpertdox_js/xdcomponents';
import ForgotPasswordForm from '../UserForms/ForgotPasswordForm';
import LoginForm from '../UserForms/LoginForm';
import RegisterForm from '../UserForms/RegisterForm';
import TwoFactorForm from '../UserForms/TwoFactorForm';
import urlQueryParser from '../Parsers/urlQueryParser';
import {SsoContext} from '../hooks/ssoContext';
import formStyles from '../UserForms/styles.js';
import xdLogo from '../../assets/xd-logo.png';
import buildAnimation from './animation';
import LoadingLandingPage from '../LoadingLandingPage';
import constructStyles from './constructStyles';
import BackgroundAnimation from '../layout/BackgroundAnimation.js';

const LoginLandingPage = (props) => {
  const [route, setRoute] = useState(() =>
    window.location.pathname.includes("resetPassword") ? "forgot" : "login"
  );
  const [disclaimerField, setDisclaimerField] = useState(false);
  const twoFactorData = useRef(false);
  const { state, toggleModal } = React.useContext(SsoContext);
  const toLogin = () => setRoute("login");
  const toRegister = () =>
    !["xt_dashboard", "xt_coding"].includes(state.urlQueries.app)
      ? setRoute("register")
      : null;
  const toForgot = () => setRoute("forgot");
  const to2fa = () => setRoute("twoFactor");
  const inDelay = useRef(0);
  const { instanceConfig } = state;
  const constructedStyles = constructStyles(formStyles, instanceConfig);

  const disclaimerFieldSettings = {
    field: {
      type: "radio",
      label:
        "The Admin Console stores data of Users (patients, family members, friends, or providers who have expressed interest in a clinical trial) and also data of past and ongoing clinical trials. Please verify that you are a study representative authorized to access this information.",
      id: "disclaimer_radio",
      labelStyles: styles.disclaimer,
      styles: {
        labelText: { fontWeight: "normal" },
      },
      options: [
        ["I am a Study Representative", "disclaimer_agree"],
        // ['I am not a Study Representative', 'disclaimer_disagree']
      ],
      validators: ["hasAgreedDisclaimer"],
    },
    validator: {
      hasAgreedDisclaimer: {
        message: "Must be study representative to continue.",
        test: (input) => {
          return input.value === "disclaimer_agree";
        },
      },
    },
  };

  if (inDelay.current === 0) inDelay.current = 320;

  const queries = urlQueryParser();
  const appFromId = queries.app || "xt_screen";

  const info =
    state.instanceConfig && state.instanceConfig.images
      ? state.instanceConfig.images.logo
      : null;
  const image = useRef(null);
  if (info) {
    const dataFlag = info.type === "pdf" ? "application" : "image";
    const typeFlag =
      info.type === "jpg"
        ? "jpeg"
        : info.type === "svg"
        ? "svg+xml"
        : info.type;
    const base64Flag = `data:${dataFlag}/${typeFlag};base64,`;
    const imageStr = info.img;
    image.current = base64Flag + imageStr;
  }

  const logoStyles =
    state.instanceConfig &&
    state.instanceConfig.images &&
    state.instanceConfig.images.logo &&
    state.instanceConfig.images.logo.styles
      ? { ...styles.logo, ...state.instanceConfig.images.logo.styles }
      : styles.logo;

  const animation = buildAnimation(inDelay);

  // redirect to loading if handling token through azure
  const [loading, setLoading] = React.useState(false);
  React.useLayoutEffect(() => {
    if (window.location.href.includes("#id_token") && !loading)
      setLoading(true);
  }, [loading, setLoading]);

  // track routing and display content accordingly
  // const lastRoute = React.useRef(route);
  const [currentDisplay, setCurrentDisplay] = React.useState(route);

    const parentFormStyles = {
      submitButton: {
        backgroundColor: 'blue',
        color: 'white',
        borderRadius: '5px',
        width:'100%'
      },
      // Other styles...
    };
    const combinedFormStyles = {
      ...constructedStyles.formOverrideStyles,
      ...parentFormStyles,
    };
  const memoDeps = [route, currentDisplay, instanceConfig ? instanceConfig.id : instanceConfig]

  const MemoizedLoginForm =
  <Animation
    condition={route === 'login'}
    animation={animation}
    wrapStyles={styles.animationWrap}
    fixedTransform='translateX(-50%) translate3d(0,0,0)'
    callback={route !== 'login' && currentDisplay !== route ? () => setCurrentDisplay(route) : null}
  >
    <LoginForm
      toForgot={toForgot}
      toRegister={toRegister}
      to2fa={to2fa}
      setTwoFactorData={(data) => {
        twoFactorData.current = {
          ...twoFactorData.current,
          ...data,
        }
      }}
      from={appFromId}
      disclaimerField={disclaimerField}
      setDisclaimerField={setDisclaimerField}
      disclaimerFieldSettings={disclaimerFieldSettings}
      history={props.history}
      titleStyles={constructedStyles.titleStyles}
      btnStyles={constructedStyles.btnStyles}
      btnHovStyles={constructedStyles.btnHovStyles}
      config={instanceConfig}
      formStyles={constructedStyles.formOverrideStyles}
      active={route === 'login'}
    />
  </Animation>;
const MemoizedRegisterForm =
  <Animation
    condition={route === 'register'}
    animation={animation}
    wrapStyles={{...styles.animationWrap, maxWidth: '927px', top: '50%'}}
    fixedTransform='translateX(-50%)'
    callback={route !== 'register' && currentDisplay !== route ? () => setCurrentDisplay(route) : null}
  >
    <RegisterForm
      toLogin={toLogin}
      from={appFromId}
      disclaimerFieldSettings={disclaimerFieldSettings}
      titleStyles={constructedStyles.titleStyles}
      btnStyles={constructedStyles.btnStyles}
      btnHovStyles={constructedStyles.btnHovStyles}
      config={instanceConfig}
      formStyles={constructedStyles.formOverrideStyles}
      active={route === 'register'}
    />
  </Animation>;
const MemoizedForgotPwForm =
  <Animation
    condition={route === 'forgot'}
    animation={animation}
    wrapStyles={styles.animationWrap}
    fixedTransform='translateX(-50%)'
    callback={route !== 'forgot' && currentDisplay !== route ? () => setCurrentDisplay(route) : null}
  >
    <ForgotPasswordForm
      toLogin={toLogin}
      from={appFromId}
      titleStyles={constructedStyles.titleStyles}
      btnStyles={constructedStyles.btnStyles}
      btnHovStyles={constructedStyles.btnHovStyles}
      config={instanceConfig}
      formStyles={combinedFormStyles}
      
      toRegister={toRegister}
      active={route === 'forgot'}
    />
  </Animation>;
const MemoizedTwoFactorForm =
  <Animation
    condition={route === 'twoFactor'}
    animation={animation}
    wrapStyles={styles.animationWrap}
    fixedTransform='translateX(-50%)'
    callback={route !== 'twoFactor' && currentDisplay !== route ? () => setCurrentDisplay(route) : null}
  >
    <TwoFactorForm
      toLogin={toLogin}
      setDisclaimerField={setDisclaimerField}
      toRegister={toRegister}
      toForgot={toForgot}
      data={twoFactorData.current}
      from={appFromId}
      route={route}
      titleStyles={constructedStyles.titleStyles}
      btnStyles={constructedStyles.btnStyles}
      btnHovStyles={constructedStyles.btnHovStyles}
      config={instanceConfig}
      formStyles={constructedStyles.formOverrideStyles}
      active={route === 'twoFactor'}
    />
  </Animation>;



  return(
      !loading ?
      <React.Fragment>
        <div style={state.instanceConfig && state.instanceConfig.styleOverrides && state.instanceConfig.styleOverrides.header
          ? {...styles.header, ...state.instanceConfig.styleOverrides.header}
          : styles.header
        }>
          {image.current && <img src={image.current} style={logoStyles} alt={state.instanceConfig.name}/>}
           <img src={xdLogo} style={styles.logoRight} alt='XpertDox LLC' />
        </div> 
          {/* <BackgroundAnimation/> */}
        {MemoizedLoginForm}
        {MemoizedRegisterForm}
        {MemoizedForgotPwForm}
        {MemoizedTwoFactorForm}
          <ModalContainer
            open={state.modals.moreInfo}
            showOverlay={state.modals.moreInfo}
            wrapStyles={styles.modalContainer}
            close={() => toggleModal('moreInfo', false)}
          >
            <p style={styles.modalText}>Federal regulations consider information about your identity and conditions to be Protected Health Information for which you have certain rights. This includes the right to know who will receive the information and how it will be used. By clicking "Sign Up" you agree to share your information with the research team at {state.instanceConfig ? state.instanceConfig.name : state.urlQueries.instance}.</p>
          </ModalContainer>
      </React.Fragment>
      : <LoadingLandingPage />
  )
}

export default LoginLandingPage;
