// import {absCenter, fixedFill} from '../globalStyles';
const button = {
  padding: '10px 24px'
}
export default {
  container: {
    background: 'rgba(0,0,0,.1)',
    transform: 'scale(0)',
    opacity: 0,
  },
  content: {
    background: '#fff',
    boxShadow: '0 16px 12px -12px rgba(0,0,0,0.2)',
    padding: '10px',
    maxWidth: '500px',
    borderRadius: '2px',
    minHeight: '120px',
  },
  question: {
    margin: '2px 0 16px',
    fontSize: '1.8rem',
  },
  accept: {
    ...button,
    background: '#16b867'
  },
  acceptHov: {
    background: '#19cf74'
  },
  cancel: {
    ...button,
    background: '#4d565d'
  },
  cancelHov: {
    background: '#E62712'
  },
}
