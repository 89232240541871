import React, { useRef } from "react";
import defaultStyles from "./styles";
import Button from "../Button";
import errorMaster from "./utilities/errorMaster";
import fieldController from "./Fields/fieldController";
import constructFormState from "./utilities/constructFormState";
import checkIfNeedsReInit from "./utilities/checkIfNeedsReInit";
import defaultValidators from "./utilities/defaultValidators";

const Form = (props) => {
  // console.log(props,"jjfsdjlfkjlkflksj")
  // style merge
  let styles = { ...defaultStyles };
  if (props.styles) {
    Object.keys(props.styles).forEach((key) => {
      styles[key] = { ...styles[key], ...props.styles[key] };
    });
  }

  // merge custom validators into pre-built
  const errorSheet = { ...errorMaster, ...props.validators };

  // get form state from props and initialize Ref for value tracking
  const formRef = useRef({});
  const { formState, setFormState } = props.state;

  // check if fields need to reconstruct
  const refreshMemo = useRef(props.memo || false);
  const needsInit = useRef(true);
  needsInit.current = checkIfNeedsReInit(
    needsInit.current,
    formRef.current,
    props.settings
  );

  if (typeof props.memo !== "undefined" && props.memo !== refreshMemo.current) {
    needsInit.current = true;
  }

  // update function
  const updateFormState = (res) => {
    formRef.current = {
      ...formRef.current,
      [res.id]: res.value,
    };
    setFormState(formRef.current);
  };

  // returns current form values (for controllers to get updated values if needed)
  const getCurrentForm = () => formRef.current;

  // initialize values to state in props on mount only
  if (needsInit.current) {
    const initial = constructFormState(
      props.settings,
      formRef.current,
      props.memo !== refreshMemo.current
    );
    setFormState({ ...initial });
    formRef.current = { ...initial };
    refreshMemo.current = props.memo;
  }

  // initialize fields on mount only
  const fields = useRef([]);
  if (needsInit.current) {
    // map state/validators into fields
    let mapValuesToSettings = props.settings.slice();
    Object.keys(formRef.current).forEach((id) => {
      mapValuesToSettings = mapValuesToSettings.map((obj) => {
        if (obj.groupId === id) obj.selected = formRef.current[id];
        else if (obj.id === id) obj.value = formRef.current[id];

        // Validators setup
        obj.hideValidators = obj.hideValidators || [];
        obj.validators = obj.validators || [];

        const noHiddenValidators =
          typeof obj.hideValidators === "undefined" ||
          obj.validators.length === 0;
        if (
          Array.isArray(obj.validators) &&
          (noHiddenValidators ||
            !obj.hideValidators.includes("fieldRequired")) &&
          obj.notRequired !== true &&
          obj.type !== "lineBreak" &&
          obj.type !== "title"
        ) {
          obj.validators.push("fieldRequired");
        }

        // Validator and style setup
        let initiallyValid = true;
        let validators = {};
        if (obj.validators && Array.isArray(obj.validators)) {
          initiallyValid =
            noHiddenValidators || obj.validators.includes("fieldRequired");
          obj.validators.forEach(
            (validatorId) => (validators[validatorId] = errorSheet[validatorId])
          );

          // Check for additional validators
          const validatorType = obj.confirmPassword
            ? "confirmPassword"
            : obj.type;
          if (defaultValidators[validatorType]) {
            defaultValidators[validatorType].forEach((validatorId) => {
              validators[validatorId] = errorSheet[validatorId];
            });
          }

          obj.validators = validators;
        }

        // Validity setup
        obj.valid =
          Object.keys(obj.validators).includes("fieldRequired") &&
          (!obj.value || obj.value === "")
            ? false
            : initiallyValid;

        // Style setup
        obj.mergedStyles = styles;
        if (typeof props.showErrors !== "undefined")
          obj.readyToValidate = props.showErrors;

        if (obj.type === "title" || obj.type === "lineBreak") obj.valid = true;

        return obj;
      });
    });

    fields.current = mapValuesToSettings.map((settings) =>
      fieldController({
        ...settings,
        getCurrentForm: getCurrentForm,
        setter: updateFormState,
      })
    );
  }

  const valid =
    fields.current.filter((field) => field.valid === false).length === 0;

  const handleSubmit = (e) => {
    // console.log('Form submit:', formRef.current);
    // console.log('Form submit:', fields.current[0].value);
    // if (fields.current[0].value == "" || fields.current[1].value == "") {
    //   let a = errorSheet["fieldRequired"];

    //   console.log(a, "--------------------");
    // }

    if (!fields.current[0].readyToValidate) {
      fields.current.forEach((field) => {
        if (
          typeof field.groupId === "undefined" ||
          formRef.current[field.groupId] !== ""
        ) {
          field.readyToValidate = true;
          field.validate(
            { id: field.id, value: formRef.current[field.id] },
            formRef.current
          );
          field.handleSetter(
            { id: field.id, value: formRef.current[field.id] },
            updateFormState
          );
        }
      });
    }
    if (props.returnEvent) e.persist();
    if (!props.action) {
      if (e && e.stopPropagation) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
      }
      if (valid && props.submitable !== false) props.handleSubmit(e);
    }
  };

  if (!fields.current[0].handleSubmit)
    fields.current.forEach((field) => (field.handleSubmit = handleSubmit));

  //style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
  const renderAdditionalButtons = () => {
    if (props.additionalButtons && Array.isArray(props.additionalButtons)) {
      // Create a flex container with a gap between buttons
      const buttonContainerStyle = {
        display: "flex", // Use flexbox layout
        gap: "30px",
        width: "100%", // Adjust this value as per your preferred gap between buttons
       
        justifyContent: "center",
      };

      return (
        <div style={buttonContainerStyle}>
          {props.additionalButtons.map((button, index) => (
            <Button
              key={index}
              onClick={button.onClick}
              styles={styles.submit}
              hoverStyles={button.hoverStyles || styles.submitHov}
              activeStyles={button.activeStyles || styles.submitAct}
              focusStyles={button.focusStyles || styles.submitFoc}
            >
              {button.text}
            </Button>
          ))}
        </div>
      );
    }

    return null;
  };

  //console.log(props.additionalButtons1,'=============>>>>>>>>>>')

  //style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
  const renderAdditionalButtons1 = () => {
    if (props.additionalButtons1 && Array.isArray(props.additionalButtons1)) {
      // Create a flex container with a gap between buttons
      const buttonContainerStyle = {
        display: "flex", // Use flexbox layout
        gap: "30px", // Adjust this value as per your preferred gap between buttons
        float: "left",

        textAlign: "right",
        marginTop: -71,
        flexDirection: "row",
        justifyContent: "center",
        // alignItems: 'flex-end',
        //  minWidth:'700px',
        width: "100%",
      };

      const styles1 = {
        // Other styles
        submit1: {
          margin: "25px auto",
          height: "58px",
          maxWidth: "100%",
          borderRadius: "10px",

          ...styles.submit,
          width: "700vw",
          marginBottom: 20,
          marginTop: 30,
        },
        // Other styles
      };

      return (
        <div style={buttonContainerStyle}>
          {props.additionalButtons1.map((button, index) => (
            <>
              <Button
                key={index}
                onClick={button.onClick}
                // styles={button.fullWidth ? {width:'100% !important',...styles.submit }:button.styles || styles.submit }
                //styles={{ ...styles.submit, ...buttonStyles }}
                styles={
                  button.fullWidth
                    ? styles1.submit1
                    : button.styles || styles.submit
                }
                hoverStyles={button.hoverStyles || styles.submitHov}
                activeStyles={button.activeStyles || styles.submitAct}
                focusStyles={button.focusStyles || styles.submitFoc}
                fullWidth={button.fullWidth}
              >
                {button.text}
              </Button>
            </>
          ))}
        </div>
      );
    }

    return null;
  };

  //props.submitText &&
  //style={styles.lineBreakLabel}
  return (
    <div style={styles.container}>
      {props.title && <h2 style={styles.title}>{props.title}</h2>}
      <form
        style={styles.form}
        action={props.action}
        method={props.method}
        id={props.id}
        key={props.id}
        onSubmit={handleSubmit}
        autoComplete={props.autoComplete}
      >
        <div style={{display:'flex',flexDirection:'row', flexWrap:"wrap", justifyContent:"space-between"}}>
        {fields.current.map((field) => {
          const invalidResult =
            field.validatorResult &&
            field.validatorResult.find((result) => result.valid !== true);

            let widthtemp=field.userLabelStyles?.width?"49%":"100%";
          return (
            <React.Fragment key={field.id}>
              {/* {console.log(field,"3354454")} */}
             <div style={{display:"flex",flexDirection: "column", width:widthtemp}}>
              {field.construct()}
              {/* {console.log(field.construct(),"324234435345e3454564")} */}
              {invalidResult ? (
                <span
                style={{
                  display:"block",
                  color: "red",
                  position: "relative",
                  margin: "-7px 10px"
                }}
                >
                  {invalidResult.message}
                </span>
              ) : null}
              </div>
            </React.Fragment>
          );
        })}
        </div>
        <div style={{...styles.lineBreakLabel,margin:props.settings.length>2?"10px 0px":"30px 0px"}}>
          {/* {console.log(props,"indexxxxxxxxxxxxxxxxxxxxxxxxx")} */}
          {props.submitable !== false && props.submitText && (
            <Button
            styles={
              valid
              ? {
                ...styles.submit,
                ...styles.register,
                ...styles.submitValid,
                width: "",
              }
              : styles.submit
            }
            hoverStyles={valid ? styles.submitHov : styles.submit}
            activeStyles={valid ? styles.submitAct : styles.submit}
            focusStyles={styles.submitFoc}
            type={"submit"}
            >
              {props.submitText || "SEND"}
            </Button>
          )}
          {renderAdditionalButtons()}
          {renderAdditionalButtons1()}
        </div>
      </form>
    </div>
  );
};

export default Form;
