// import React from 'react';
// import MSAL from '../../config/msal.config.js';
// import axios from 'axios';
// import config from '../../config/axios.config';
// import {UserAgentApplication} from "msal";
// import {Client} from '@microsoft/microsoft-graph-client';

// const MSALintegration = props => {
//   const {msalConfig, msalConfig2, loginRequest} = MSAL;
//   const [fetchedUser, setFetchedUser] = React.useState(null);


//   let msalFetched = React.useRef(false);
//   const setupMSAL = () => {
//     if (!msalFetched.current)  {
//       msalFetched.current = true;
//       try {
//         const msalObj = new UserAgentApplication({
//           auth: {
//             clientId: msalConfig2.appId,
//             redirectUri: msalConfig2.redirectUri,
//             authority: msalConfig2.authority,
//           },
//           cache: {
//             cacheLocation: 'sessionStorage',
//             storeAuthStateInCooke: true,
//           }
//         });
//         console.log(msalObj);
//         return msalObj;
//       } catch(err) {
//         console.error(err, '\nIntegrated login error - MSAL\n');
//         return null;
//       }
//     }
//   }
//   const myMSALObj = React.useRef(setupMSAL());
//   const msalHandler = myMSALObj.current;

//   // login user to xpertdox api
//   const loginHybridUser = async (importedUser) => {
//     const body = {
//       userInfo: {
//         name: importedUser.displayName,
//         userTitle: importedUser.jobTitle,
//         email: importedUser.email || importedUser.userPrincipalName,
//         phoneNumber: importedUser.mobilePhone,
//       },
//       instance: props.state.urlQueries.instance || props.config.id,
//       appID: props.state.urlQueries.app || 'xt_screen',
//     }
//     try {
//       const res = await axios({
//         url: config.apiUrl + '/backend/rt/sso/int_login/',
//         method: 'POST',
//         data: body,
//         headers: config.headers,
//       })
//       if (res) {
//         props.setStatus('complete');
//         console.log(res.data, 'data from response');
//         if (res.data && res.data.cookies) props.handleSession(res.data.cookies.session);
//         console.log(res.data.redirect + '?session=' + res.data.session)
//         window.location = res.data.redirect + '?session=' + res.data.session;
//       }
//     } catch(err) {
//       console.error(err.message, '\n -- login hybrid user error');
//       props.setVisible(true);
//     }
//   }

//   const getAccessToken = () => msalHandler.acquireTokenSilent({scopes: msalConfig2.scopes});
//   const getUserInfo = (accessToken) => {
//     const graphClient = Client.init({
//       authProvider: (done) => {
//         done(null, accessToken);
//       }
//     });
//     return graphClient.api('/me').get();
//   }

//   // login user using azure ad popup window
//   const handleIntegratedLogin = async () => {
//     if (msalHandler) {
//       try {
//         if (props.popupError) props.setPopupError(false);
//         props.setVisible(false);
//         let importedUser = fetchedUser;
//         if (!importedUser) {
//           const popupResult = await msalHandler.loginPopup({
//             scopes: msalConfig2.scopes,
//             props: 'select_accout'
//           });
//           const accessToken = await getAccessToken();
//           importedUser = await getUserInfo(accessToken.accessToken);
//           console.log(importedUser, 'user graph result');
//         }
//         loginHybridUser(importedUser);

//       } catch(err) {
//         props.setPopupError(err.message || 'Error occurred connecting to integration.');
//         props.setVisible(true);
//       }
//     }
//   }

//   const handleIntegratedLogout = () => msalHandler ? msalHandler.logout() : null;
//   const initFetch = React.useRef(false);
//   const checkForUser = async () => {
//     try {
//       initFetch.current = true;
//       const accessToken = await msalHandler.acquireTokenSilent({scopes: msalConfig2.scopes});
//       if (!accessToken) throw new Error('token fetch error');
//       // props.setVisible(false);
//       const importedUser = await getUserInfo(accessToken.accessToken);
//       if (!importedUser) throw new Error('Failed to import user.');
//       setFetchedUser(importedUser)
//       // loginHybridUser(importedUser)
//     } catch(err) {
//       props.setVisible(true);
//       console.error(err, 'initial integrated fetch error.')
//     }

//   }
//   if (!initFetch.current) checkForUser();


//   return ({
//     handleIntegratedLogin,
//     handleIntegratedLogout
//   });

// }

// export default MSALintegration;


import React, { useState, useEffect, useRef } from 'react';
import MSAL from '../../config/msal.config.js';
import axios from 'axios';
import config from '../../config/axios.config';
import { UserAgentApplication } from 'msal';
import { Client } from '@microsoft/microsoft-graph-client';

const MSALintegration = (props) => {
  const { msalConfig, msalConfig2, loginRequest } = MSAL;
  const [fetchedUser, setFetchedUser] = useState(null);
  const msalFetched = useRef(false);
  const myMSALObj = useRef(null);

  const setupMSAL = () => {
    try {
      const msalObj = new UserAgentApplication({
        auth: {
          clientId: msalConfig2.appId,
          redirectUri: msalConfig2.redirectUri,
          authority: msalConfig2.authority,
        },
        cache: {
          cacheLocation: 'sessionStorage',
          storeAuthStateInCookie: true,
        },
      });
      return msalObj;
    } catch (err) {
      console.error(err, '\nIntegrated login error - MSAL\n');
      return null;
    }
  };

  useEffect(() => {
    if (!msalFetched.current) {
      myMSALObj.current = setupMSAL();
      msalFetched.current = true;
    }
  }, []);

  const msalHandler = myMSALObj.current;

  const loginHybridUser = async (importedUser) => {
    const body = {
      userInfo: {
        name: importedUser.displayName,
        userTitle: importedUser.jobTitle,
        email: importedUser.email || importedUser.userPrincipalName,
        phoneNumber: importedUser.mobilePhone,
      },
      instance: props.state.urlQueries.instance || props.config.id,
      appID: props.state.urlQueries.app || 'xt_screen',
    }
    try {
      const res = await axios({
        url: config.apiUrl + '/backend/rt/sso/int_login/',
        method: 'POST',
        data: body,
        headers: config.headers,
      })
      if (res) {
        props.setStatus('complete');
        console.log(res.data, 'data from response');
        if (res.data && res.data.cookies) props.handleSession(res.data.cookies.session);
        console.log(res.data.redirect + '?session=' + res.data.session)
        window.location = res.data.redirect + '?session=' + res.data.session;
      }
    } catch(err) {
      console.error(err.message, '\n -- login hybrid user error');
      props.setVisible(true);
    }
  };

  const getAccessToken = async () => {
    try {
      const accessToken = await msalHandler.acquireTokenSilent({ scopes: msalConfig2.scopes });
      if (!accessToken) throw new Error('Access token is null');
      return accessToken;
    } catch (err) {
      throw new Error('Failed to acquire access token');
    }
  };

  const getUserInfo = async (accessToken) => {
    try {
      const graphClient = Client.init({
        authProvider: (done) => {
          done(null, accessToken);
        },
      });
      const userInfo = await graphClient.api('/me').get();
      return userInfo;
    } catch (err) {
      throw new Error('Failed to get user info');
    }
  };

  const handleIntegratedLogin = async () => {
    if (msalHandler) {
      try {
        if (props.popupError) props.setPopupError(false);
        props.setVisible(false);
        let importedUser = fetchedUser;
        if (!importedUser) {
          const popupResult = await msalHandler.loginPopup({
            scopes: msalConfig2.scopes,
            props: 'select_accout'
          });
          const accessToken = await getAccessToken();
          importedUser = await getUserInfo(accessToken.accessToken);
          console.log(importedUser, 'user graph result');
        }
        loginHybridUser(importedUser);

      } catch(err) {
        props.setPopupError(err.message || 'Error occurred connecting to integration.');
        props.setVisible(true);
      }
    }
  };

  const handleIntegratedLogout = () => {
    if (msalHandler) msalHandler.logout();
  };

  const initFetch = useRef(false);

  const checkForUser = async () => {
    try {
      initFetch.current = true;
      const accessToken = await getAccessToken();
      if (!accessToken) throw new Error('token fetch error');
      // props.setVisible(false);
      const importedUser = await getUserInfo(accessToken.accessToken);
      if (!importedUser) throw new Error('Failed to import user.');
      setFetchedUser(importedUser)
      // loginHybridUser(importedUser)
    } catch(err) {
      props.setVisible(true);
      console.error(err, 'initial integrated fetch error.')
    }

  };

  useEffect(() => {
    if (!initFetch.current && msalHandler) {
      checkForUser();
    }
  }, [msalHandler]);

  return {
    handleIntegratedLogin,
    handleIntegratedLogout
  };
};

export default MSALintegration;
