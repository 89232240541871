import React, {useRef} from 'react';
// import jwtDecode from 'jwt-decode';
// import instanceSetup from './instanceSetup';
import parseUrlQueries from './parseUrlQueries';
import colors from '../General/colors';
import {absCenter} from '../General/globalStyles';
import {Loader} from '../General';
// import axios from 'axios';
// import axiosConfig from '../../config/axios.config';
import XdLogoBg from '../XdLogoBg';
import contextFetch from './contextFetch';
import ListOfInstancesPage from '../ListOfInstancesPage';
import MSAL from '../UserForms/MSALintegration';

const SsoContext = React.createContext();



// ==== reducer
const reducer = (state, action) => {
  return {
    ...state,
    ...action,
  }
};


// ==== provider
const SsoContextProvider = props => {

  const [state, dispatch] = React.useReducer(reducer, {
    storageToken: localStorage.sso_session,
    instanceConfig: localStorage.instanceConfig ? JSON.parse(localStorage.instanceConfig) : null,
    urlQueries: parseUrlQueries(),
    colors: {
      main: colors.main,
      secondary: colors.xdHighLight,
    },
    modals: {
      moreInfo: false,
    },
    msalInteg: false,
  });
  const [checkingCookie, setCheckingCookie] = React.useState(localStorage.sso_session !== undefined);

  const setupMSAL = (object) => {dispatch({msalInteg: MSAL(object)})};

  // --- udpate modal display
  const toggleModal = (modal, value) => {
    dispatch({
      modals: {
        ...state.modals,
        [modal]: value,
      }
    })
  }

  // -- set instance in storage
  if ((!localStorage.instance && state.instanceConfig !== null)
    || (state.instanceConfig !== null && localStorage.instance !== state.instanceConfig.id)
    || (!localStorage.instanceConfig && state.instanceConfig))
    {
      localStorage.setItem('instance', state.instanceConfig.id);
      localStorage.setItem('instanceConfig', JSON.stringify(state.instanceConfig))
  }

  // -- track events
  const events = useRef({
    fetchedCookie: !localStorage.sso_session,
    fetchedConfig: false,
  })

  // -- check for stored cookie
  if (localStorage.sso_session && !events.current.fetchedCookie && (state.urlQueries.instance || localStorage.instance)) {
    events.current.fetchedCookie = true;
    contextFetch.getCookie(state.urlQueries.app, (state.urlQueries.instance || localStorage.instance), setCheckingCookie);
  }
  if (state.urlQueries.instance === '' && state.instanceConfig !== '') {
    dispatch({instanceConfig: ''})
  }

  // -- set session cookie for ease of login
  const setSessionCookie = session => localStorage.setItem('sso_session', session);

  // -- check for confilcting instances
  const instanceConflict = localStorage.instanceConfig && state.urlQueries.instance
   && state.urlQueries.instance !== localStorage.instanceConfig.id;

  // -- fetch instance configuration file
  if ((state.instanceConfig === null || instanceConflict)
    && state.urlQueries.instance
    && !events.current.fetchedConfig) {
    events.current.fetchedConfig = true;
    contextFetch.getInstanceConfig(state.urlQueries.instance, dispatch);
  } else if (state.instanceConfig === null && !state.urlQueries.instance) {
    dispatch({instanceConfig: false})
  }

  const loaderStyles = {
    container: {
      position: 'relative',
      minHeight: '100vh',
      width: '100%',
    },
    content: {
      ...absCenter,
    }
  }
  let path ;
  if(typeof window !==undefined){
    path = window.location.pathname
  }

  return(
    <SsoContext.Provider
      value={{
        state: state,
        handleSession: setSessionCookie,
        toggleModal: toggleModal,
        setupMSAL,
      }}
    >
      {path==="/term-of-use" || path==="/privacy-policy"?null:
        <XdLogoBg />
         // state.instanceConfig === false &&
      }
      {state.instanceConfig && !checkingCookie
        ? props.children
        : (state.instanceConfig === '' 
            ? <ListOfInstancesPage />
            : <Loader
              styles={loaderStyles}
              width='140px'
              height='140px'
              noBg
            />
          )
      }
    </SsoContext.Provider>
  )
}

const SsoContextConsumer = SsoContext.Consumer;

export {SsoContext, SsoContextProvider, SsoContextConsumer};
